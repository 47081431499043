// components
import BannerCard from "components/BannerCard";
// types
import { TSLDRLayout } from "../type";
// enums
import { EResourceType } from "cms/enums/EResourceType";


function SLDR_002 ( props: TSLDRLayout<{}> ) {
    console.log("SLDR_002: ", props);

    return (
        <BannerCard
            payload={props.payload}
            variant={"default"}
            resourceType={EResourceType.PATTERN}
            effectActive={true}
            className="col-span-1 sm:col-span-2"
            navigationURL={props.navigationURL}
        />
    )

}


export default SLDR_002;
