import React, { useEffect } from "react";
import Lodash, { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";
// actions
import CoreAction from "core/actions/common/CoreAction";
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import Divider from "components/Divider";
import Container from "components/Container";
// import Subscription from "components/Subscription";
import ProductSingleDetails from "components/ProductSingleDetails";
import IClientProductDetails from "ecommerce/interfaces/IClientProductDetails";
import ClientProductsServiceAction from "ecommerce/actions/components/InnerLayouts/INNR006/ClientProductsServiceAction";
import { TINNRLayout } from "../type";
import Breadcrumb from "components/Breadcrumb";
import IClientCatalogCategoryList from "ecommerce/interfaces/IClientCatalogCategoryList";
import SEO from "components/SEO";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";


function mapStateToProps ( state: any ) {
    console.log("INNR006.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    const productDetailPayload:IClientProductDetails    = state.INNR006Store.ClientProductsStore.details.payload;
    const productDetailError:TValidations[]|null        = state.INNR006Store.ClientProductsStore.details.error?.value;
    const productDetailIsLoading:boolean                = state.INNR006Store.ClientProductsStore.details.isLoading;

    const clientCatalogCategoryListPayload:IClientCatalogCategoryList[] = state.ClientCatalogCategoryStore.list.payload;

    const errors:TValidations[]|null|undefined      = Lodash.reject( [], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                         = [false].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        productDetailPayload,
        productDetailError,
        productDetailIsLoading,

        clientCatalogCategoryListPayload,

        errors,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("INNR006.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const clientProductsServiceAction = new ClientProductsServiceAction(dispatch);

    return {

        _details: ( id:string, webApplicationLanguageCode:string ) => {

            clientProductsServiceAction.details({ servicePayload: { key: id, language: webApplicationLanguageCode } })

        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {

        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function INNR006Loader () {

    return (
        <></>
    )

}

function INNR006 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR006.rendered: ", props);

    useEffect(
        () => {

            props._details( props.contentDetailPayload.id, props.webApplicationLanguageCode );

            return function cleanup() {
                props._showLoader();
            }

        }, []
    )

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);
            
        }, [ props.productDetailPayload ]
    )

    return (
        <>
            <SEO productPayload={props.productDetailPayload} siteSettingPayload={props.siteSettingDetailPayload} type="product-page" />
            <Divider className="mb-0" />
            <Container>
                <div className="pt-8">
                    <Breadcrumb payload={props.productDetailPayload} catalogCategories={props.clientCatalogCategoryListPayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} />
                </div>
                <ProductSingleDetails payload={props.productDetailPayload} />
                {/* <RelatedProducts sectionHeading="text-related-products" /> */}
                {/* <Subscription className="px-5 sm:px-8 md:px-16 2xl:px-24 mb-20" /> */}
            </Container>
        </>
    )
    
}

const Component = React.memo(INNR006, ( prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR006.memo", { prevProps, nextProps });
    return !(
        prevProps.productDetailPayload !== nextProps.productDetailPayload 
    );
});

export default connector(Component);
