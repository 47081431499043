import { combineReducers } from 'redux';
// reducers
import ClientTagsServiceStore from "ecommerce/reducers/services/ClientTagsServiceStore";


const screenCode = "403";

const TagFilterStore = combineReducers({

    ClientTagsStore: ClientTagsServiceStore({ screenCode }),
    
});

export default TagFilterStore;
