import React, { useRef, useState } from "react";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// types
import { TMainPageLayout } from "../type";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { EContentType } from "cms/enums/EContentType";
import ContainerV2 from "components/ContainerV2";
import Divider from "components/Divider";
import ContactInfoBlock from "components/ContactInfoBlock";
import ContactForm from "components/ContactForm";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("MNPG008.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null            = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                    = state.SiteSettingStore.details?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        siteSettingDetailError,
        siteSettingDetailIsLoading,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG008.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG008Loader () {

    return (
        <></>
    )

}

function MNPG008 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG008.rendered", props) 

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const weAreHereToHelp = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "weAreHereToHelp" }
        ],
        type: "find"
    });
    const contactOurTeam = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactOurTeam" }
        ],
        type: "find"
    });

    return (
        <div className="pt-6 pb-5 md:pt-12 md:pb-7 bg-floorsAndExtras-600">
            <ContainerV2>
                <div className="grid grid-cols-12">
                    <div className="md:col-span-4 mb-8 sm:mb-10 md:mb-12 text-white col-span-12">
                        <p className='text-xl'>
                            {weAreHereToHelp?.value}
                        </p>
                        <h2 className="text-[24px] leading-[1.6] md:text-[24px] lg:text-[30px] 2xl:text-[38px] 3xl:text-[48px] font-bold mb-3 md:mb-4 lg:mb-4 xl:mb-4 2xl:mb-4 text-white">
                            {contactOurTeam?.value}
                        </h2>

                        <Divider className="mb-0" />

                        <ContactInfoBlock languageVersions={props.parentPayload.languageVersions} className="text-white" />
                    </div>

                    <div className="col-span-1 hidden md:block"></div>

                    <div className="md:col-span-7 col-span-12">
                        <ContactForm languageVersions={props.parentPayload.languageVersions} labelClassName="text-white" />
                    </div>
                </div>
            </ContainerV2>
        </div>
    )

}

const Component = React.memo(MNPG008, ( prevProps:TMainPageLayout<PropsFromRedux> , nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG008.memo", { prevProps, nextProps });

    return !(prevProps.parentPayload !== nextProps.parentPayload);
});

export default connector(Component);
