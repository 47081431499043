import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientProductsService from 'ecommerce/services/ClientProductsService';
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientProductsServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientProductsService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.GET(),
        ...reduxStorages.DETAILS(),

    });
}

export default ClientProductsServiceStore;
