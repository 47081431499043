import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
// components
// actions
import CoreAction from "core/actions/common/CoreAction";
// enums
import Text from "components/Text";
import { IoHomeSharp } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";


function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Error404.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {

        _hideLoader: () => {
            coreAction.hideLoader()
        }

    }
}

const connector = connect( null, mapDispatchToProps );

/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TError404<T> = {

} & T;


/* component */
function Error404Loader () {

    return (
        <></>
    )

}

function Error404 ( props: TError404<PropsFromRedux> ) {
    console.log("Error404.rendered:", props)

    useEffect(
        () => {
            props._hideLoader();
        }, []
    )

    return (
        <div className="text-center px-16 py-16 sm:py-20 lg:py-24 xl:py-32 flex items-center justify-center">
            <div>
                <img
                    src="/assets/images/404.svg"
                    alt="Looks like you are lost"
                    width={822}
                    height={492}
                />

                <Text variant="mediumHeading">Looks like you are lost</Text>
                <p className="text-sm md:text-base leading-7 pt-2 md:pt-3.5 pb-7 md:pb-9">
                    We can't find the page you're looking for
                </p>
                <Link
                    to="/"
                    className="text-[13px] md:text-sm lg:text-base leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 bg-heading text-white px-4 md:px-6  py-2.5 lg:py-3 hover:text-white hover:bg-gray-600 hover:shadow-cart rounded-lg"
                >
                    <IoHomeSharp />
                    <span className="pl-1.5">Go Home</span>
                </Link>
            </div>
        </div>
    );

};

const Component = React.memo(Error404, ( prevProps: TError404<PropsFromRedux>, nextProps: TError404<PropsFromRedux> ) => {
    console.log("Error404.memo", { prevProps, nextProps });

    console.log("Error404.doNotRender");
    return true;
});

export default connector(Component);
