enum EActionType {

    LIST                = "LI",
    POST                = "PO",
    PATCH               = "PA",
    MANAGEMENT          = "MA",
    DELETE              = "DE",
    DETAILS             = "DT",
    STORAGE             = "ST",
    SIGNIN              = "SI",
    GET                 = "GE",

    LANGUAGE            = "LA",
    LANGUAGE_CODE       = "LC",
    LOADER              = "LO",

    DOWNLOAD            = "DL",
    UPLOAD              = "UL",

    CHANGE              = "CH",
    COLOR_PALETTE       = "CP",
    COLOR_PALETTE_MODE  = "CM",
    PAGINATION          = "PN",

    PUBLISH_MESSAGE     = "PM",
    PUBLISH_POPUP       = "PP"

}

export default EActionType;
