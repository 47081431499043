import React from "react";
import Lodash from "lodash";
import { connect, ConnectedProps } from "react-redux";
// actions
import CoreAction from "core/actions/common/CoreAction";
import IClientLanguage from "core/interfaces/IClientLanguage";
// import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { Navigate, useLocation } from "react-router-dom";
import { TINNRLayout } from "../type";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import SEO from "components/SEO";


function mapStateToProps ( state: any ) {
    console.log("INNR004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("INNR004.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {
        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {

        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function INNR004Loader () {

    return (
        <></>
    )

}

function INNR004 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR004.rendered: ", props);

    const location = useLocation();
    const _id = location.pathname.split("/")?.pop();
    if ( _id !== props.contentDetailPayload?.id ) return null;

    if ( !Lodash.isEmpty(props.webApplicationLanguageISOCode) )
        return (
            <>
                <SEO contentPayload={props.contentDetailPayload} siteSettingPayload={props.siteSettingDetailPayload} type="content-page" />
                <Navigate to={`/${props.webApplicationLanguageISOCode}/shop`} replace />
            </>
        )
    else 
        return <Navigate to={`/${props.webApplicationLanguageISOCode}`} replace />;
    
}

const Component = React.memo(INNR004, ( prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR004.memo", { prevProps, nextProps });
    return !(
        prevProps.contentDetailPayload !== nextProps.contentDetailPayload 
    );
});

export default connector(Component);
