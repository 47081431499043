interface DividerProps {
  className?: string;
}

function Divider ( props:DividerProps) {
  return <div className={`border-t border-gray-300 ${props.className}`} />;
};

Divider.defaultProps = {

    className: "mb-12 lg:mb-14 xl:mb-16 pb-0.5 lg:pb-1 xl:pb-0",
}

export default Divider;
