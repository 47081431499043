import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientStoresService from 'ecommerce/services/ClientStoresService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';
import EActionType from 'core/enums/EActionType';
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


function ClientStoresServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientStoresService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.DETAILS(),
        
        pagination: reduxStorages.BASE({ 
            storeKey: "pagination",
            protocolKey: EActionType.PAGINATION,
            initialState: EReduxStoreInitialState.OBJECT
        })["pagination"]
    
    });
}

export default ClientStoresServiceStore;
