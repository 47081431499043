import IApplicationDocument from 'cms/interfaces/IApplicationDocument';
import ApplicationDocumentSelector, { TApplicationDocumentSelector } from 'cms/utilities/ApplicationDocumentSelector';
import CoreDefault from 'core/Defaults';
import { isEmpty } from 'lodash';


type TRMDRPDFViever = {

    width?:string;
    height?:string;
    className?:string;

} & TApplicationDocumentSelector;


function RMDRPDFViever ( props:TRMDRPDFViever ) {

    const applicationDocument = ApplicationDocumentSelector<IApplicationDocument>({
        resources: props.resources,
        resourceType: props.resourceType,
        type: "find"
    })

    if ( applicationDocument && !isEmpty(applicationDocument) ) {

        return (
            <iframe title="RMDRPDFViewer" src={`flipbook/rmdr-flipbook.html?pdfUrl=${CoreDefault.cdn}/${applicationDocument.filePath}`} className={props.className} width={props.width} height={props.height} />
        )
    
    }

    return null;

}

RMDRPDFViever.defaultProps = {

    width: "100%",
    height: null,
    className: "h-screen",

}

export default RMDRPDFViever;
