import MNPG_001 from "./components/MNPG_001";
import MNPG_002 from "./components/MNPG_002";
import MNPG_003 from "./components/MNPG_003";
import MNPG_004 from "./components/MNPG_004";
import MNPG_005 from "./components/MNPG_005";
import MNPG_006 from "./components/MNPG_006";
import MNPG_007 from "./components/MNPG_007";
import MNPG_008 from "./components/MNPG_008";
import MNPG_009 from "./components/MNPG_009";
import MNPG_015 from "./components/MNPG_015";

const MainPageLayout:any = {
    
    MNPG_001,
    MNPG_002,
    MNPG_003,
    MNPG_004,
    MNPG_005,
    MNPG_006,
    MNPG_007,
    MNPG_008,
    MNPG_009,
    MNPG_015,
    
}

export default MainPageLayout;
