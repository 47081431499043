// action
import OdataReadOnlyActionBase from "./OdataReadOnlyActionBase";
// enum
import EActionStatus from "core/enums/EActionStatus";
import EActionType from "core/enums/EActionType";
// interface
import { IActionPostOdata } from "core/interfaces/IActionPost";
import { IActionDeleteOdata } from "core/interfaces/IActionDelete";
import IActionRunParams from "core/interfaces/IActionRunParams";
import IServiceResponse from "core/interfaces/IServiceResponse";
import IActionCallbackArgs from "core/interfaces/IActionCallbackArgs";


class OdataActionBase extends OdataReadOnlyActionBase {

    async post ( params: IActionPostOdata ) {

        this.fetching(EActionType.POST);
        
        const serviceParams: IActionRunParams = {
            args: {...params.args, actionType: EActionType.POST},
            servicePayload: params.servicePayload
        }
        
        this.executeService( this.service.post.bind(this.service), serviceParams );
    
    }

    async postModifier ( params: IActionPostOdata ) {
        console.log("OdataActionBase.post_modify: ", params);

        this.fetching(EActionType.POST);
        this.modify(EActionType.POST, params.servicePayload);

    }

    postClean () {

        this.clean(EActionType.POST);

    }

    async delete ( params: IActionDeleteOdata ) {

        this.fetching(EActionType.DELETE);
        
        const serviceParams: IActionRunParams = {
            args: {...params.args, actionType: EActionType.DELETE},
            servicePayload: params.payload
        }
        
        this.executeService( this.service.delete.bind(this.service), serviceParams );
    
    }

    deleteClean () {

        this.clean(EActionType.DELETE);

    }

    // callback406 ( response: IServiceResponse, args?: IActionCallbackArgs ) {

    //     if ( args?.actionType === EActionType.POST || args?.actionType === EActionType.DELETE ) {
    //         this.dispatch({ 
    //             type: `${this.screenCode}${this.serviceCode}${args?.actionType}${EActionStatus.FAIL}`, 
    //             payload: response,
    //             message: null
    //         });
    //     }
    //     else if ( args?.actionType === EActionType.POST || args?.actionType === EActionType.DELETE ) {
    //         this.dispatch({ 
    //             type: `${this.screenCode}${this.serviceCode}${args?.actionType}${EActionStatus.FAIL}`, 
    //             payload: response,
    //             message: null
    //         });
    //     }

    // }

}

export default OdataActionBase;
