import { combineReducers } from 'redux';
// reducers
import ClientContentsServiceStore from "cms/reducers/services/ClientContentsServiceStore";
import ContentMetricsServiceStore from "core/reducers/service/metrics/ContentMetricsServiceStore";


const screenCode = "20B";

const ContentStore = combineReducers({
 
    ClientContentsStore: ClientContentsServiceStore({ screenCode }),
    ContentMetricsStore: ContentMetricsServiceStore({ screenCode })
    
});

export default ContentStore;
