import { combineReducers } from 'redux';
// 
import ClientPagesServiceStore from "cms/reducers/services/ClientPagesServiceStore";


const screenCode = "20E";

const ShowroomLayoutStore = combineReducers({
 
    ClientPagesStore: ClientPagesServiceStore({ screenCode }),
    
});

export default ShowroomLayoutStore;
