import { combineReducers } from 'redux';
// 
import ClientMainPageItemsServiceStore from "cms/reducers/services/ClientMainPageItemsServiceStore";


const screenCode = "212";

const MainPageItemStore = combineReducers({
 
    ClientMainPageItemsStore: ClientMainPageItemsServiceStore({ screenCode }),
    
});

export default MainPageItemStore;
