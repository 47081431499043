import cn from 'classnames';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import RMDRImage from 'components/RMDRImage';
import { EResourceType } from 'cms/enums/EResourceType';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
import IEnum from 'core/interfaces/IEnum';
import IClientPageList from 'cms/interfaces/IClientPageList';
import { FaAnglesRight } from "react-icons/fa6";
import { LuCalendarClock } from "react-icons/lu";
import Moment from 'moment';
import RMDRLink from './RMDRLink';
import ERedirectType from 'cms/enums/ERedirectType';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';


type TPageCard = {
    payload: IClientPageList;
    stockStatusDataSource?:IEnum[];

    className?: string;
    contactClassName?: string;
    imageContentClassName?: string;
    imageClassName?: string;
    variant?:
    | 'grid'
    | 'gridSlim'
    | 'list'
    | 'listSmall'
    | 'gridModern'
    | 'gridModernWide'
    | 'gridTrendy'
    | 'rounded'
    | 'circle';
    imgWidth?: number;
    imgHeight?: number;
    hideProductDescription?: boolean;
    showCategory?: boolean;
    showRating?: boolean;
    bgTransparent?: boolean;
    bgGray?: boolean;
    demoVariant?: 'ancient';
    disableBorderRadius?: boolean;
    disableActionButton?: boolean;
    readMoreString?: string|null;
}

function PageCard(props: TPageCard ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
        ],
        type: "find"
    });

    const date = Moment(props.payload.creationDateTime).format("MM-DD-YYYY")

    return (
        <RMDRLink
            title={languageVersion?.name}
            hasNavigationDestination={true}
            navigationURL={languageVersion?.contentRouteNavigationURL}
            redirectUrl={redirectUrl?.value}
            redirectType={ERedirectType.REDIRECT_NEW_TAB}
            className={cn(
                `group box-border overflow-hidden flex ${!props.disableBorderRadius && 'rounded-md'
                } cursor-pointer`,
                {
                    'pr-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-product':
                        props.variant === 'grid' ||
                        props.variant === 'gridModern' ||
                        props.variant === 'gridModernWide' ||
                        props.variant === 'gridTrendy',
                    ' bg-white':
                        (props.variant === 'grid' && !props.bgGray) ||
                        (props.variant === 'gridModern' && !props.bgGray) ||
                        (props.variant === 'gridModernWide' && !props.bgGray) ||
                        (props.variant === 'gridTrendy' && !props.bgGray) ||
                        (props.variant === 'gridSlim' && !props.bgGray),
                    'bg-gray-200': props.variant === 'list' || props.bgGray,
                    'pr-0 md:pb-1 flex-col items-start':
                        props.variant === 'gridSlim',
                    'items-center border border-gray-100 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-listProduct':
                        props.variant === 'listSmall',
                    'flex-row items-center transition-transform ease-linear pr-2 lg:pr-3 2xl:pr-4':
                        props.variant === 'list',
                    '!bg-transparent': props.variant === 'grid' && props.bgTransparent === true,
                },
                props.className
            )}
        >
            <div
                className={cn(
                    'flex',
                    {
                        'mb-3 md:mb-3.5': props.variant === 'grid',
                        'mb-3 md:mb-3.5 pb-0': props.variant === 'gridSlim',
                        'flex-shrink-0 w-32 sm:w-44 md:w-36 lg:w-44':
                            props.variant === 'listSmall',
                        'relative':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',
                    },
                    props.imageContentClassName
                )}
            >

                <RMDRImage
                    resourceType={EResourceType.OUTER_IMAGE} 
                    resources={props.payload?.resources}
                    alt={languageVersion?.name} 
                    title={languageVersion?.name} 
                    width={props.demoVariant === 'ancient' ? 352 : props.imgWidth}
                    height={props.demoVariant === 'ancient' ? 452 : props.imgHeight}
                    className={
                        cn(
                            "bg-gray-300 object-cover",
                            props.imageClassName,
                            {
                                'rounded-s-md':!props.disableBorderRadius
                            },
                            {
                                'w-full transition duration-200 ease-in':
                                    props.variant === 'grid' ||
                                    props.variant === 'gridModern' ||
                                    props.variant === 'gridModernWide' ||
                                    props.variant === 'gridTrendy',
                                'rounded-md group-hover:rounded-b-none':
                                    (props.variant === 'grid' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridModern' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridModernWide' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridTrendy' && !props.disableBorderRadius),
                                'rounded-md transition duration-150 ease-linear transform group-hover:scale-105':
                                    props.variant === 'gridSlim',
                                'rounded-s-md transition duration-200 ease-linear transform group-hover:scale-105':
                                    props.variant === 'list',
                            }
                        )
                    }
                />
            </div>
            <div
                className={cn(
                    'w-full overflow-hidden p-2',
                    {
                        'md:px-2.5 xl:px-4': props.variant === 'grid',

                        'px-2 md:px-2.5 xl:px-4 h-full flex flex-col':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',

                        'pl-0': props.variant === 'gridSlim',
                        'px-4 lg:px-5 2xl:px-4': props.variant === 'listSmall',
                    },
                    props.contactClassName
                )}
            >
                {
                    (props.variant === 'gridModern' || props.variant === 'gridModernWide' || props.variant === 'gridTrendy') && 
                        (
                            <div className="flex items-center pb-2 gap-x-2 mb-3">
                                {
                                    date && 
                                        (   
                                            <p className='text-right flex justify-end items-center gap-2'>
                                                <LuCalendarClock className="text-xl" />
                                                {date}
                                            </p>
                                        )
                                    
                                }
                            </div>
                        )
                }
                <h2
                    className={cn('line-clamp-3 mb-1', {
                        'text-sm h-10 md:text-base md:h-12': props.variant === 'grid',
                        'font-semibold': props.demoVariant !== 'ancient',
                        'font-bold': props.demoVariant === 'ancient',
                        'text-xs h-8 sm:text-sm sm:h-10 md:text-base md:h-[4.5rem]':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',
                        'md:mb-1.5 text-sm h-10 sm:text-base sm:h-12 md:text-sm md:h-10 lg:text-base lg:h-12 xl:text-lg xl:h-14':
                            props.variant === 'gridSlim',
                        'text-sm h-10 sm:text-base sm:h-12 md:mb-1.5 pb-0': props.variant === 'listSmall',
                        'text-sm h-10 sm:text-base sm:h-12 md:text-sm md:h-10 lg:text-base lg:h-12 xl:text-lg xl:h-14 md:mb-1.5':
                            props.variant === 'list',
                        'text-white': props.bgTransparent,
                        'text-heading': !props.bgTransparent,
                    })}
                >
                    {languageVersion?.name}
                </h2>
                {
                    !props.hideProductDescription && languageVersion?.value && 
                        (
                            <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed line-clamp-3" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                        )
                }
                {
                    props.readMoreString && 
                        (   
                            <p className='text-right flex justify-end items-center gap-2'>
                                {props.readMoreString}
                                <FaAnglesRight className='text-xl' />
                            </p>
                        )
                    
                }
            </div>
        </RMDRLink>
    )
}

PageCard.defaultProps = {

    className: '',
    contactClassName: '',
    imageContentClassName: '',
    variant: 'list',
    imgWidth: 340,
    imgHeight: 440,
    hideProductDescription: false,
    showCategory: false,
    showRating: false,
    bgTransparent: false,
    bgGray: false,
    disableBorderRadius: false,
    disableActionButton: false,

}

export default PageCard;
