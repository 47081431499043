import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientCategoriesService from 'cms/services/ClientCategoriesService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';
import EActionType from 'core/enums/EActionType';
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


function ClientCategoriesServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientCategoriesService.code });

    return combineReducers({

        ...reduxStorages.DETAILS(),
        
        pagination: reduxStorages.BASE({ 
            storeKey: "pagination",
            protocolKey: EActionType.PAGINATION,
            initialState: EReduxStoreInitialState.OBJECT
        })["pagination"]
    
    });
}

export default ClientCategoriesServiceStore;
