import { useState } from 'react';
import CoreDefaults from "core/Defaults";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { isEmpty } from 'lodash';
import ProductCard from "components/ProductCard";

// types
import { TGallery } from '../type';
// 
import "../gallery06.scss";
import IClientProductList from 'ecommerce/interfaces/IClientProductList';

type TVariant = "circle"
    | "rounded"
    | "listSmall"
    | "grid"
    | "gridSlim"
    | "list"
    | "gridModern"
    | "gridModernWide"
    | "gridTrendy"
    | undefined;

type TGallery06 = {

    payload: IClientProductList[];

    hideProductDescription?: boolean;
    showCategory?: boolean;
    showRating?: boolean;
    disableBorderRadius?: boolean;

    variant?: TVariant;
    demoVariant?: "ancient";

};


function Gallery06 ( props:TGallery<TGallery06> ) {

    if ( !isEmpty(props.payload) ) {
        return (
            <Swiper
                slidesPerView={1}
                spaceBetween={8}
                loop={true}
                navigation={true}
                pagination={{
                    clickable: true
                }}
                modules={[FreeMode, Pagination, Navigation]}
                className="gallery06"
                breakpoints={{
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 8,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 8,
                    },
                    1500: {
                        slidesPerView: 6,
                        spaceBetween: 8,
                    },
                }}
            >
                {
                    props.payload?.map((item, index) => (
                        <SwiperSlide key={`ss_${item.id}`}>
                            <ProductCard
                                key={`pc_${item.id}`}
                                payload={item}
                                variant={props.variant}
                                className="border border-gray-300"
                                imageClassName="!rounded-b-none"
                                disableActionButton
                                hideProductDescription={props.hideProductDescription}
                                showCategory={props.showCategory}
                                showRating={props.showRating}
                                demoVariant={props.demoVariant}
                                disableBorderRadius={props.disableBorderRadius}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        );    
    }
}

Gallery06.defaultProps = {

    variant: "grid",
    hideProductDescription: false,
    showCategory: false,
    showRating: false,
    disableBorderRadius: false,

}

export default Gallery06;
