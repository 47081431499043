import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Lodash from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
// components
import MainPageLayout from "components/MainPageLayout";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientMainPageItemsServiceAction from "cms/actions/components/MainPageItems/ClientMainPageItemsServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientMainPageItemList from "cms/interfaces/IClientMainPageItemList";
import ILayoutList from "cms/interfaces/ILayoutList";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
// enums
import ELayoutTypeDataSource from "cms/enums/ELayoutType";
import { EContentStatus } from "cms/enums/EContentStatus";
// types
import TValidations from "cms/types/TValidations";
// styles
import 'react-tabs/style/react-tabs.css';


function mapStateToProps ( state: any ) {
    console.log("MainPageItems.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const mainPageItemsListPayload:IClientMainPageItemList[]   = state.MainPageItemStore.ClientMainPageItemsStore.list?.payload;
    const mainPageItemsListError:TValidations[]|null           = state.MainPageItemStore.ClientMainPageItemsStore.list?.error;
    const mainPageItemsListIsLoading:boolean                   = state.MainPageItemStore.ClientMainPageItemsStore.list?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null           = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                   = state.SiteSettingStore.details?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        mainPageItemsListPayload,
        mainPageItemsListError,
        mainPageItemsListIsLoading,

        siteSettingDetailPayload,
        siteSettingDetailError,
        siteSettingDetailIsLoading,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MainPageItems.mapDispatchToProps");

    const clientMainPageItemsServiceAction = new ClientMainPageItemsServiceAction(dispatch);
    const coreAction = new CoreAction(dispatch);

    return {
        // list
        _listClientMainPageItems: ( mapLayoutName:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMainPageItemsServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        _hideLoader: () => {
            coreAction.hideLoader();
        },

        _showLoader: () => {
            coreAction.showLoader();
        },
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TMainPageItems<T> = {

    mapLayoutName?: string;
    
} & T;


/* component */
function MainPageItemsLoader () {

    return (
        <></>
    )

}

function MainPageItems (props: TMainPageItems<PropsFromRedux>) {
    console.log("MainPageItems.rendered: ", props);

    const [ mainPageParentLayouts, setMainPageParentLayouts ] = useState<IClientMainPageItemList[]|null>(null);

    useEffect(
        () => {
            
            return function cleanup() {
                props._showLoader();
            }

        }, []
    )

    useEffect(
        () => {
            if ( props.mapLayoutName && !Lodash.isEmpty(props.mapLayoutName) ) 
                props._listClientMainPageItems( props.mapLayoutName, props.webApplicationLanguageCode );
        }, [ props.mapLayoutName ]
    )

    useEffect (
        () => {

            if ( props.mainPageItemsListPayload && !Lodash.isEmpty(props.mainPageItemsListPayload) && !props.mainPageItemsListIsLoading ) {
                setMainPageParentLayouts(props.mainPageItemsListPayload?.filter((item:IClientMainPageItemList) => item.parentId === null ))
            }

            if ( !props.mainPageItemsListIsLoading ) {
                props._hideLoader();
            }

        }, [ props.mainPageItemsListIsLoading ]
    )
    
    if ( mainPageParentLayouts && !Lodash.isEmpty(mainPageParentLayouts) && !props.mainPageItemsListIsLoading ) {

        return (
            <>
                {
                    mainPageParentLayouts?.map((item:IClientMainPageItemList) => { 
                        const layout = item.layouts.find((item:ILayoutList) => item.layoutType === ELayoutTypeDataSource.MAIN_PAGE.id );

                        if ( layout ) {
                            const Layout = MainPageLayout[layout.layoutName];
                            const childs = props.mainPageItemsListPayload?.filter((cItem:IClientMainPageItemList) => cItem.parentId === item.id);
                            
                            if ( Layout ) {
                                return <Layout parentPayload={item} childsPayload={childs} siteSettingPayload={props.siteSettingDetailPayload} />
                            }
                        }

                        return null;
                    })
                }
            </>
        )

    }

    return null;
    
}

MainPageItems.defaultProps = {

    mapLayoutName: "MNPGGRP_001"

}

const Component = React.memo(MainPageItems, (prevProps:TMainPageItems<PropsFromRedux>, nextProps:TMainPageItems<PropsFromRedux>) => {
    console.log("MainPageItems.memo", { prevProps, nextProps });
    return !(prevProps.mainPageItemsListIsLoading !== nextProps.mainPageItemsListIsLoading );
});

export default connector(Component);
