import MegaMenu from './MegaMenu';
import cn from 'classnames';
import IClientCatalogCategoryList from 'ecommerce/interfaces/IClientCatalogCategoryList';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import RMDRLink from 'components/RMDRLink';
import ERedirectType from 'cms/enums/ERedirectType';
import { isEmpty } from 'lodash';
import TMenu from 'cms/types/TMenu';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';


type TListMenu = {

    dept:number;
    payload:TMenu;
    hasSubMenu?:TMenu[];
    hasMegaMenu?:TMenu[];
    hasBrands?:TMenu[];
    hasBanners?:TMenu[];
    menuName:string;
    key:string;
    menuIndex:number;
    webApplicationLanguageISOCode:string;

    onClick?: (event:any, contentId?:string) => void;
}

type TSubMenu = {

    dept:number;
    payload:IClientCatalogCategoryList;
    menuIndex:number;

}


function ListMenu ( props:TListMenu ) {
    console.log("ListMenu.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });
    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
        ],
        type: "find"
    });

    const navigationURL = `/${props.webApplicationLanguageISOCode}/shop?c=${props.payload.hierarchyId}`;

    return (
        <li className={cn(!props.hasMegaMenu ? 'group relative' : '')}>
            <RMDRLink
                title={languageVersion?.name}
                hasNavigationDestination={true}
                navigationURL={navigationURL}
                redirectType={ERedirectType.STAY_CURRENT_PAGE}
                redirectUrl={redirectUrl?.value}
                className="flex items-center py-2 pl-5 xl:pl-7 pr-3 xl:pr-3.5 hover:text-heading hover:bg-gray-300"
                onClick={(event:any) => { if( props.onClick ) props.onClick(event, props.payload.id) }}
            >
                {/* {
                    props.data.icon && 
                        (
                            <span className="inline-flex mr-2">{props.data.icon}</span>
                        )
                } */}
                {languageVersion?.name}
                {/* {
                    props.data.subMenu && 
                        (
                            <span className="text-sm mt-0.5 shrink-0 ml-auto">
                                <IoIosArrowForward className="transition duration-300 ease-in-out text-body group-hover:text-black" />
                            </span>
                        )
                } */}
            </RMDRLink>

            {/* {
                props.hasSubMenu && (
                    <SubMenu dept={props.dept} data={props.data.subMenu} menuIndex={props.menuIndex} />
                )
            } */}

            {
                (!isEmpty(props.hasMegaMenu) || !isEmpty(props.hasBrands) || !isEmpty(props.hasBanners)) && (
                    <div className="absolute flex bg-white categoryMegaMenu shadow-header left-full">
                        <div className="flex-shrink-0">
                            <MegaMenu payload={props.hasMegaMenu} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} onClick={props.onClick} />
                        </div>
                        {/* <div className="hidden xl:block flex-shrink-0 bg-white">
                            <div className="grid grid-cols-3 gap-3 p-6 2xl:py-8 2xl:px-7 3xl:grid-cols-3 justify-items-center">
                                {
                                    props.hasBrands.map((brand: any) => (
                                        <Link
                                            to={brand.path}
                                            key={brand.id}
                                            className="bg-gray-200 border border-gray-300 rounded-md"
                                        >
                                            <img
                                                src={brand.icon.src}
                                                height={60}
                                                width={150}
                                                alt={brand.label}
                                            />
                                        </Link>
                                    ))
                                }
                            </div>
                            <div className="grid grid-cols-2 gap-3 p-6 border-t border-gray-300 2xl:py-8 2xl:px-7">
                                {
                                    props.hasBanners.map((banner: any) => (
                                        <Link to={banner.path} key={banner.id}>
                                            <img className="rounded-md" src={banner.image.src} alt={banner.label} />
                                        </Link>
                                    ))
                                }
                            </div>
                        </div> */}
                    </div>
                )
            }
        </li>
    );
};

// function SubMenu ( props:TSubMenu ) {
//     const dept = props.dept + 1;

//     return (
//         <ul className="absolute z-0 invisible w-56 py-3 bg-gray-200 opacity-0 subMenuChild shadow-subMenu right-full 2xl:right-auto 2xl:left-full top-4">
//             {
//                 props.payload?.map((menu, index) => {
//                     const menuName: string = `sidebar-submenu-${dept}-${props.menuIndex}-${index}`;

//                     return (
//                         <ListMenu
//                             dept={dept}
//                             data={props.menu}
//                             hasSubMenu={menu.subMenu}
//                             menuName={menuName}
//                             key={menuName}
//                             menuIndex={index}
//                         />
//                     );
//                 })
//             }
//         </ul>
//     );
// };


export default ListMenu;
