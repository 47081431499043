import React from "react";
import SectionHeader from "components/SectionHeader";
import cn from "classnames";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import ProductCard, { ProductCardLoader } from "./ProductCard";
import IEnum from "core/interfaces/IEnum";


type TVariant = "circle"
    | "rounded"
    | "listSmall"
    | "grid"
    | "gridSlim"
    | "list"
    | "gridModern"
    | "gridModernWide"
    | "gridTrendy"
    | undefined;


type TProductsProps = {

    sectionHeading?: string|null;
    payload?: IClientProductList[];
    stockStatusDataSource?:IEnum[];
    className?: string;

    variant?: TVariant;
    imgWidth?: number | string;
    imgHeight?: number | string;

    hideProductDescription?: boolean;
    showCategory?: boolean;
    showRating?: boolean;
    demoVariant?: "ancient";
    disableBorderRadius?: boolean;

    onClick?: ( event: React.MouseEvent<HTMLDivElement, MouseEvent>, value:IClientProductList ) => void;

}


function ProductsBlockLoader ( props: { variant?:string, length:number } ) {

    return (
        <div
            className={cn(
                'h-80 grid gap-3',
                {
                    "grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5":
                        props.variant === "grid",
                    "grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4":
                        props.variant === "gridModernWide",
                    "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5":
                        props.variant === "gridModern",
                }
            )}
        >
            {
                Array.from({ length: props.length }).map((item) => (<ProductCardLoader variant={props.variant} />))
            }
        </div>
    )

}

function ProductsBlock ( props: Readonly<TProductsProps> ) {

    return (
        <div className={props.className}>
            {
                props.sectionHeading && <SectionHeader sectionHeading={props.sectionHeading} />
            }

            <div
                className={cn(
                    `grid gap-x-${props.demoVariant === "ancient" ? 2 : 3} md:gap-x-${props.demoVariant === "ancient" ? 2 : 5
                    } xl:gap-x-${props.demoVariant === "ancient" ? 2 : 7} gap-y-${props.demoVariant === "ancient" ? 2 : 3
                    } xl:gap-y-${props.demoVariant === "ancient" ? 2 : 5} 2xl:gap-y-${props.demoVariant === "ancient" ? 3 : 8
                    } bg-white`,
                    {
                        "grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5":
                            props.variant === "grid",
                        "grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4":
                            props.variant === "gridModernWide",
                        "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5":
                            props.variant === "gridModern",
                    }
                )}
            >
                {
                    props.payload?.map((item, index) => (
                        <ProductCard
                            key={index}
                            payload={item}
                            stockStatusDataSource={props.stockStatusDataSource}
                            variant={props.variant}
                            className={`border border-gray-300 ${props.className}`}
                            imageClassName="!rounded-b-none"
                            disableActionButton
                            hideProductDescription={props.hideProductDescription}
                            showCategory={props.showCategory}
                            showRating={props.showRating}
                            demoVariant={props.demoVariant}
                            disableBorderRadius={props.disableBorderRadius}
                            // onClick={(event) => props.onClick && props.onClick(event, item)}
                        />
                    ))
                }
            </div>
        </div>
    );
    
};


ProductsBlock.defaultProps = {

    variant: "grid",
    limit: 10,
    hideProductDescription: false,
    showCategory: false,
    showRating: false,
    disableBorderRadius: false,

}

export default ProductsBlock;
export {
    ProductsBlockLoader
}