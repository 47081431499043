import PageCard from "./PageCard";
import IEnum from "core/interfaces/IEnum";
import NoData from "components/NoData";
import { HiOutlineNewspaper } from "react-icons/hi2";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { isEmpty } from "lodash";
import IClientPageList from "cms/interfaces/IClientPageList";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";


type TPageGrid = {

    siteSettingPayload:IClientSiteSettingDetail;
    payload:IClientPageList[];
    stockStatusDataSource?:IEnum[];
    isLoading:boolean;
    className?:string;
}


/* component */
function PageGridLoader ( props:TPageGrid ) {

    return (
        <>
            {
                Array.from({ length:8 }).map(() => (
                        <div className="animate-pulse">
                            <div className="bd-trending__item text-center mb-30 position-relative">
                                <div className="bd-trending__product-thumb border-5">
                                    <div className="w-full h-48 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                </div>
                            </div>
                        </div>
                ))
            }
        </>
    )

}

function PageGrid ( props:TPageGrid ) {
    console.log("PageGrid: ", PageGrid);

    const readMoreString = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "readMoreString" }
        ],
        type: "find"
    });

    if ( !props.isLoading && isEmpty(props.payload) ) {
        
        return (
            <NoData
                icon={<HiOutlineNewspaper className="h-40 stroke-1 text-9xl" />}
                className="flex flex-col items-center justify-center py-60"
                textClassName="text-center text-2xl"
                siteSettingPayload={props.siteSettingPayload}
            />
        )

    }

    return (
        <>
            <div
                className={`grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 ${props.className}`}
            >
                {
                    props.isLoading ?
                        (
                            <PageGridLoader {...props} />
                        ) : 
                        (
                            props.payload.map((item, index) => <PageCard
                                                                    payload={item}
                                                                    stockStatusDataSource={props.stockStatusDataSource}
                                                                    variant="gridModern"
                                                                    className="border border-gray-300"
                                                                    imageClassName="!rounded-b-none"
                                                                    disableActionButton
                                                                    readMoreString={readMoreString?.value}
                                                                />
                        )
                )}
            </div>
            
            
            
        </>
    );
}

export default PageGrid;
