import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
// components
import RMDRNavLink from "../../RMDRNavLink";
// types
import TValidations from "cms/types/TValidations";
// actions
import ClientMenuItemServiceAction from "cms/actions/components/Header/ClientMenuItemServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientMenuItemList from 'cms/interfaces/IClientMenuItemList';
// enums
import { EContentStatus } from "cms/enums/EContentStatus";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("RightMenu.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const clientMenuItemGet:IClientMenuItemList[]|null              = state.HeaderStore.ClientMenuItemStore.get?.["MNGRP003"];
    const clientMenuItemGetError:TValidations[]|null                = state.HeaderStore.ClientMenuItemStore.get?.error;
    const clientMenuItemGetIsLoading:boolean                        = state.HeaderStore.ClientMenuItemStore.get?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        clientMenuItemGet,
        clientMenuItemGetError,
        clientMenuItemGetIsLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("RightMenu.mapDispatchToProps");

    const clientMenuItemServiceAction = new ClientMenuItemServiceAction(dispatch);

    return {
        
        _getClientMenuItems: ( webApplicationLanguageCode:string, mapLayoutName:string, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMenuItemServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TMenuProps<T> = {

} & T;


/* component */
function RightMenuLoader () {

    return (
        <></>
    )

}

function RightMenu ( props:TMenuProps<PropsFromRedux> ) {
    console.log("RightMenu.rendered: ", props);

    useEffect(
        () => {
            if ( !isEmpty(props.webApplicationLanguageCode)) {
                props._getClientMenuItems(props.webApplicationLanguageCode, "MNGRP_003", "MNGRP003");
            }
        }, [ props.webApplicationLanguageCode ]
    )

    if ( !isEmpty(props.clientMenuItemGet) && props.clientMenuItemGet ) {
        
        const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.clientMenuItemGet[0].languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });
        const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.clientMenuItemGet[0].languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
            ],
            type: "find"
        });

        return (
            <RMDRNavLink
                title={languageVersion?.name?.toUpperCase()}
                text={languageVersion?.name?.toUpperCase()}
                hasNavigationDestination={props.clientMenuItemGet[0].hasDestinationNavigation}
                isShowAlways={true}

                navigationURL={languageVersion?.contentRouteNavigationURL}

                redirectType={props.clientMenuItemGet[0].redirectType}
                redirectUrl={redirectUrl?.value}
                className="flex items-center justify-center gap-2 px-3.5 xl:px-4 text-m relative before:absolute before:-bottom-2.5 before:h-2.5 before:w-full before:z-10 font-semibold text-white transition-colors rounded-md cursor-pointer h-11 w-56 bg-floorsAndExtras-600"

                variant="div"
            />
        );
    }

};

const Component = React.memo(RightMenu, ( prevProps:TMenuProps<PropsFromRedux>, nextProps:TMenuProps<PropsFromRedux> ) => {
    console.log("RightMenu.memo", { prevProps, nextProps });

    return !(
        prevProps.clientMenuItemGet !== nextProps.clientMenuItemGet
    );
});

export default connector(Component);
