import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientTagssService extends ReadOnlyOdataServiceBase {

    static code = "406";
    code = "406";

    constructor () {
        super({
            sourcePath: "client-tags"
        });
    }

}

export default ClientTagssService;
