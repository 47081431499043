import React from 'react';
import { isEmpty, orderBy } from "lodash";
// components
import RMDRTextContent from "components/RMDRTextContent";
import RMDRAccordion01 from "components/RMDRAccordions/components/RMDRAccordion01";
import RMDRTab01 from "components/RMDRTabs/components/RMDRTab01";
// enums
import { EView } from 'cms/enums/EView';
import { EContentType } from 'cms/enums/EContentType';
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail"
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList"

type TContentRenderer = {
    
    languageVersions?: ILanguageVersionList[] | ILanguageVersionDetail[];

    showNameField?:boolean;

    titleClassName?:string;
    contentClassName?:string;

}

function ContentRenderer ( props: Readonly<TContentRenderer> ) {

    if ( props.languageVersions && !isEmpty(props.languageVersions) ) {

        let parentLanguageVersions = props.languageVersions.filter((item) => item.parentId === null && item.view === EView.INNER && item.contentType === EContentType.DEFAULT);
        parentLanguageVersions = orderBy(parentLanguageVersions, ['order'],['asc']);

        return (
            <>
                {
                    parentLanguageVersions.map(
                        (item) => <>
                            <RMDRTextContent languageVersion={item} languageVersions={props.languageVersions} titleClassName={props.titleClassName} contentClassName={props.contentClassName} showNameField={props.showNameField} />
                            <RMDRAccordion01 languageVersion={item} languageVersions={props.languageVersions} />
                            <RMDRTab01 languageVersion={item} languageVersions={props.languageVersions} />
                        </> 
                    )
                }
            </>
        )

    }

    return null;

}

ContentRenderer.defaultProps = {

    showNameField: false,

}

export default ContentRenderer;
export type {
    TContentRenderer
};
