import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';
import Lodash from 'lodash';


function changeValue(
    value:any, 
    initialState:EReduxStoreInitialState, 
    changeOrders:{ key:string, value:any, match_key:string, match_value:any }[]
) {
    
    console.log("This is it.changeValue: ", value, initialState, changeOrders);

    if ( initialState === EReduxStoreInitialState.ARRAY ) return changeValueInArray(value, changeOrders);
    else if ( initialState === EReduxStoreInitialState.OBJECT ) return changeValueInDictionary(value, changeOrders);
    
    return value;

}

function changeValueInArray(
    value:any[], 
    changeOrders:{ key:string, value:any, match_key:string, match_value:any }[]
) {

    
    let _value = !Lodash.isEmpty(value) ? [...value] : [];

    for ( let order of changeOrders ) {

        try {
            if ( !Lodash.isEmpty(order.match_key) && Lodash.isEmpty(order.key) ) {
                _value = _value.map(
                    valueItem => { 
                        if (valueItem[order.match_key] === order.match_value) return { ...valueItem, ...order.value } 
                        else return valueItem 
                    }
                );
            }
            else if ( !Lodash.isEmpty(order.match_key) && !Lodash.isEmpty(order.key) ) {
                _value = _value.map( 
                    (valueItem) => { 
                        if ( valueItem[order.match_key] === order.match_value ) return { ...valueItem, [order.key]: order.value }
                        else return valueItem
                    }
                );
            }
            else if (Lodash.isEmpty(order.match_key) && Lodash.isEmpty(order.key)) {
                _value = [ ..._value, order.value ]
            }
        } catch (ex) {

        }

    }

    return Object.assign(_value);

}

function changeValueInDictionary(
    value:{[key: string]: any}|null, 
    changeOrders:{ key:string, value:any, match_key:string, match_value:any }[]
) {

    let _value:{[key: string]: any} = !Lodash.isEmpty(value) ? {...value} : {};
    for(let item of changeOrders) if (!Lodash.isEmpty(item.key)) _value[item.key] = item.value;

    return Object.assign(_value);

}

export default changeValue;