import cn from 'classnames';

interface Props {
    className?: string;
    title: string;
    attributes: {
        id: number;
        value: string;
        meta: string;
    }[];
    active: string;
    onClick: any;
}

function ProductAttributes ( props: Props ) {

    return (
        <div className={props.className}>
            <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                {props.title}
            </h3>
            <ul className="flex flex-wrap colors -mr-3">
                {
                    props.attributes?.map(({ id, value, meta }) => (
                        <li
                            key={`${value}-${id}`}
                            className={
                                cn(
                                    'cursor-pointer rounded border  w-9 md:w-11 h-9 md:h-11 p-1 mb-2 md:mb-3 mr-2 md:mr-3 flex justify-center items-center text-heading text-xs md:text-sm uppercase font-semibold transition duration-200 ease-in-out hover:border-black',
                                    value === props.active ? 'border-black' : 'border-gray-100'
                                )
                            }
                            onClick={() => props.onClick({ [props.title]: value })}
                        >
                            {
                                props.title === 'color' ? 
                                    (
                                        <span
                                            className="block w-full h-full rounded"
                                            style={{ backgroundColor: meta }}
                                        />
                                    ) : (
                                        value
                                    )
                            }
                        </li>
                    ))
                }
            </ul>
        </div>
    );

};

ProductAttributes.defaultProps = {

    className: 'mb-4'

}

export default ProductAttributes;
