// types
import { TSocialMedia } from "../type";import {
    IoLogoInstagram,
    IoLogoFacebook,
    IoLogoYoutube,
    IoLogoLinkedin,
} from 'react-icons/io5';
import {
    FaXTwitter
} from 'react-icons/fa6'


function SocialMedia02 ( props:TSocialMedia ) {
    console.log("SocialMedia02: ", props);

    const facebook = props?.payload?.find((item) => item.name === "facebook");
    const x = props?.payload?.find((item) => item.name === "x");
    const instagram = props?.payload?.find((item) => item.name === "instagram");
    const linkedin = props?.payload?.find((item) => item.name === "linkedin");
    const youtube = props?.payload?.find((item) => item.name === "youtube");

    if ( props?.payload ) {
        return (
            <ul className={props.className} style={props.style}>
                { 
                    facebook && (
                        <li className="flex items-baseline">
                            <span className="mr-3 relative top-0.5 lg:top-1 text-sm lg:text-lg"><IoLogoFacebook /></span>
                            <a rel="noreferrer" href={facebook.value||"#"} title="Facebook" data-tooltip="Facebook" aria-label="Facebook" target="_blank" className="ransition-colors duration-200 hover:text-black">
                                { facebook.name }
                            </a>
                        </li>
                        
                    )
                }
                { 
                    x && (
                        <li className="flex items-baseline">
                            <span className="mr-3 relative top-0.5 lg:top-1 text-sm lg:text-lg"><FaXTwitter /></span>
                            <a rel="noreferrer" href={x.value||"#"} title="X" data-tooltip="X" aria-label="X" target="_blank" className="ransition-colors duration-200 hover:text-black">
                                { x.name }
                            </a>
                        </li>
                        
                    )
                }
                { 
                    linkedin && (
                        <li className="flex items-baseline">
                            <span className="mr-3 relative top-0.5 lg:top-1 text-sm lg:text-lg"><IoLogoLinkedin /></span>
                            <a rel="noreferrer" href={linkedin.value||"#"} title="LinkedIn" data-tooltip="LinkedIn" aria-label="LinkedIn" target="_blank" className="ransition-colors duration-200 hover:text-black">
                                { linkedin.name }
                            </a>
                        </li>
                        
                    )
                }
                { 
                    instagram && (
                        <li className="flex items-baseline">
                            <span className="mr-3 relative top-0.5 lg:top-1 text-sm lg:text-lg"><IoLogoInstagram /></span>
                            <a rel="noreferrer" href={instagram.value||"#"} title="Instagram" data-tooltip="Instagram" aria-label="Instagram" target="_blank" className="ransition-colors duration-200 hover:text-black">
                                { instagram.name }
                            </a>
                        </li>
                        
                    )
                }
                { 
                    youtube && (
                        <li className="flex items-baseline">
                            <span className="mr-3 relative top-0.5 lg:top-1 text-sm lg:text-lg"><IoLogoYoutube /></span>
                            <a rel="noreferrer" href={youtube.value||"#"} title="Youtube" data-tooltip="Youtube" aria-label="Youtube" target="_blank" className="ransition-colors duration-200 hover:text-black">
                                { youtube.name }
                            </a>
                        </li>
                        
                    )
                }
            </ul>
        );
    }

    return (
        <ul className={`animate-pulse ${props.className}`} style={props.style}>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
        </ul>
    );
    
}

export default SocialMedia02;
