// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientPagesService from "cms/services/ClientPagesService";
// enum
import EActionType from "core/enums/EActionType";
// interface
import { IActionGetOdata } from "core/interfaces/IActionGet";
import IActionRunParams from "core/interfaces/IActionRunParams";


class ClientPagesServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientPagesService, screenCode);
    }

    async search ( params: IActionGetOdata ) {

        this.fetching(EActionType.GET);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.GET
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.search.bind(this.service), serviceParams );
        
    }

    modifyPagination ( payload: any ) {

        this.modify(EActionType.PAGINATION, payload);

    }

    paginationClean () {
        
        this.clean(EActionType.PAGINATION);

    }

}

export default ClientPagesServiceAction;
