import React from "react";
import { connect, ConnectedProps } from "react-redux"
import Lodash, { isEmpty } from 'lodash';
import { Outlet, useParams, useLocation } from "react-router-dom";
// components
import SEO from "components/SEO";
//
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { Dispatch } from "@reduxjs/toolkit";
import Error404 from "components/Error404";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationLayout.mapStateToProps: ", state);

    const languageStorePayload:IClientLanguage[]    = state.LanguageStore.list?.payload;

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail         = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null                = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                        = state.SiteSettingStore.details?.isLoading;

    const error:TValidations[]|null|undefined                       = Lodash.reject( [siteSettingDetailError], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                                         = [siteSettingDetailIsLoading].includes(true);

    return {

        languageStorePayload,
        
        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        error,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("WebApplicationLayout.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TWebApplicationLayout<T> = {
    
} & T;


/* component */
function WebApplicationLayoutLoader () {

    return (
        <></>
    )

}

const WebApplicationLayout = ( props:TWebApplicationLayout<PropsFromRedux> ) => {
    console.log("WebApplicationLayout.rendered: ", props);

    const location = useLocation();
    const { language, category, categoryId, content, contentName, contentId } = useParams();
    
    const languageIsExists = !isEmpty(props.languageStorePayload.find((item) => item.twoLetterISOName === language));
    if ( languageIsExists ) {
        return (
            <>
                <SEO siteSettingPayload={props.siteSettingDetailPayload} type="header" />
                <Outlet 
                    context={{
                        category,
                        categoryId,

                        content,
                        contentName,
                        contentId,
                        
                        location
                    }}
                />
            </>
        )
    }

    return <Error404 />
    


};



const Component = React.memo(WebApplicationLayout, ( prevProps:TWebApplicationLayout<PropsFromRedux>, nextProps:TWebApplicationLayout<PropsFromRedux> ) => {
    console.log("WebApplicationLayout.memo", { prevProps, nextProps });

    return !(
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.webApplicationLanguageCode !== nextProps.webApplicationLanguageCode
    )
});

export default connector(Component);
