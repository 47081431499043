import cn from "classnames";


interface Props {
	className?: string;
	children?: any;
	el?: HTMLElement;
	clean?: boolean;
}

function Container ( props:Props ) {
	const rootClassName = cn(props.className, {
		"mx-auto max-w-[1920px] px-4 md:px-8 2xl:px-16": !props.clean,
	});

	let Component: React.ComponentType<
		React.HTMLAttributes<HTMLDivElement>
	> = props.el as any;

	return <Component className={rootClassName}>{props.children}</Component>;
};

Container.defaultProps = {
    el: "div",
};

export default Container;
