import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';

const reduxStorages = new CoreReduxStore( { screenCode: "COM", serviceCode: "CCC" } as ICoreReduxStore )

/**
 * - Language api sinden alınan veriler burada depolanır.
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode COM(Common) ve serviceCode CCC(ClientCatalogCategories) olarak set edilmiştir.
 */

const ClientCatalogCategoryStore = combineReducers({

    ...reduxStorages.LIST()

});

export default ClientCatalogCategoryStore;
