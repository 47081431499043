import React, { useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import Lodash from "lodash";
import { Dispatch } from '@reduxjs/toolkit';
// actions
import ClientMainPageItemsServiceAction from "cms/actions/components/Footer/ClientMainPageItemsServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
// enums
import { EContentStatus } from 'cms/enums/EContentStatus';
import IClientLanguage from 'core/interfaces/IClientLanguage';
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
import TValidations from 'cms/types/TValidations';
import BottomBar from './components/BottomBar';
import MiddleBar from './components/MiddleBar';


function mapStateToProps ( state: any ) {
    console.log("Footer.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage                = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string                     = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string                  = state.CoreStore.languageCode.payload;

    const mainPageItemsListPayload:IClientMainPageItemList[]|null   = state.FooterStore.ClientMainPageItemsStore.list?.payload;
    const mainPageItemsListError:TValidations[]|null                = state.FooterStore.ClientMainPageItemsStore.list?.error;
    const mainPageItemsListIsLoading:boolean                        = state.FooterStore.ClientMainPageItemsStore.list?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        mainPageItemsListPayload,
        mainPageItemsListError,
        mainPageItemsListIsLoading,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Footer.mapDispatchToProps");

    const clientMainPageItemsServiceAction = new ClientMainPageItemsServiceAction(dispatch);

    return {

        // list
        _listClientMainPageItems: ( mapLayoutName:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMainPageItemsServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TFooter<T> = {

    mapLayoutName:string|null;

} & T;


function Footer ( props:TFooter<PropsFromRedux> ) {
    console.log("Footer.rendered: ", props);

    useEffect(
        () => {
            if ( !Lodash.isEmpty(props.mapLayoutName) && props.mapLayoutName ) {
                props._listClientMainPageItems( props.mapLayoutName, props.webApplicationLanguageCode );
            }
        }, []
    )

    return (
        <footer className='border-t border-gray-300 pt-9 md:pt-11 lg:pt-16 3xl:pt-20 relative'>
            <MiddleBar layoutName="MNPG_009" payload={props.mainPageItemsListPayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} />
            <BottomBar layoutName="MNPG_014" payload={props.mainPageItemsListPayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} />
        </footer>
    )
}

const Component = React.memo(Footer, ( prevProps:TFooter<PropsFromRedux>, nextProps:TFooter<PropsFromRedux> ) => {
    console.log("MainPageItem.memo", { prevProps, nextProps });
    return !(prevProps.mainPageItemsListIsLoading !== nextProps.mainPageItemsListIsLoading );
});

export default connector(Component);
