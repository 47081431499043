import { useState, useEffect } from 'react';
// enums
import ESeverityLevel from 'cms/enums/ESeverityLevel';
// types
import TValidations from 'cms/types/TValidations';


type TRMDRTextArea = {

    name: string;
    title: string|null;
    rmdrRef?: any;
    
    value: string|null;
    defaultValue: string|null;
    placeholder: string|null;
    rows: number;

    errors: TValidations[]|null;
    isDisabled: boolean;
    isAutoFocus: boolean;

    onValueChanged: (name:string, value?:string|null) => void;

    className?: string;
    disabled: boolean;

}

function RMDRTextArea ( props:TRMDRTextArea ) {
    
    const [validation, setValidation] = useState<any>(null);

    useEffect(
        () => {
            let __value = props.errors?.find((item:any) => item.propertyName === props.name);

            if ( __value ) { 
                let className = "";
                let inputClassName = ""

                if (__value.severity === ESeverityLevel.ERROR) className = "bg-red-100 rounded-t-none text-red-700 p-2 rounded text-sm"
                else if (__value.severity === ESeverityLevel.WARNING) className = "bg-red-100 rounded-t-none text-orange-700 p-2 rounded text-sm"
                else if (__value.severity === ESeverityLevel.INFO) className = "bg-red-100 rounded-t-none text-blue-700 p-2 rounded text-sm"
                
                setValidation({ ...__value, className }); 
            }

            if ( !__value && validation ) setValidation(null);
        }, [ props.errors ]
    )

    function valueChanged ( event:any ) {
        if ( props.onValueChanged && event.event !== undefined ) props.onValueChanged(props.name, event.value??undefined);
        if ( validation !== null ) setValidation(null);
    }

    return (
        <>
            <textarea
                ref={props.rmdrRef}
                
                name={props.name}
                placeholder={props.placeholder||""}
                disabled={props.disabled}
                onChange={valueChanged}

                rows={props.rows}

                className={`${props.className} ${validation ? "rounded-b-none":""}`}
            />
            {
                validation && <p className={validation.className}>{ validation.errorMessage }</p>
            }
        </>
    );
}

RMDRTextArea.defaultProps = {
    name: "RMDRTextBox",
    title: null,
    rmdrRef: undefined,
    
    value: "",
    defaultValue: "",
    placeholder: "",
    rows: 1,

    validations: [],
    isDisabled: false,
    isAutoFocus: false,

    lookupDataSource: null,

    onValueChanged: null,

    className: "",
    disabled: false,
}

export default RMDRTextArea;
export type {
    TRMDRTextArea
};
