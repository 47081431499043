import _ClientTagsServiceAction from "ecommerce/actions/services/ClientTagsServiceAction";


const screenCode = "403";
class ClientTagsServiceAction extends _ClientTagsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientTagsServiceAction;
