import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import ClientCatalogCategoriesService from 'ecommerce/services/ClientCatalogCategoriesService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function ClientCatalogCategoriesServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: ClientCatalogCategoriesService.code });

    return combineReducers({

        ...reduxStorages.LIST(),
        ...reduxStorages.DETAILS(),
    
    });
}

export default ClientCatalogCategoriesServiceStore;
