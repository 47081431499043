import HttpMethodEnum from 'core/enums/EHttpMethod';
import FetchExtension from 'core/extensions/FetchExtension';
import IFetchExtension from "core/interfaces/IFetchExtension";
import { IOdataGetRequest } from "core/interfaces/IGetRequest";
import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientProductsService extends ReadOnlyOdataServiceBase {

    static code = "402";
    code = "402";

    constructor () {
        super({
            sourcePath: "client-products"
        });
    }

    async search( params: IOdataGetRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
			// protocol: EndpointProtocol.ODATA,
            method: HttpMethodEnum.GET,
			language: params.language,
            
			sourcePath: `${this.sourcePath}/search`,
			queryOptions: params.queryOptions
		}

		return FetchExtension(apiFetchConfig);
	
    }

}

export default ClientProductsService;
