// enums
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';


type TRMDRGoogleMaps = {

    languageVersions?:ILanguageVersionList[]|ILanguageVersionDetail[]|null;

}

function RMDRGoogleMaps ( props: TRMDRGoogleMaps ) {

    const googleMaps = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "googleMaps" }
        ],
        type: "find"
    });

    if ( googleMaps ) {

        return (
            <iframe
                title="newWork"
                src={googleMaps?.value||""}
                loading="lazy"
                className="h-[600px] w-full"
            />
        )

    }

    return null;
    
}

export default RMDRGoogleMaps;
export type {
    TRMDRGoogleMaps
};
