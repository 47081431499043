import React, { useState } from "react";
import cn from "classnames";
// components
import RMDRLink from "./RMDRLink";
import RMDRImage from "./RMDRImage";
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import ESizeType from "cms/enums/ESizeType";
import ERedirectType from "cms/enums/ERedirectType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";


/* types */
type TLogo = {
    
    siteSettingPayload:IClientSiteSettingDetail;
    webApplicationLanguageISOCode:string;
    className?:string;

};


/* component */
function LogoLoader () {

    return (
        <></>
    )

}

function Logo ( props: TLogo ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    return (
        <RMDRLink
            title={languageVersion?.name}
            hasNavigationDestination={true}
            navigationURL={`/${props.webApplicationLanguageISOCode}`}
            redirectType={ERedirectType.STAY_CURRENT_PAGE}
            className={cn("inline-flex focus:outline-none", props.className)}
        >
            <RMDRImage
                resourceType={EResourceType.LOGO_DEFAULT} 
                resources={props.siteSettingPayload.resources} 
                alt={languageVersion?.name} 
                title={languageVersion?.name} 
                sizeType={ESizeType.X06}
                className="max-w-52"
            />
        </RMDRLink>

    );

};

export default Logo;
