import _ClientPagesServiceAction from "cms/actions/services/ClientPagesServiceAction";


const screenCode = "20E";
class ClientPagesServiceAction extends _ClientPagesServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientPagesServiceAction;
