// redux
import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
// thunk
import { thunk } from "redux-thunk";
// reducers
import CoreReducer from "core/reducers";
import CmsReducers from "cms/reducers";
import ECommerceReducers from "ecommerce/reducers";


function Reducer () { 
    return combineReducers({
        ...CoreReducer,
        ...CmsReducers,
        ...ECommerceReducers,
    });
}

function AppReducer () { 
    return configureStore({
        reducer: Reducer(),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
        // devTools: process.env["NODE_ENV"] !== "production"
        devTools: false
    });
}

export default AppReducer;
