import React, { useEffect, useState } from "react";
import Lodash, { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom"
import { connect, ConnectedProps } from "react-redux";
// components
import SEO from "components/SEO";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientProductsServiceAction from "ecommerce/actions/screens/MarketPlace/ClientProductsServiceAction";
import ClientStoresServiceAction from "ecommerce/actions/screens/MarketPlace/ClientStoresServiceAction";
import ClientCatalogCategoriesServiceAction from "ecommerce/actions/screens/MarketPlace/ClientCatalogCategoriesServiceAction";
import StockStatusServiceAction from "ecommerce/actions/screens/MarketPlace/StockStatusServiceAction";
import MarketPlacePageAction from "ecommerce/actions/screens/MarketPlace/MarketPlacePageAction";
import ECommerceAction from 'ecommerce/actions/common/ECommerceAction';
// enums
import { EContentStatus } from "cms/enums/EContentStatus";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import IClientLanguage from "core/interfaces/IClientLanguage";
// types
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import ContainerV2 from "components/ContainerV2";
import StickyBox from 'react-sticky-box';
import ShopFilters from "components/Filters/ShopFilters";
import ProductGrid from "components/ProductGrid";
import Pagination from "components/Pagination";
import IEnum from "core/interfaces/IEnum";
import OrderBy from "components/Filters/OrderBy";
import Divider from "components/Divider";


function mapStateToProps ( state: any ) {
    console.log("MarketPlace.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage                    = state.CoreStore.language.payload;
    const webApplicationLanguageCode:string                         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string                      = state.CoreStore.languageCode.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail         = state.SiteSettingStore.details.payload;
    const siteSettingDetailError:TValidations[]|null                = state.SiteSettingStore.details.error;
    const siteSettingDetailIsLoading:boolean                        = state.SiteSettingStore.details.isLoading;

    const clientProductsListPayload:IClientProductList[]            = state.MarketPlaceStore.ClientProductsStore.list.payload;
    const clientProductsListCount:number                            = state.MarketPlaceStore.ClientProductsStore.list.count;
    const clientProductsListError:TValidations[]|null               = state.MarketPlaceStore.ClientProductsStore.list.error;
    const clientProductsListIsLoading:boolean                       = state.MarketPlaceStore.ClientProductsStore.list.isLoading;

    const marketPlacePagePayload                                    = state.MarketPlaceStore.MarketPlacePageStore.payload;
    const stockStatusPayload:IEnum[]                                = state.MarketPlaceStore.StockStatusStore.list.payload;

    const errors:TValidations[]|null|undefined  = Lodash.reject( [clientProductsListError, siteSettingDetailError], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                     = [clientProductsListIsLoading, siteSettingDetailIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
        
        siteSettingDetailPayload,

        clientProductsListPayload,
        clientProductsListCount,
        clientProductsListIsLoading,

        marketPlacePagePayload,
        stockStatusPayload,

        errors,
        isLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MarketPlace.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const clientProductsServiceAction = new ClientProductsServiceAction(dispatch);
    const clientStoresServiceAction = new ClientStoresServiceAction(dispatch);
    const marketPlacePageAction = new MarketPlacePageAction(dispatch);
    const clientCatalogCategoriesServiceAction = new ClientCatalogCategoriesServiceAction(dispatch);
    const stockStatusServiceAction = new StockStatusServiceAction(dispatch);
    const ecommerceAction = new ECommerceAction(dispatch);

    return {

        _modifyMarketPlaceStore: ( payload:any ) => {
            marketPlacePageAction.modify(payload);
        },
        _setSearchString: ( value:string ) => {
            console.log("This is it._setSearchString: ", value);
            marketPlacePageAction.modify([
                {key:"searchString", value: value},
                {key:"hasSearch", value: true},
            ]);
        },
        _setHasSearch: ( value:boolean = true, isNewSearch:boolean = false ) => {
            console.log("This is it._setSearchString: ", value, isNewSearch);
            let _payload:any = [
                {key:"hasSearch", value},
            ]
            if (isNewSearch) {
                _payload = [
                    {key:"orderBy", value: "catalogCategoryName"},
                    {key:"currentPage", value: 1},
                    {key:"isFirstSearch", value: true},
                    {key:"scrollTo", value: true},
                    ..._payload
                ]
            }
            
            marketPlacePageAction.modify(_payload);
        },
        _setFirstSearch: ( value:boolean ) => {
            console.log("This is it._setSearchString: ", value);
            marketPlacePageAction.modify([
                {key:"isFirstSearch", value}
            ]);
        },
        _setScrollTo: ( value:boolean ) => {
            console.log("This is it._setSearchString: ", value);
            marketPlacePageAction.modify([
                {key:"scrollTo", value},
            ]);
        },
        _setLastPage: ( lastPage:number ) => {
            console.log("This is it._setSearchString: ", lastPage);
            marketPlacePageAction.modify([
                {key:"lastPage", value: lastPage},
            ]);
        },
        _setCurrentPage: ( currentPage:number ) => {
            console.log("This is it._setSearchString: ", currentPage);
            marketPlacePageAction.modify([
                {key:"currentPage", value: currentPage},
                // {key:"hasSearch", value: true},
                // {key:"scrollTo", value: true},
            ]);
        },
        _setOrderBy: ( value:string ) => {
            console.log("This is it._setSearchString: ", value);
            marketPlacePageAction.modify([
                {key:"orderBy", value},
                {key:"currentPage", value: 1},
                {key:"hasSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },

        _search: ( payload:any, webApplicationLanguageCode:string ) => {
            console.log("This is it._setSearchString: ", payload, webApplicationLanguageCode);

            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`],
                count: true,
                top: payload.itemsPerPage,
                skip: (payload.currentPage-1) * payload.itemsPerPage
            };
            
            if(!isEmpty(payload.filters) && payload.filters) {
                queryOptions.filter?.push("and");
                queryOptions.filter = [...queryOptions?.filter||[], ...payload.filters];
            }

            if ( payload.orderBy && !isEmpty(payload.orderBy) ) {
                queryOptions["orderBy"] = [payload.orderBy]
            }

            console.log("queryOptions:", payload.filters, queryOptions);

            clientProductsServiceAction.search({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
            
        },

        _getCatalogCategories: ( webApplicationLanguageCode:string ) => {

            clientCatalogCategoriesServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        filter: [`status eq ${EContentStatus.ACTIVE}`, "and", "level eq '0.1'"],
                        count: true,
                        top: 1000
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _getStockStatus: ( webApplicationLanguageCode:string ) => {

            stockStatusServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        top: 1000
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _openProductDetailPopup: ( value:IClientProductList ) => {

            ecommerceAction.openProductDetailPopup(value);
        
        },

        _detailProduct: ( productId:string, webApplicationLanguageCode:string ) => {

            clientProductsServiceAction.details({ servicePayload: { key: productId, language: webApplicationLanguageCode } });

        },
        _cleanProductDetail: () => {

            clientProductsServiceAction.detailsClean();

        },

        _cleanSearch: () => {

            marketPlacePageAction.clean();
            clientProductsServiceAction.listClean();
        },
        
        // others
        _hideLoader: () => { 
            coreAction.hideLoader();
        },
        _showLoader: () => { 
            coreAction.showLoader();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TMarketPlace<T> = {

} & T;


/* component */
function MarketPlaceLoader () {

    return (
        <></>
    )

}

function MarketPlace ( props:TMarketPlace<PropsFromRedux> ) {
    console.log("MarketPlace.rendered: ", props);

    const [ searchParams ] = useSearchParams();

    const [ init ] = useState([
        {key:"orderBy", value: "price asc"},
        {key:"itemsPerPage", value: 20},
        {key:"currentPage", value: 1},
        {key:"scrollTo", value: true},
        {key:"hasSearch", value: true},
    ]);

    useEffect(
        () => {
            if ( Lodash.isEmpty(props.marketPlacePagePayload) ) {
                props._getCatalogCategories(props.webApplicationLanguageCode);
                props._getStockStatus(props.webApplicationLanguageCode);
            }

            return function cleanup () {
                props._showLoader();
                props._cleanSearch();
            }
        }, []
    )

    useEffect(
        () => {

            let filters: string[] = [];

            const _searchString = searchParams.get("q")?.trim();
            const _categories = searchParams.get("c")?.trim();
            const _tags = searchParams.get("t")?.trim();
            const _brands = searchParams.get("b")?.trim();
            const _price = searchParams.get("p")?.trim();
            const _orderBy = searchParams.get("o")?.trim();
            let _currentPage = parseInt(searchParams.get("cp")?.trim()||"1");
            if ( isNaN(_currentPage) ) {
                _currentPage = 1;
            }

            console.log("This is it.searchParams: ", {_searchString, _categories, _tags, _brands, _price, _orderBy, _currentPage, window, aaa: searchParams.get("cp")?.trim()} )

            // string filter
            if ( _searchString && !isEmpty(_searchString) ) {
                if ( filters.length > 0 ) filters.push("and");
                filters.push(`(contains(name, '${_searchString}') or contains(catalogCategoryName, '${_searchString}'))`);
            }

            // categories filter
            if ( _categories && !isEmpty(_categories) ) {
                let filterItems = [];
                let _categoriesSplit = _categories.split(",");
                for ( let item of _categoriesSplit ) {
                    filterItems.push(`catalogCategoryRelationHierarchy eq '${item}'`);
                }
                if ( filterItems && !isEmpty(filterItems) ) {
                    if ( filters.length > 0 ) filters.push("and");
                    filters.push(`(${filterItems.join(" or ")})`);
                }
            }

            // tags filter
            if ( _tags && !isEmpty(_tags) ) {
                let filterItems = [];
                let _tagsSplit = _tags.split(",");
                for ( let item of _tagsSplit ) {
                    filterItems.push(`tags/any(d: d/id eq ${item})`);
                }
                if ( filterItems && !isEmpty(filterItems) ) {
                    if ( filters.length > 0 ) filters.push("and");
                    filters.push(`(${filterItems.join(" or ")})`);
                }
            }

            // brand filter
            if ( _brands && !isEmpty(_brands) ) {
                let filterItems = [];
                let _brandsSplit = _brands.split(",");
                for ( let item of _brandsSplit ) {
                    filterItems.push(`contentId eq ${item}`);
                }
                if ( filterItems && !isEmpty(filterItems) ) {
                    if ( filters.length > 0 ) filters.push("and");
                    filters.push(`(${filterItems.join(" or ")})`);
                }
            }

            // price filter
            if ( _price && !isEmpty(_price) ) {
                let filterItems = [];
                let _priceSplit = _price.split("-");
                
                let priceMin = parseInt(_priceSplit[0]);
                let priceMax = parseInt(_priceSplit[1]);
                
                if ( priceMin > -1 ) { filterItems.push(`price ge ${priceMin}`) }
                if ( priceMax > -1 ) { filterItems.push(`price le ${priceMax}`) }
                
                if ( filterItems && !isEmpty(filterItems) ) {
                    if ( filters.length > 0 ) filters.push("and");
                    filters.push(`(${filterItems.join(" and ")})`);
                }
            }

            if ( Lodash.isEmpty(props.marketPlacePagePayload) ) {
                let _init = [
                    ...init,
                    { key:"filters", value: filters },
                    { key:"orderBy", value: _orderBy },
                    { key:"currentPage", value: _currentPage },
                ];
                props._modifyMarketPlaceStore(_init);
            }
            else {
                props._modifyMarketPlaceStore([
                    { key:"hasSearch", value: true },
                    { key:"filters", value: filters },
                    { key:"orderBy", value: _orderBy },
                    { key:"currentPage", value: _currentPage },
                ]);
            }

        }, [ searchParams ]
    )    

    useEffect(
        () => {
            if (props.marketPlacePagePayload?.hasSearch) {
                console.log("This is it: ", props.marketPlacePagePayload);
                props._setHasSearch(false);
                props._search(props.marketPlacePagePayload, props.webApplicationLanguageCode);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        }, [props.marketPlacePagePayload]
    )
    
    useEffect(
        () => {
            // if (props.marketPlacePagePayload?.scrollTo) {
                // setTimeout(
                //     () => {
                //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                //     }, 200
                // );
                props._setFirstSearch(false);
                // props._setScrollTo(false);

                setTimeout(() => { props._hideLoader() }, 100);
            // }
        }, [props.clientProductsListPayload]
    )

    useEffect(
        () => {
            try {
                let _lastPage = Math.ceil(props.clientProductsListCount/props.marketPlacePagePayload.itemsPerPage);
                props._setLastPage(_lastPage);
            }
            catch(ex) {}
        }, [props.clientProductsListCount]
    )

    function paginationOnClick ( event:React.MouseEvent<HTMLAnchorElement, MouseEvent>, value:number ) {

        props._setCurrentPage(value);

    }

    function onClick ( event: React.MouseEvent<HTMLDivElement, MouseEvent>, value:IClientProductList ) {

        props._openProductDetailPopup(value);

    }

    return (
        <>
            
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="showroom-page" />

            <Divider className="mb-0" />

            <ContainerV2>
                <div className={`flex pt-8 pb-16 lg:pb-20`}>
                    <div className="flex-shrink-0 pr-24 hidden lg:block w-96">
                        <StickyBox offsetTop={50} offsetBottom={20}>
                            <ShopFilters siteSettingPayload={props.siteSettingDetailPayload} />
                        </StickyBox>
                    </div>

                    <div className="w-full lg:-ml-9">
                        <OrderBy totalItemCount={props.clientProductsListCount} isLoading={props.clientProductsListIsLoading} siteSettingPayload={props.siteSettingDetailPayload} />
                        <ProductGrid payload={props.clientProductsListPayload} isLoading={props.clientProductsListIsLoading} stockStatusDataSource={props.stockStatusPayload} siteSettingPayload={props.siteSettingDetailPayload} onClick={onClick} />

                        {
                            props.marketPlacePagePayload.lastPage > 1 &&
                                <Pagination pageSize={props.marketPlacePagePayload.lastPage} disabled={props.clientProductsListIsLoading} className="mt-10 md:mt-40 mb-45" />
                        }
                    </div>
                </div>
            </ContainerV2>

        </>
    )
}

const Component = React.memo(MarketPlace, ( prevProps:TMarketPlace<PropsFromRedux>, nextProps:TMarketPlace<PropsFromRedux> ) => {
    console.log("MarketPlace.memo", { prevProps, nextProps });
    return !(
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.marketPlacePagePayload !== nextProps.marketPlacePagePayload
    )
});

export default connector(Component);
