import React from "react";
import { isEmpty } from "lodash";
// enums
import { EViewType } from "cms/enums/EViewType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


type TRMDRTextContent = {

    languageVersion?: ILanguageVersionList|ILanguageVersionDetail|null;
    languageVersions?: ILanguageVersionList[]|ILanguageVersionDetail[]|null;

    isLoading?:boolean;
    showNameField?:boolean;

    titleClassName?:string;
    contentClassName?:string;

}

const RMDRTextContent = ( props:TRMDRTextContent ) => {
    console.log("RMDRTextContent.rendered", props)

    if ( props.languageVersion && !isEmpty(props.languageVersion) && props.languageVersion?.viewType === EViewType.TEXT  ) {
    
        return (
            <>
               { props.showNameField ? <h2 className={props.titleClassName}>{props.languageVersion.name}</h2> : (null)}
                <div className={`ck-content ${props.contentClassName}`} dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.languageVersion?.value)}} />
            </>
            
        );

    }

    return null;
}

const Component = React.memo(RMDRTextContent, (prevProps:TRMDRTextContent, nextProps:TRMDRTextContent) => {
    console.log("RMDRTextContent.memo", { prevProps, nextProps });

    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.languageVersion !== nextProps.languageVersion
    )
});

export default Component;
export type {
    TRMDRTextContent
};
