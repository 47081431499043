import React, { useEffect, useState } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
// components
import ListMenu from './ListMenu';
// interfaces
import IClientLanguage from 'core/interfaces/IClientLanguage';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
// actions
import ClientMenuItemServiceAction from "cms/actions/components/Header/ClientMenuItemServiceAction";
import ContentMetricsServiceAction from "cms/actions/components/Header/ContentMetricsServiceAction";
// utilities
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
// enums
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
// style
import { HiMenu } from 'react-icons/hi';
// types
import TValidations from 'cms/types/TValidations';
import { EContentStatus } from 'cms/enums/EContentStatus';
import IClientMenuItemList from 'cms/interfaces/IClientMenuItemList';
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
import { isEmpty } from 'lodash';
import MenuComposer from 'cms/utilities/MenuComposer';
import TMenu from 'cms/types/TMenu';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import IClientCatalogCategoryList from 'ecommerce/interfaces/IClientCatalogCategoryList';
import { EContentMetricsType } from 'core/enums/EContentMetricsType';


function mapStateToProps ( state: any ) {
    console.log("CategoryMenu.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const clientMenuItemGet:IClientMenuItemList[]|null              = state.HeaderStore.ClientMenuItemStore.get?.["MNGRP002"];
    const clientMenuItemGetError:TValidations[]|null                = state.HeaderStore.ClientMenuItemStore.get?.error;
    const clientMenuItemGetIsLoading:boolean                        = state.HeaderStore.ClientMenuItemStore.get?.isLoading;

    const catalogCategoryListPayload:IClientCatalogCategoryList[] = state.ClientCatalogCategoryStore.list.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        clientMenuItemGet,
        clientMenuItemGetError,
        clientMenuItemGetIsLoading,

        catalogCategoryListPayload

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("CategoryMenu.mapDispatchToProps");

    const clientMenuItemServiceAction = new ClientMenuItemServiceAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {

        _getClientMenuItems: ( webApplicationLanguageCode:string, mapLayoutName:string, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMenuItemServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
        },

        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

        _clean: () => {

        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TCategoryMenu<T> = {

} & T;


/* component */
function CategoryMenuLoader () {

    return (
        <></>
    )

}


function CategoryMenu ( props:TCategoryMenu<PropsFromRedux> ) {
    console.log("CategoryMenu.rendered:", props);

    const allCategories = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingDetailPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "allCategories" }
        ],
        type: "find"
    });

    const [ navigation, setNavigation ] = useState<TMenu[]|null>(null);

    useEffect(
        () => {
            if ( !isEmpty(props.webApplicationLanguageCode)) {
                props._getClientMenuItems(props.webApplicationLanguageCode, "MNGRP_002", "MNGRP002");
            }
        }, [ props.webApplicationLanguageCode ]
    )

    useEffect(
        () => {

            if ( !isEmpty(props.clientMenuItemGet) ) {
                let parentMenuItems = props.clientMenuItemGet?.filter((item:any) => item.parentId === null);
                let navigation = MenuComposer(props.clientMenuItemGet, parentMenuItems, props.catalogCategoryListPayload);
                console.log("navigation: ", navigation);
                setNavigation(navigation);
            }

        }, [ props.clientMenuItemGet ]
    )

    return (
        <div className='relative flex-shrink-0 categoryMenu hidden lg:block'>
            <div className="flex items-center justify-center gap-2 px-3.5 xl:px-4 text-sm relative before:absolute before:-bottom-2.5 before:h-2.5 before:w-full before:z-10 font-semibold text-white transition-colors rounded-md cursor-pointer h-11 bg-floorsAndExtras-600 hover:bg-floorsAndExtras-700">
                <HiMenu className="text-xl" />
                {allCategories?.value}
            </div>
            {
                navigation && Array.isArray(navigation) && (
                    <div className="absolute invisible bg-white opacity-0 subMenu shadow-header left-0">
                        <ul className="relative py-5 text-sm text-body">
                            {
                                navigation.map((item, index) => {
                                    const dept: number = 1;
                                    const menuName: string = `sidebar-menu-${dept}-${index}`;

                                    return (
                                        <ListMenu
                                            dept={dept}
                                            payload={item}
                                            // hasSubMenu={item.items}
                                            hasMegaMenu={item.items}
                                            // hasBrands={menu.brands}
                                            // hasBanners={menu.banners}
                                            menuName={menuName}
                                            key={menuName}
                                            menuIndex={index}
                                            webApplicationLanguageISOCode={props.webApplicationLanguageISOCode}
                                            onClick={(event:any, contentId?:string) => { if (contentId) props._viewEvent(contentId, props.webApplicationLanguageCode) } }
                                        />
                                    );
                                })
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

const Component = React.memo(CategoryMenu, ( prevProps: TCategoryMenu<PropsFromRedux>, nextProps: TCategoryMenu<PropsFromRedux> ) => {
    console.log("CategoryMenu.memo", { prevProps, nextProps });
    return !(
        prevProps.clientMenuItemGet !== nextProps.clientMenuItemGet
    );
});

export default connector(Component);
