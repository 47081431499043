import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import cn from 'classnames';
//
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import BannerCard, { BannerCardLoader } from "components/BannerCard";
import { TSLDRGRPLayout } from "../type";
import { EResourceType } from "cms/enums/EResourceType";
import IClientCatalogCategoryList from "ecommerce/interfaces/IClientCatalogCategoryList";
import { EContentType } from "cms/enums/EContentType";

function mapStateToProps ( state: any ) {
    console.log("Slider.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const webApplicationLanguage: IClientLanguage               = state.CoreStore.language?.payload;
    const webApplicationLanguageCode: string                    = webApplicationLanguage.code;
    const webApplicationLanguageISOCode: string                 = state.CoreStore.languageCode?.payload;

    const catalogCategoryListPayload:IClientCatalogCategoryList[] = state.ClientCatalogCategoryStore.list.payload;

    return {

        siteSettingDetailPayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        catalogCategoryListPayload
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("SliderGroup.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function SLDRGRP004Loader () {

    return (
        <>
        </>
    )

}

function SLDRGRP004 ( props: TSLDRGRPLayout<PropsFromRedux> ) {
    console.log("SLDRGRP004.rendered: ", props);

    if ( props.payload && isEmpty(props.payload) ) {
        return <SLDRGRP004Loader />
    }
    // else if (props.sliderItemListIsLoading === false && isEmpty(props.sliderItemListPayload) && isEmpty(props.sliderItemListError)) {
    //     return (
    //         <div className="mb-12 md:mb-14 xl:mb-16 max-w-[1920px] mx-auto">
    //             <NoData
    //                 className="flex flex-col items-center justify-center h-80 bg-gray-100"
    //                 textClassName="text-center text-xl"
    //                 iconClassName="stroke-1 text-8xl"
    //                 siteSettingPayload={props.siteSettingDetailPayload}
    //             />
    //         </div>
    //     )
    // }
    else if (!isEmpty(props.payload)) {

        return (
            <>
            </>
        );

    }

    return null;

}

const Component = React.memo(SLDRGRP004, ( prevProps: TSLDRGRPLayout<PropsFromRedux>, nextProps: TSLDRGRPLayout<PropsFromRedux> ) => {
    console.log("SLDRGRP004.memo", { prevProps, nextProps });

    return !(
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode
        || prevProps.payload !== nextProps.payload
    )
});

export default connector(Component);
