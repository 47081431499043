import { isEmpty } from 'lodash';
// component
import RMDRVariantImage from 'components/RMDRVariantImage';
import Gallery05 from 'components/RMDRGallery/components/Gallery05';
// enums
import { EResourceType } from 'cms/enums/EResourceType';
// type
import { TGallery } from '../type';
//
import defaultImage from "assets/img/default-image-1024.webp";
import ApplicationDocumentSelector from 'cms/utilities/ApplicationDocumentSelector';
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';


function Gallery02 ( props:TGallery ) {

    if ( props.resources && !isEmpty(props.resources) ) {

        const resources = ApplicationDocumentSelector<IApplicationDocument[]>({...props, type: "filter"});

        if ( resources.length === 1 ) {
            return (
                <RMDRVariantImage resources={props.resources} alt={props.alt} title={props.title} />
            )
        } else if ( resources.length > 1 ) {
            return (
                <Gallery05 {...props} alt={props.alt} title={props.title} />
            )
        } else {
            return (
                <img src={defaultImage} alt={"default-image-1024"} loading="lazy" title='default image 1024' />
            )
        }

    }

}

export default Gallery02;
