import cn from 'classnames';
import IClientProductList from 'ecommerce/interfaces/IClientProductList';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import RMDRImage from 'components/RMDRImage';
import { EResourceType } from 'cms/enums/EResourceType';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
import IEnum from 'core/interfaces/IEnum';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import { MdKeyboardDoubleArrowDown } from 'react-icons/md';
import RMDRLink from './RMDRLink';
import ERedirectType from 'cms/enums/ERedirectType';


type TProductCard = {
    payload: IClientProductList;
    stockStatusDataSource?:IEnum[];

    className?: string;
    contactClassName?: string;
    imageContentClassName?: string;
    imageClassName?: string;
    variant?:
    | 'grid'
    | 'gridSlim'
    | 'list'
    | 'listSmall'
    | 'gridModern'
    | 'gridModernWide'
    | 'gridTrendy'
    | 'rounded'
    | 'circle';
    imgWidth?: number;
    imgHeight?: number;
    hideProductDescription?: boolean;
    showCategory?: boolean;
    showRating?: boolean;
    bgTransparent?: boolean;
    bgGray?: boolean;
    demoVariant?: 'ancient';
    disableBorderRadius?: boolean;
    disableActionButton?: boolean;
    // onClick: ( event: React.MouseEvent<HTMLDivElement, MouseEvent> ) => void;
}

function ProductCardLoader ( props: { variant?:string, className?:string } ) {

    return (
        <div
            className={cn(
                `bg-gray-100`,
                {
                    'pr-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-product':
                        props.variant === 'grid' ||
                        props.variant === 'gridModern' ||
                        props.variant === 'gridModernWide' ||
                        props.variant === 'gridTrendy',
                    
                    'pr-0 md:pb-1 flex-col items-start':
                        props.variant === 'gridSlim',
                    'items-center border border-gray-100 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-listProduct':
                        props.variant === 'listSmall',
                    'flex-row items-center transition-transform ease-linear pr-2 lg:pr-3 2xl:pr-4':
                        props.variant === 'list'
                },
                props.className
            )}
        />
    )

}

function ProductCard(props: TProductCard ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const variant = props.payload?.variants?.[0];

    return (
        <RMDRLink
            title={languageVersion?.name}
            hasNavigationDestination={true}
            navigationURL={languageVersion?.contentRouteNavigationURL}
            redirectType={ERedirectType.STAY_CURRENT_PAGE}
            className={cn(
                `group box-border overflow-hidden flex ${!props.disableBorderRadius && 'rounded-md'
                } cursor-pointer`,
                {
                    'pr-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-product':
                        props.variant === 'grid' ||
                        props.variant === 'gridModern' ||
                        props.variant === 'gridModernWide' ||
                        props.variant === 'gridTrendy',
                    ' bg-white':
                        (props.variant === 'grid' && !props.bgGray) ||
                        (props.variant === 'gridModern' && !props.bgGray) ||
                        (props.variant === 'gridModernWide' && !props.bgGray) ||
                        (props.variant === 'gridTrendy' && !props.bgGray) ||
                        (props.variant === 'gridSlim' && !props.bgGray),
                    'bg-gray-200': props.variant === 'list' || props.bgGray,
                    'pr-0 md:pb-1 flex-col items-start':
                        props.variant === 'gridSlim',
                    'items-center border border-gray-100 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-listProduct':
                        props.variant === 'listSmall',
                    'flex-row items-center transition-transform ease-linear pr-2 lg:pr-3 2xl:pr-4':
                        props.variant === 'list',
                    '!bg-transparent': props.variant === 'grid' && props.bgTransparent === true,
                },
                props.className
            )}
        >
        {/* <RMDRLink
            className={cn(
                `group box-border overflow-hidden flex ${!props.disableBorderRadius && 'rounded-md'
                } cursor-pointer`,
                {
                    'pr-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-product':
                        props.variant === 'grid' ||
                        props.variant === 'gridModern' ||
                        props.variant === 'gridModernWide' ||
                        props.variant === 'gridTrendy',
                    ' bg-white':
                        (props.variant === 'grid' && !props.bgGray) ||
                        (props.variant === 'gridModern' && !props.bgGray) ||
                        (props.variant === 'gridModernWide' && !props.bgGray) ||
                        (props.variant === 'gridTrendy' && !props.bgGray) ||
                        (props.variant === 'gridSlim' && !props.bgGray),
                    'bg-gray-200': props.variant === 'list' || props.bgGray,
                    'pr-0 md:pb-1 flex-col items-start':
                        props.variant === 'gridSlim',
                    'items-center border border-gray-100 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-listProduct':
                        props.variant === 'listSmall',
                    'flex-row items-center transition-transform ease-linear pr-2 lg:pr-3 2xl:pr-4':
                        props.variant === 'list',
                    '!bg-transparent': props.variant === 'grid' && props.bgTransparent === true,
                },
                props.className
            )}
            // onClick={props.onClick}
            // role="button"
            text={languageVersion?.name||""}
        > */}
            <div
                className={cn(
                    'flex w-full h-auto',
                    {
                        'mb-3 md:mb-3.5': props.variant === 'grid',
                        'mb-3 md:mb-3.5 pb-0': props.variant === 'gridSlim',
                        'flex-shrink-0 w-32 sm:w-44 md:w-36 lg:w-44':
                            props.variant === 'listSmall',
                        'mb-3 md:mb-3.5 relative':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',
                    },
                    props.imageContentClassName
                )}
            >

                <RMDRImage
                    resourceType={EResourceType.OUTER_IMAGE} 
                    resources={variant?.resources}
                    alt={languageVersion?.name} 
                    title={languageVersion?.name} 
                    width={props.demoVariant === 'ancient' ? 352 : props.imgWidth}
                    height={props.demoVariant === 'ancient' ? 452 : props.imgHeight}
                    className={
                        cn(
                            "bg-gray-300 object-cover",
                            props.imageClassName,
                            {
                                'rounded-s-md':!props.disableBorderRadius
                            },
                            {
                                'w-full transition duration-200 ease-in':
                                    props.variant === 'grid' ||
                                    props.variant === 'gridModern' ||
                                    props.variant === 'gridModernWide' ||
                                    props.variant === 'gridTrendy',
                                'rounded-md group-hover:rounded-b-none':
                                    (props.variant === 'grid' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridModern' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridModernWide' && !props.disableBorderRadius) ||
                                    (props.variant === 'gridTrendy' && !props.disableBorderRadius),
                                'rounded-md transition duration-150 ease-linear transform group-hover:scale-105':
                                    props.variant === 'gridSlim',
                                'rounded-s-md transition duration-200 ease-linear transform group-hover:scale-105':
                                    props.variant === 'list',
                            }
                        )
                    }
                />

                <div className="absolute top-3.5 md:top-4 3xl:top-5 left-3.5 md:left-4 3xl:left-5 flex flex-col gap-y-1 items-start">
                    {
                        props.payload.variants?.[0]?.discountedPercentage &&
                            (props.variant === 'gridModernWide' || props.variant === 'gridModern' || props.variant === 'gridTrendy') && 
                                (
                                    <span className="bg-[red] text-white font-bold text-base md:text-sm leading-5 rounded-md inline-block px-1 sm:px-1.5 xl:px-2 py-0.5 sm:py-1">
                                        <p>
                                            <span className="sm:hidden">-</span>
                                            {props.payload.variants?.[0]?.discountedPercentage}% <span className="hidden sm:inline">OFF</span>
                                        </p>
                                    </span>
                                )
                    }

                    {/* {
                        props.product?.isNewArrival &&
                            (props.variant === 'gridModernWide' ||
                                props.variant === 'gridModern' ||
                                props.variant === 'gridTrendy') && (
                                <span className="bg-[#B26788] text-white text-10px md:text-xs leading-5 rounded-md inline-block px-1.5 sm:px-1.5 xl:px-2 py-0.5 sm:py-1">
                                    <p>
                                        New <span className="hidden sm:inline">Arrival</span>
                                    </p>
                                </span>
                            )
                    } */}
                </div>

                {/* {!props.disableActionButton && props.variant === 'gridModernWide' && (
                    <div className="absolute right-2 sm:right-3 bottom-6 space-y-2 w-[32px] sm:w-[42px] lg:w-[52px]">
                        <ProductViewIcon className="w-full transition duration-300 ease-in delay-100 bg-white rounded-md sm:opacity-0 group-hover:opacity-100" />
                        <ProductWishIcon className="w-full transition duration-300 ease-in delay-200 bg-white rounded-md sm:opacity-0 group-hover:opacity-100" />
                        <ProductCompareIcon className="w-full transition duration-300 ease-in delay-300 bg-white rounded-md sm:opacity-0 group-hover:opacity-100" />
                    </div>
                )} */}

                {
                    props.payload.variants?.length > 1 ? (
                        <div className="absolute px-2 bottom-4 flex w-full flex-wrap gap-1">
                            { 
                                props.payload.variants.slice(0,2).map((item) => (
                                    <RMDRLink
                                        key={item.id}
                                        title={item.variantGroupName}
                                        text={item.variantGroupName}
                                        hasNavigationDestination={true}
                                        navigationURL={`${languageVersion?.contentRouteNavigationURL}?vid=${item.id}`}
                                        redirectType={ERedirectType.STAY_CURRENT_PAGE}
                                        className="p-1 text-sm md:text-base border border-black rounded-md bg-white hover:bg-floorsAndExtras-600 hover:text-white hover:border-none"
                                    />
                                ))
                            }
                            {
                                props.payload.variants.length > 2 ? (
                                    <RMDRLink
                                        key={"other"}
                                        title={`+${props.payload.variants.length-2}`}
                                        text={`+${props.payload.variants.length-2}`}
                                        hasNavigationDestination={true}
                                        navigationURL={`${languageVersion?.contentRouteNavigationURL}`}
                                        redirectType={ERedirectType.STAY_CURRENT_PAGE}
                                        className="p-1 text-sm md:text-base border border-black rounded-md bg-white hover:bg-floorsAndExtras-600 hover:text-white hover:border-none"
                                    />
                                ) : null
                            }
                        </div>
                    ) : null
                }
            </div>
            <div
                className={cn(
                    'w-full overflow-hidden p-2',
                    {
                        'md:px-2.5 xl:px-4': props.variant === 'grid',

                        'px-2 md:px-2.5 xl:px-4 h-full flex flex-col':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',

                        'pl-0': props.variant === 'gridSlim',
                        'px-4 lg:px-5 2xl:px-4': props.variant === 'listSmall',
                    },
                    props.contactClassName
                )}
            >
                {/* {(props.variant === 'gridModern' ||
                    props.variant === 'gridModernWide' ||
                    props.variant === 'gridTrendy') && (
                        <div className="flex items-center py-2 gap-x-2">
                            <svg
                                className="w-4 h-4 sm:w-6 sm:h-6 text-[#FBD103]"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <span className="text-xs font-semibold truncate sm:text-sm text-heading">
                                4.5
                            </span>
                            {
                                !isEmpty(props.stockStatusDataSource) && (
                                    
                                        props.payload.stockStatus === EStockStatus.OUT_OF_STOCK && (
                                            <span className="text-xs sm:text-sm leading-5 pl-3 font-semibold text-red-600">
                                                {props.stockStatusDataSource?.find((item) => item.id === props.payload.stockStatus)?.name}
                                            </span>
                                        ),

                                        props.payload.stockStatus === EStockStatus.IN_STOCK && (
                                            <span className="text-xs sm:text-sm leading-5 pl-3 font-semibold text-emerald-500">
                                                {props.stockStatusDataSource?.find((item) => item.id === props.payload.stockStatus)?.name}
                                            </span>
                                        )
                                    
                                )
                            }
                        </div>
                    )} */}
                {/* {!!(props.showCategory || props.showRating) && (
                    <div className="flex flex-col md:flex-row md:items-center lg:flex-row xl:flex-row 2xl:flex-row  mb-0.5 items-start">
                        {!!props.showCategory && (
                            <h3
                                className={cn(
                                    'font-semibold text-sm mb-1 md:mb-0 mr-2 md:mr-3',
                                    {
                                        'text-white': props.bgTransparent,
                                        'text-black/70': !props.bgTransparent,
                                    }
                                )}
                            >
                                Category
                            </h3>
                        )}
                        {!!props.showRating && <RatingDisplay rating={2.5} />}
                    </div>
                )} */}
                <h2
                    className={cn('line-clamp-3 mb-1', {
                        'text-sm h-10 md:text-base md:h-12': props.variant === 'grid',
                        'font-semibold': props.demoVariant !== 'ancient',
                        'font-bold': props.demoVariant === 'ancient',
                        'text-xs h-8 sm:text-sm sm:h-10 3xl:text-base md:h-[4.5rem]':
                            props.variant === 'gridModern' ||
                            props.variant === 'gridModernWide' ||
                            props.variant === 'gridTrendy',
                        'md:mb-1.5 text-sm h-10 sm:text-base sm:h-12 md:text-sm md:h-10 lg:text-base lg:h-12 xl:text-lg xl:h-14':
                            props.variant === 'gridSlim',
                        'text-sm h-10 sm:text-base sm:h-12 md:mb-1.5 pb-0': props.variant === 'listSmall',
                        'text-sm h-10 sm:text-base sm:h-12 md:text-sm md:h-10 lg:text-base lg:h-12 xl:text-lg xl:h-14 md:mb-1.5':
                            props.variant === 'list',
                        'text-white': props.bgTransparent,
                        'text-heading': !props.bgTransparent,
                    })}
                >
                    {languageVersion?.name}
                </h2>
                {
                    !props.hideProductDescription && languageVersion?.value && 
                        (
                            <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                        )
                }
                <div
                    className={`font-semibold text-base mt-1.5 flex flex-wrap gap-x-2 
                                ${props.variant === 'grid' ? 'lg:text-lg lg:mt-2.5' : 'sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3'}
                                ${props.variant === 'gridModern' || props.variant === 'gridModernWide' || props.variant === 'gridTrendy' ? 'flex flex-row !mt-auto gap-3' : ''} 
                                ${props.bgTransparent ? 'text-white' : 'text-heading'}`
                    }
                >
                    {
                        variant?.discountedPercentage &&
                            <div className="flex items-center font-bold text-base text-red-600 md:text-sm lg:text-md 2xl:text-lg">
                                <MdKeyboardDoubleArrowDown className="text-red-600" />
                                {variant?.discountedPercentage}%
                            </div>
                    }
                    {
                        variant?.discountedPercentage ? (
                            <span className={`inline-block h-7 ${props.demoVariant === 'ancient' && 'font-bold text-gray-900 text-lg'}`}>
                                {variant?.currencySign||"$"}{variant?.discountedPrice}
                            </span>
                        ) : (
                            <span className={`inline-block h-7 ${props.demoVariant === 'ancient' && 'font-bold text-gray-900 text-lg'}`}>
                                {
                                    variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                }
                            </span>
                        )
                    }
                    {
                        variant?.discountedPercentage && 
                            (
                                <del className={`text-base font-normal md:text-sm lg:text-md 2xl:text-lg ${props.bgTransparent ? 'text-white/70' : 'text-gray-800'}`}>
                                    {
                                        variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                    }
                                </del>
                            )
                    }
                </div>
            </div>

            {/* {(!props.disableActionButton && (props.variant === 'gridTrendy' || props.variant === 'gridModern')) && (
                <div className="absolute flex right-2 bottom-2 gap-x-2">
                    <ProductWishIcon className="transition ease-in duration-300 sm:opacity-0 group-hover:opacity-100 delay-200 w-[35px] sm:w-[42px] lg:w-[52px] bg-[#F1F3F4] rounded-md" />
                    <ProductCompareIcon className="transition ease-in duration-300 sm:opacity-0 group-hover:opacity-100 delay-300 w-[35px] sm:w-[42px] lg:w-[52px] bg-[#F1F3F4] rounded-md" />
                </div>
            )} */}
        </RMDRLink>
    )
}

ProductCard.defaultProps = {

    className: '',
    contactClassName: '',
    imageContentClassName: '',
    variant: 'list',
    imgWidth: 340,
    imgHeight: 440,
    hideProductDescription: false,
    showCategory: false,
    showRating: false,
    bgTransparent: false,
    bgGray: false,
    disableBorderRadius: false,
    disableActionButton: false,

}

export default ProductCard;
export {
    ProductCardLoader
}
