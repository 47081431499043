import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { HiOutlineSelector, HiCheck } from 'react-icons/hi';
import cn from "classnames";


type TListBox = {

    title: string;
    value: any;
    options: any[];

    disabled:boolean;
    selectorKey?: any;

    onChange: ( value:any ) => void;
    className?:string;
    listOptionClassName?:string;

}


function ListBox ( props:TListBox ) {
    console.log("ListBox: ", props);

    return (
        <Listbox value={props.value} by={props.selectorKey||"id" as any} onChange={props.onChange} disabled={props.disabled}>
            
                    <div className={cn("relative ml-2 lg:ml-0 z-10 min-w-[180px]", props.className) }>
                        <ListboxButton className="border border-gray-300  text-heading text-[13px] md:text-sm font-semibold  relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm cursor-pointer">
                            <span className="block truncate">{props.value?.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <HiOutlineSelector
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </ListboxButton>
                        
                            <ListboxOptions
                                anchor="bottom"
                                transition
                                className={cn("absolute py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0", props.listOptionClassName)}
                            >
                                {
                                    props.options?.map((option, personIdx) => (
                                        <ListboxOption
                                            key={personIdx}
                                            className="text-gray-900 data-[focus]:bg-gray-100 cursor-default select-none relative py-2 pl-10 pr-4"
                                            value={option}
                                        >
                                            {
                                                ({ focus, selected }) => (
                                                    <>
                                                        <span className={`${selected ? 'font-bold' : 'font-normal'} block truncate`}>
                                                            {option?.name}
                                                        </span>
                                                        {
                                                            selected ? (
                                                                <span className="check-icon absolute inset-y-0 left-0 flex items-center pl-3">
                                                                    <HiCheck className="w-5 h-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null
                                                        }
                                                    </>
                                                )
                                            }
                                        </ListboxOption>
                                    ))
                                }
                            </ListboxOptions>
                    </div>
        </Listbox>
    );
}

ListBox.defaultProps = {

    disabled: false,

}

export default ListBox;
