import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientStoresService extends ReadOnlyOdataServiceBase {

    static code = "403";
    code = "403";

    constructor () {
        super({
            sourcePath: "client-stores"
        });
    }

}

export default ClientStoresService;
