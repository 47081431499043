import { isEmpty, reject } from "lodash";
import cn from 'classnames';
// components
import BannerCard, { BannerCardLoader } from 'components/BannerCard';
// interfaces
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
import IClientSliderItemList from 'cms/interfaces/IClientSliderItemList';
import { EResourceType } from "cms/enums/EResourceType";

type TBannerProps = {

    payload?: IClientMainPageItemList[]|IClientSliderItemList[]|null;

    className?: string;
    classNameInner?: string;

    variant?: 'rounded' | 'default';
    effectActive?: boolean;
    disableBorderRadius?: boolean;

}

function BannerBlockLoader () {

    return (
        <>
            <div className={`mb-12 md:mb-14 xl:mb-16 grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5`}>
                <BannerCardLoader showLoader={true} className="mx-auto col-span-1 sm:col-span-2" />
                <BannerCardLoader showLoader={true} className="mx-auto col-span-full sm:col-span-5" />
                <BannerCardLoader showLoader={true} className="mx-auto col-span-1 sm:col-span-2" />
            </div>
        </>
    )

}

function BannerBlock ( props:TBannerProps ) {

    return (
        <>
            <div className={`mb-12 md:mb-14 xl:mb-16 hidden sm:flex grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5 ${props.className}`}>
                {
                    props.payload?.map((item, index) => 
                        <BannerCard 
                            payload={item}
                            variant={props.variant}
                            resourceType={EResourceType.OUTER_IMAGE}
                            effectActive={props.effectActive}
                            className={cn(
                                props.className,
                                { "col-span-1 sm:col-span-2": index%2===0 },
                                { "col-span-full sm:col-span-5": index%2!==0 }
                            )}
                            classNameInner={props.classNameInner}
                            disableBorderRadius={props.disableBorderRadius}
                        />
                    )
                }
            </div>
            <div className="mb-12 md:mb-14 xl:mb-16 sm:hidden grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5">
                {   
                    reject([props.payload?.[0], props.payload?.[2], props.payload?.[1]], isEmpty)?.map((item, index) => 
                        <BannerCard 
                            payload={item}
                            variant={props.variant}
                            resourceType={EResourceType.OUTER_IMAGE}
                            effectActive={props.effectActive}
                            className={cn(
                                props.className,
                                { "mx-auto col-span-1 sm:col-span-2": index===0||index===1 },
                                { "mx-auto col-span-full sm:col-span-5": index===2 }
                            )}
                            classNameInner={props.classNameInner}
                            disableBorderRadius={props.disableBorderRadius}
                        />
                    )
                } 
            </div>
        </>
    );
    
}

BannerBlock.defaultProps = {

    variant: 'default',

}

export default BannerBlock
export {
    BannerBlockLoader
}
