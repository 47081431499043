import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
// styles
import './style.scss'
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import RMDRImage from "../RMDRImage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";


function mapStateToProps ( state: any ) {
    console.log("Preloader.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail|null    = state.SiteSettingStore.details?.payload;
    const isLoading:boolean                                         = !state.CoreStore.loader.isLoading;

    return {

        siteSettingDetailPayload,
        isLoading
    
    }
}

const connector = connect(mapStateToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TPreloader<T> = {
    
} & T;


/* component */
function Preloader ( props:TPreloader<PropsFromRedux> ) {
    console.log("Preloader.rendered: ", props);

    const [ preloaderCSS, setPreloaderCSS ] = useState("");
    const [ showPreloader, setShowPreloader ] = useState(false);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType:EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect (
        () => {
            if ( props.isLoading === false ) {
                setTimeout( () => { setPreloaderCSS("animate__fadeOut"); }, 400 );
                setTimeout( () => { setShowPreloader(false); }, 600 );
            }
            else {
                setPreloaderCSS("");
                setShowPreloader(true);
            }
        }, [props.isLoading]
    )
    
    if ( showPreloader ) {
        document.body.classList.add('overflow-hidden');

        return (
            <div className={`preloader animate__animated animate__faster ${preloaderCSS}`}>
                <div className="loader-circle"></div>
                <div className="loader-line-mask">
                    <div className="loader-line"></div>
                </div>
                <RMDRImage
                    resourceType={EResourceType.LOGO_ONLY}
                    sizeType={ESizeType.X01}
                    resources={props.siteSettingDetailPayload?.resources}
                    className="object-none"
                    alt={languageVersion?.name}
                    title={languageVersion?.name}
                />
            </div>
        )
    }

    document.body.classList.remove('overflow-hidden');
    return null;

}

const Component = React.memo(Preloader, ( prevProps:TPreloader<PropsFromRedux>, nextProps:TPreloader<PropsFromRedux> ) => {
    console.log("Preloader.memo", { prevProps, nextProps });

    return !( 
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.siteSettingDetailPayload !== nextProps.siteSettingDetailPayload
    )
});

export default connector(Component);
