import React, { useState } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// actions
import TValidations from "cms/types/TValidations";
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ContainerV2 from "components/ContainerV2";
import SaleBannerGrid, { SaleBannerGridLoader } from "components/SaleBannerGrid";
import BannerBlock, { BannerBlockLoader } from "components/BannerBlock";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import NoData from "components/NoData";


function mapStateToProps ( state: any ) {
    console.log("MNPG006.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG006.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG006Loader () {

    return (
        <div className="animate-pulse">
            <SaleBannerGridLoader />
            <BannerBlockLoader />
        </div>
    )

}

function MNPG006 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG006.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    return (
        <ContainerV2>
            {
                isEmpty(props.childsPayload) ? (
                    <NoData
                        className="flex flex-col items-center justify-center h-80 bg-gray-100 mb-12 md:mb-14 xl:mb-16"
                        textClassName="text-center text-xl"
                        iconClassName="stroke-1 text-8xl"
                        siteSettingPayload={props.siteSettingDetailPayload}
                    />
                ) : (
                    <>
                        <SaleBannerGrid payload={props.childsPayload?.slice(0,2)} effectActive />
                        <BannerBlock payload={props.childsPayload?.slice(2,5)} effectActive />
                    </>
                )
            }
        </ContainerV2>
    )

}

const Component = React.memo(MNPG006, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG006.memo", { prevProps, nextProps });

    return !(false);
});

export default connector(Component);
