import React from "react";
import Lodash, { isEmpty } from "lodash";
// types
import { TRMDRTab } from "../type";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import RenderText from "../../RenderText";
import { EViewType } from "cms/enums/EViewType";


const RMDRTab01 = ( props:TRMDRTab ) => {

    if ( props.languageVersion && !isEmpty(props.languageVersion) && props.languageVersion?.viewType === EViewType.TAB ) {
        let payloadChild = props.languageVersions?.filter((item) => item.parentId === props.languageVersion?.id);
        payloadChild = Lodash.orderBy(payloadChild, ['order'],['asc']);

        if ( payloadChild && !isEmpty(payloadChild) ) {
            return (
                <div className='mb-12 md:mb-14 xl:mb-16'>
                    <TabGroup as='div' className=''>
                        <TabList as='ul' className='tab-ul'>
                            {
                                payloadChild.map((item) => (
                                    <Tab
                                        as='li'
                                        className={({ selected }) =>
                                            selected ? 'tab-li-selected' : 'tab-li'
                                        }
                                    >
                                        {item.name}
                                    </Tab>
                                ))
                            }
                        </TabList>

                        <TabPanels>
                            {
                                payloadChild.map((item) => (
                                    <TabPanel>
                                        <RenderText html={item.value} />
                                    </TabPanel>
                                ))
                            }
                        </TabPanels>
                    </TabGroup>
                </div>
            );
        }
    }

    return null;
    
}

const Component = React.memo(RMDRTab01, (prevProps:TRMDRTab, nextProps:TRMDRTab) => {
    console.log("RMDRTab01.memo", { prevProps, nextProps });

    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.languageVersion !== nextProps.languageVersion
    ) 
});

export default Component;
