import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientSiteSettingService extends ReadOnlyOdataServiceBase {

    static code = "205";
    code = "205";

    constructor () {
        super({
            sourcePath: "client-site-settings"
        });
    }

}

export default ClientSiteSettingService;
