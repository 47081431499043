// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
import EActionType from "core/enums/EActionType";
import { IActionGetOdata } from "core/interfaces/IActionGet";
import IActionRunParams from "core/interfaces/IActionRunParams";
// service
import ClientProductsService from "ecommerce/services/ClientProductsService";


class ClientProductsServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientProductsService, screenCode);
    }

    async search ( params: IActionGetOdata ) {

        this.fetching(EActionType.LIST);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.LIST
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.search.bind(this.service), serviceParams );
        
    }

}

export default ClientProductsServiceAction;
