// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientStoresService from "ecommerce/services/ClientStoresService";


class ClientStoresServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientStoresService, screenCode);
    }

}

export default ClientStoresServiceAction;
