import { isEmpty } from "lodash";
import IClientProductDetails from 'ecommerce/interfaces/IClientProductDetails';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import { QRCode } from 'react-qrcode-logo';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import ContentRenderer from './ContentRenderer';
import Gallery02 from "components/RMDRGallery/components/Gallery02";
import { EResourceType } from "cms/enums/EResourceType";
import { useEffect, useState } from "react";
import IDetailVariants from "ecommerce/interfaces/IDetailVariants";
import RMDRLink from "./RMDRLink";
import ERedirectType from "cms/enums/ERedirectType";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";


type TProductSingleDetails = {

    payload:IClientProductDetails;

}

function ProductSingleDetails ( props:TProductSingleDetails ) {
    console.log("ProductSingleDetails: ", props);

    const [ searchParams ] = useSearchParams();
    const [ variant, setVariant ] = useState<IDetailVariants|null|undefined>(null);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const languageCatalogCategory = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.payload?.catalogCategory?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect(
        () => {
            console.log("ProductSingleDetails.useEffect.1: ");

            const _vid = searchParams.get("vid")?.trim();

            if ( !isEmpty(_vid) ) {
                const _variant = props.payload?.variants?.find((item) => item.id === _vid);
                if ( !isEmpty(_variant) ) {
                    setVariant(_variant);
                    return;
                }
            } 
            
            setVariant(props.payload.variants?.[0]);

        }, [ searchParams, props.payload ]
    )

    if ( !isEmpty(variant) ) {
        return (
            <div className="block lg:grid grid-cols-9 gap-x-10 xl:gap-x-14 pt-7 pb-10 lg:pb-14 2xl:pb-20 items-start">
    
                <div className="col-span-3 h-full">
                    <div className="col-span-1 transition duration-150 ease-in hover:opacity-90 h-full">
                        <Gallery02
                            resources={variant?.resources} 
                            resourceType={EResourceType.IMAGE} 
                            type={"filter"}

                            alt={languageVersion?.name}
                            title={languageVersion?.name}
                        />
                    </div>
                </div>
    
                <div className="col-span-6 pt-8 lg:pt-0">
                    <div className="block 2xl:flex flex-row justify-between pb-7 border-b border-gray-300 ">
                        <div className="flex-grow">
                            <h2 className="text-heading text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-bold hover:text-black">
                                {languageVersion?.name}
                            </h2>
                            <p className="text-body text-sm font-bold lg:text-base leading-6 lg:leading-8">
                                { languageCatalogCategory?.name }
                                {/* <RMDRLink
                                    text={languageCatalogCategory?.name}
                                    hasNavigationDestination={true}
                                    navigationURL={languageCatalogCategory?.contentRouteNavigationURL}
                                    redirectType={ERedirectType.REDIRECT_NEW_TAB}
                                /> */}
                            </p>
                            {/* <p className="text-body text-sm lg:text-base leading-6 lg:leading-8" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} /> */}
                            <div className="flex items-center mt-5 gap-3">
                                {
                                    variant?.discountedPercentage &&
                                        <div className="flex items-center font-bold text-base text-red-600 md:text-xl lg:text-2xl 2xl:text-3xl">
                                            <MdKeyboardDoubleArrowDown className="text-red-600" />
                                            {variant?.discountedPercentage}%
                                        </div>
                                }
                                {
                                    variant?.discountedPercentage ? (
                                        <div className="text-heading font-bold text-base h-6 md:text-xl md:h-7 lg:text-2xl lg:h-8 2xl:text-4xl 2xl:h-10">
                                            {variant?.currencySign||"$"}{variant?.discountedPrice}
                                        </div>
                                    ) : (
                                        <div className="text-heading font-bold text-base h-6 md:text-xl md:h-7 lg:text-2xl lg:h-8 2xl:text-4xl 2xl:h-10">
                                            {
                                                variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                            }
                                        </div>
                                    )
                                }
                                {
                                    variant?.discountedPercentage && 
                                        (
                                            <del className="ine-through font-segoe text-gray-400 text-sm md:text-base lg:text-xl xl:text-2xl">
                                                {
                                                    variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                                }
                                            </del>
                                        )
                                }
                            </div>
    
                            {
                                props.payload.variants?.length > 1 ? (
                                    <div className="mt-10">
                                        { 
                                            props.payload.variants?.map((item) => (
                                                <RMDRLink
                                                    key={item.id}
                                                    title={item.variantGroupName}
                                                    text={item.variantGroupName}
                                                    hasNavigationDestination={true}
                                                    navigationURL={`${languageVersion?.contentRouteNavigationURL}?vid=${item.id}`}
                                                    redirectType={ERedirectType.STAY_CURRENT_PAGE}
                                                    className={cn(
                                                        "px-3 py-2 text-sm md:text-base border border-black rounded-md bg-white mr-1 hover:bg-floorsAndExtras-600 hover:text-white hover:border-none",
                                                        { "!bg-floorsAndExtras-600 !text-white": item.id === variant.id },
                                                    )}
                                                />
                                            ))
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                        {/* <div className="mt-10 2xl:mt-0">
                            {
                                languageVersion && !isEmpty(languageVersion) && 
                                    (
                                        <QRCode value={`${window.location.origin}/${languageVersion.languageTwoLetterISOName}/qr/${props.payload.id}`} qrStyle='dots' ecLevel='M' quietZone={0} size={400} />
                                    )
                            }
                        </div> */}
                    </div>
                    
                    <ContentRenderer 
                        languageVersions={props.payload.languageVersions} 
                        showNameField={true} 
                        titleClassName="text-sm font-bold leading-relaxed text-heading pr-2 md:text-base lg:text-lg pt-5 pb-1 md:pt-6 md:pb-2 border-t border-gray-300"
                        contentClassName="leading-7 text-sm text-gray-600"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="block lg:grid grid-cols-9 gap-x-10 xl:gap-x-14 pt-7 pb-10 lg:pb-14 2xl:pb-20 items-start animate-pulse">
            <div className="col-span-3 h-full">
                <div className="flex items-center justify-center w-full h-96 bg-gray-300 rounded dark:bg-gray-700">
                    <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                    </svg>
                </div>
            </div>

            <div className="col-span-6 pt-8 lg:pt-0 h-full">
                <div className="block 2xl:flex flex-row justify-between pb-7 border-b border-gray-300 h-full">
                    <div className="flex-grow">
                        <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 w-2/3 mb-4"></div>
                        <div className="h-5 bg-gray-200 rounded-lg dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                        <div className="h-5 bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-5 bg-gray-200 rounded-lg dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                        <div className="h-5 bg-gray-200 rounded-lg dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-5 bg-gray-200 rounded-lg dark:bg-gray-700 max-w-[360px]"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductSingleDetails;
