import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

type ScrollbarProps = {

    options?: any;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    [key:string]: any;

};

function Scrollbar ( props:ScrollbarProps ) {

    return (
        <OverlayScrollbarsComponent
            options={{
                scrollbars: { autoHide: 'scroll' },
                ...(props.options ? props.options : {}),
            }}
            className={cn('os-theme-thin', props.className)}
            style={props.style}
            defer
            {...props}
        >
            {props.children}
        </OverlayScrollbarsComponent>
    );

};

export default Scrollbar;
