import { useState, Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiOutlineSelector } from 'react-icons/hi';
import IClientLanguage from 'core/interfaces/IClientLanguage';
import { useLocation, useNavigate } from 'react-router-dom';
import ListBox from './ListBox';


/* types */
type TLanguageSwitcher = {
    
    languagePayload:IClientLanguage[];
    webApplicationLanguageISOCode:string;
    showLoader: () => void;

};


/* component */
function LanguageSwitcherLoader () {

    return (
        <></>
    )

}

function LanguageSwitcher ( props:TLanguageSwitcher ) {
    
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedItem, setSelectedItem] = useState<IClientLanguage|undefined>(undefined);

    useEffect(
        () => {
            const isoCode = props.languagePayload.find((item) => item.twoLetterISOName === props.webApplicationLanguageISOCode);
            setSelectedItem(isoCode);
        }, [ props.webApplicationLanguageISOCode ]
    )

    function reloadPage( webApplicationLanguageISOCode:string ) {

        try {
            let pathnameArray = location.pathname?.split("/")?.filter((item:any) => item);
            let newPathname:string = ""; 
    
            if ( pathnameArray.length > 0 ) {
                pathnameArray[0] = webApplicationLanguageISOCode;
                newPathname = `/${pathnameArray.join("/")}`;
            }

            navigate(newPathname, { replace: true });
            window.location.reload(); 
        } catch( ex ) {
            console.error(ex);
        }

    }

    function handleItemClick ( values:IClientLanguage|undefined ) {

        if ( values ) {
            if (props.showLoader) props.showLoader();
            setSelectedItem(values);
            reloadPage( values.twoLetterISOName );
        }

    }

    return (

        <ListBox
            title=''
            value={selectedItem}
            options={props.languagePayload}
            selectorKey={"name"}
            onChange={handleItemClick}
            className='min-w-[130px] md:min-w-[180px]'
            listOptionClassName="min-w-[130px] md:min-w-[180px] z-[99]"
        />

    )

}

export default LanguageSwitcher;
