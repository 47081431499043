import { isEmpty, reject } from "lodash";
import Helmet from "react-helmet";
import CoreDefault from "core/Defaults";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
import ESizeType from "cms/enums/ESizeType";
// extensions
import AnalyticsExtension from "core/extensions/AnalyticsExtension";
// utilities
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
// interfaces
import IClientContentDetail from "cms/interfaces/IClientContentDetail";
import IClientPageList from "cms/interfaces/IClientPageList";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientPageDetail from "cms/interfaces/IClientPageDetail";
import IClientContentList from "cms/interfaces/IClientContentList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import IClientProductDetails from "ecommerce/interfaces/IClientProductDetails";


type TSEO = {

    contentPayload?: IClientPageList|IClientPageDetail|IClientContentDetail|IClientContentList|null;
    productPayload?: IClientProductList|IClientProductDetails;
    siteSettingPayload?: IClientSiteSettingDetail|null;

    // predefined
    type?:"header" | "home-page" | "content-page" | "showroom-page" | "product-page";

    // other
    customMetaPayload?:{ title:string, url:string };

    // 
    enableTitle?:boolean;
    enableIcon?:boolean;
    enableAuthor?:boolean;

    enableDescription?:boolean;    // priority: contentPayload, customMetaPayload, siteSettingPayload
    enableKeywords?:boolean;       // priority: contentPayload, customMetaPayload, siteSettingPayload

    enableAnalytics?:boolean;

    enableBase?:boolean;
    enableTwitter?:boolean;
    enableCanonical?:boolean;
    enableOpenGraph?:boolean;

    enableLDJSONSearch?:boolean;
    enableLDJSONOrganization?:boolean;

}

type TLanguagePayload = {

    webAppNameDescription:ILanguageVersionDetail|null;
    contentNameDescription:ILanguageVersionDetail|ILanguageVersionList|null;
    author:ILanguageVersionDetail|null;
    
    webAppmetaDescription:ILanguageVersionDetail|null;
    contentMetaDescription:ILanguageVersionDetail|null;
    
    webAppMetaKeywords:ILanguageVersionDetail|null;
    contentMetaKeywords:ILanguageVersionDetail|null;
    
    twitterUserName:ILanguageVersionDetail|null;
    sameAs:ILanguageVersionDetail|null;

    addressLocality:ILanguageVersionDetail|null;
    addressRegion:ILanguageVersionDetail|null;
    postalCode:ILanguageVersionDetail|null;
    address:ILanguageVersionDetail|null;
}

type TResourcePayload = {

    applicationIcon:IApplicationDocument|null;
    defaultApplicationDocument:IApplicationDocument|null;

    contentOuterApplicationDocument:IApplicationDocument|null;
    contentInnerApplicationDocument:IApplicationDocument|null;
    contentImage:IApplicationDocument|null;
    
    productOunterApplicationDocument:IApplicationDocument|null;
    productInnerApplicationDocument:IApplicationDocument|null;
    productImageApplicationDocument:IApplicationDocument|null;
    productImage:IApplicationDocument|null;

}

function enableTitle ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableTitle !== true ) return;

    if ( !isEmpty(_languagePayload.contentNameDescription) && _languagePayload.contentNameDescription ) {
        payload.push(<title>{_languagePayload.webAppNameDescription?.name} - {_languagePayload.contentNameDescription.name}</title>);
        if ( props.enableAnalytics ) AnalyticsExtension.sendPageView(`${CoreDefault.url}/${_languagePayload.contentNameDescription.contentRouteNavigationURL}`, `${_languagePayload.webAppNameDescription?.name} - ${_languagePayload.contentNameDescription.name}`);
    }
    else if ( !isEmpty(props.customMetaPayload) && props.customMetaPayload ) {
        payload.push(<title>{_languagePayload.webAppNameDescription?.name} - {props.customMetaPayload.title}</title>);
        if ( props.enableAnalytics ) AnalyticsExtension.sendPageView(`${CoreDefault.url}/${props.customMetaPayload.url}`, `${_languagePayload.webAppNameDescription?.name} - ${props.customMetaPayload.title}`);
    }
    else {
        payload.push(<title>{_languagePayload.webAppNameDescription?.name}</title>);
        if ( props.enableAnalytics ) AnalyticsExtension.sendPageView(`${CoreDefault.url}/${_languagePayload.webAppNameDescription?.languageTwoLetterISOName}`, `${_languagePayload.webAppNameDescription?.name}`);
    }
}

function enableIcon ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableIcon !== true ) return;

    if ( !isEmpty(_resourcePayload.applicationIcon) && _resourcePayload.applicationIcon ) {
        payload.push(<link rel="icon" href={`${CoreDefault.cdn}/${_resourcePayload.applicationIcon?.filePath}`} />);
        payload.push(<link rel="shortcut icon" href={`${CoreDefault.cdn}/${_resourcePayload.applicationIcon?.filePath}`} />);
    }
}

function enableAuthor ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableAuthor !== true ) return;

    if ( !isEmpty(_languagePayload.author) && _languagePayload.author ) payload.push(<meta name="author" content={_languagePayload.author?.name||""} />);
    else payload.push(<meta name="author" content={_languagePayload.webAppNameDescription?.name||""} />);

}

function enableDescription ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableDescription !== true ) return;

    if ( props.type === "home-page" && _languagePayload.webAppmetaDescription && !isEmpty(_languagePayload.webAppmetaDescription) ) {
        payload.push(<meta name="description" content={_languagePayload.webAppmetaDescription.value as string} />);
    }
    else if ( _languagePayload.contentMetaDescription && !isEmpty(_languagePayload.contentMetaDescription) ) {
        payload.push(<meta name="description" content={_languagePayload.contentMetaDescription.value as string} />)
    }
}

function enableKeywords ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableKeywords !== true ) return;

    if ( props.type === "home-page" && _languagePayload.webAppMetaKeywords && !isEmpty(_languagePayload.webAppMetaKeywords) ) {
        payload.push(<meta name="keywords" content={_languagePayload.webAppMetaKeywords.value as string} />);
    }
    else if ( _languagePayload.contentMetaKeywords && !isEmpty(_languagePayload.contentMetaKeywords) ) {
        payload.push(<meta name="keywords" content={_languagePayload.contentMetaKeywords.value as string} />);
    }
}

function enableBase ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableBase !== true ) return;

    payload.push(<base href={window.location.href} />);
}

function enableTwitter ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableTwitter !== true ) return;

    if ( !isEmpty(_languagePayload.contentNameDescription) && _languagePayload.contentNameDescription ) {
        payload.push(<meta property="twitter:card" content="summary" />);
        if ( !isEmpty(_languagePayload.twitterUserName) ) payload.push(<meta property="twitter:site" content={_languagePayload.twitterUserName.value as string} />);
        if ( !isEmpty(_languagePayload.contentNameDescription.value ) ) payload.push(<meta property="twitter:description" content={_languagePayload.contentNameDescription.value as string} />);
        payload.push(<meta property="twitter:title" content={`${_languagePayload.webAppNameDescription?.name} - ${_languagePayload.contentNameDescription?.name}`} />);
        if ( !isEmpty(_resourcePayload.contentImage) ) payload.push(<meta property="twitter:image" content={`${CoreDefault.cdn}/${_resourcePayload.contentImage.filePath}`} />);
        payload.push(<meta property="twitter:image:alt" content={_languagePayload.contentNameDescription.name as string} />);
    }
    else if ( !isEmpty(props.customMetaPayload) && props.customMetaPayload ) {
        payload.push(<meta property="twitter:card" content="summary" />);
        if ( !isEmpty(_languagePayload.twitterUserName) ) payload.push(<meta property="twitter:site" content={_languagePayload.twitterUserName.value as string} />);
        if ( !isEmpty((_languagePayload.contentNameDescription as any)?.value) ) payload.push(<meta property="twitter:description" content={(_languagePayload.contentNameDescription as any)?.value || ""} />);
        payload.push(<meta property="twitter:title" content={`${_languagePayload.webAppNameDescription?.name} - ${props.customMetaPayload?.title}`} />);
        if ( !isEmpty(_resourcePayload.contentImage) ) payload.push(<meta property="twitter:image" content={`${CoreDefault.cdn}/${_resourcePayload.contentImage.filePath}`} />);
        payload.push(<meta property="twitter:image:alt" content={props.customMetaPayload.title as string} />);
    }
    else {
        payload.push(<meta property="twitter:card" content="summary" />);
        if ( !isEmpty(_languagePayload.twitterUserName) ) payload.push(<meta property="twitter:site" content={_languagePayload.twitterUserName.value as string} />);
        if ( !isEmpty(_languagePayload.webAppNameDescription?.value ) ) payload.push(<meta property="twitter:description" content={_languagePayload.webAppNameDescription?.value as string} />);
        payload.push(<meta property="twitter:title" content={`${_languagePayload.webAppNameDescription?.name} - ${_languagePayload.webAppNameDescription?.name}`} />);
        if ( !isEmpty(_resourcePayload.contentImage) ) payload.push(<meta property="twitter:image" content={`${CoreDefault.cdn}/${_resourcePayload.contentImage.filePath}`} />);
        payload.push(<meta property="twitter:image:alt" content={_languagePayload.webAppNameDescription?.name as string} />);
    }
}

function enableCanonical ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableCanonical !== true ) return;

    payload.push(<link rel="canonical" href={window.location.href} />);
}

function enableOpenGraph ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableOpenGraph !== true ) return;

    if ( props.type === "home-page" ) {
        payload.push(<meta property="og:type" content="website" />);
        
        payload.push(<meta property="og:url" content={window.location.href} />);
        payload.push(<meta property="og:site_name" content={_languagePayload.webAppNameDescription?.name as string} />);
        payload.push(<meta property="og:title" content={_languagePayload.webAppNameDescription?.name as string} />);
        if ( !isEmpty(_languagePayload.webAppNameDescription?.value) ) {
            payload.push(<meta property="og:description" content={_languagePayload.webAppNameDescription?.value as string} />);
        }

        if ( !isEmpty(_resourcePayload.defaultApplicationDocument) ) {
            payload.push(<meta property="og:image" content={`${CoreDefault.cdn}/${_resourcePayload.defaultApplicationDocument.filePath}`} />);
            payload.push(<meta property="og:image:secure_url" content={`${CoreDefault.cdn}/${_resourcePayload.defaultApplicationDocument.filePath}`} />);
            payload.push(<meta property="og:image:type" content={_resourcePayload.defaultApplicationDocument.contentType} />);
            // payload.push(<meta property="og:image:width" content={_resourcePayload.contentImage.width.toString()} />);
            // payload.push(<meta property="og:image:height" content={_resourcePayload.contentImage.height.toString()} />);
            payload.push(<meta property="og:image:alt" content={_languagePayload.webAppNameDescription?.name as string} />);
        }
    } 
    else if ( props.type === "content-page" || props.type === "showroom-page" || props.type === "product-page" ) {        
        payload.push(<meta property="og:url" content={window.location.href} />);
        payload.push(<meta property="og:site_name" content={_languagePayload.webAppNameDescription?.name as string} />);
        payload.push(<meta property="og:title" content={`${_languagePayload.webAppNameDescription?.name} - ${props.customMetaPayload?.title||_languagePayload.contentNameDescription?.name}`}  />);
        if ( !isEmpty(_languagePayload.contentNameDescription?.value) ) {
            payload.push(<meta property="og:description" content={_languagePayload.contentNameDescription?.value as string} />);
        }
        payload.push(<meta property="og:type" content="article" />);

        // payload.push(<meta property="article:published_time" content="article" />);     // datetime
        // payload.push(<meta property="article:modified_time" content="article" />);      // datetime
        // payload.push(<meta property="article:expiration_time" content="article" />);    // datetime
        payload.push(<meta property="article:author" content={_languagePayload.webAppNameDescription?.name as string} />);             // profile array
        // payload.push(<meta property="article:section" content="article" />);            // string
        if ( !isEmpty(_languagePayload.contentMetaKeywords) ) {
            payload.push(<meta property="article:tag" content={_languagePayload.contentMetaKeywords?.name as string} />);                // string array
        }

        if ( !isEmpty(_resourcePayload.contentImage) ) {
            payload.push(<meta property="og:image" content={`${CoreDefault.cdn}/${_resourcePayload.contentImage.filePath}`} />);
            payload.push(<meta property="og:image:secure_url" content={`${CoreDefault.cdn}/${_resourcePayload.contentImage.filePath}`} />);
            payload.push(<meta property="og:image:type" content={_resourcePayload.contentImage.contentType} />);
            // payload.push(<meta property="og:image:width" content={_resourcePayload.contentImage.width.toString()} />);
            // payload.push(<meta property="og:image:height" content={_resourcePayload.contentImage.height.toString()} />);
            payload.push(<meta property="og:image:alt" content={_languagePayload.contentNameDescription?.name as string} />);
        }
        else if ( !isEmpty(_resourcePayload.productImage) ) {
            payload.push(<meta property="og:image" content={`${CoreDefault.cdn}/${_resourcePayload.productImage.filePath}`} />);
            payload.push(<meta property="og:image:secure_url" content={`${CoreDefault.cdn}/${_resourcePayload.productImage.filePath}`} />);
            payload.push(<meta property="og:image:type" content={_resourcePayload.productImage.contentType} />);
            // payload.push(<meta property="og:image:width" content={_resourcePayload.contentImage.width.toString()} />);
            // payload.push(<meta property="og:image:height" content={_resourcePayload.contentImage.height.toString()} />);
            payload.push(<meta property="og:image:alt" content={_languagePayload.contentNameDescription?.name as string} />);
        }
    }
}

function enableGoogleSiteVerification ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( isEmpty(process.env.GOOGLE_SITE_VERIFICATION) ) return;

    payload.push(<meta name="google-site-verification" content={process.env.GOOGLE_SITE_VERIFICATION} />);
}

function enableLDJSONSearch ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableLDJSONSearch !== true ) return;

    payload.push(
        <script type="application/ld+json">{`
            {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "url": "${window.location.origin}",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "${window.location.origin}/${_languagePayload.webAppNameDescription?.languageTwoLetterISOName}/shop?q={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }
            }
        `}</script>
    );

}

function enableLDJSONOrganization ( props:TSEO, _languagePayload:TLanguagePayload, _resourcePayload:TResourcePayload, payload:any[] ) {
    if ( props.enableLDJSONOrganization !== true ) return;

    const sameAs = _languagePayload.sameAs ? `[${_languagePayload.sameAs.value}]` : "[]"
    const address = _languagePayload.addressLocality && _languagePayload.addressRegion && _languagePayload.postalCode && _languagePayload.address ? 
        `{"@type":"PostalAddress","addressLocality":"${_languagePayload.addressLocality?.value}","addressRegion":"${_languagePayload.addressRegion?.value}", "postalCode":"${_languagePayload.postalCode?.value}", "streetAddress":"${_languagePayload.address?.value}"}` :
        "{}"

    payload.push(
        <script type="application/ld+json">{`
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${_languagePayload.webAppNameDescription?.name}",
                "url": "${window.location.origin}/${_languagePayload.webAppNameDescription?.languageTwoLetterISOName}",
                "logo": {
                    "@type": "ImageObject",
                    "url": "${CoreDefault.cdn}/${_resourcePayload.defaultApplicationDocument?.filePath}",
                    "width": ${_resourcePayload.defaultApplicationDocument?.width},
                    "height": ${_resourcePayload.defaultApplicationDocument?.height},
                },
                "sameAs" : ${sameAs},
                "address": ${address}
            }
        `}</script>
    );

}

function SEO ( props:TSEO ) {

    let payload:any[] = [];
    

    const webAppNameDescription = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    const contentNameDescription = LanguageVersionSelector<ILanguageVersionDetail|ILanguageVersionList|null>({
        languageVersions: props.contentPayload?.languageVersions||props.productPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });
    
    const author = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentPayload?.languageVersions||props.productPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "author" }
        ],
        type:"find"
    });

    const webAppmetaDescription = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "metaDescription" }
        ],
        type:"find"
    });

    const contentMetaDescription = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentPayload?.languageVersions||props.productPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "metaDescription" }
        ],
        type:"find"
    });

    const webAppMetaKeywords = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "metaKeywords" }
        ],
        type:"find"
    });

    const contentMetaKeywords = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentPayload?.languageVersions||props.productPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "metaKeywords" }
        ],
        type:"find"
    });

    const twitterUserName = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"twitterUsername" }
        ],
        type:"find"
    });

    const sameAs = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"sameAs" }
        ],
        type:"find"
    });

    const addressLocality = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"addressLocality" }
        ],
        type:"find"
    });

    const addressRegion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"addressRegion" }
        ],
        type:"find"
    });

    const postalCode = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"postalCode" }
        ],
        type:"find"
    });

    const address = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name:"address" }
        ],
        type:"find"
    });

    const _languagePayload = {
        webAppNameDescription,
        contentNameDescription,
        author,

        webAppmetaDescription,
        contentMetaDescription,
        
        webAppMetaKeywords,
        contentMetaKeywords,
        
        twitterUserName,
        sameAs,

        addressLocality,
        addressRegion,
        postalCode,
        address
    }

    const applicationIcon = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.siteSettingPayload?.resources, resourceType: EResourceType.LOGO_ONLY, sizeType: ESizeType.X02, type: "find" });
    const defaultApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.siteSettingPayload?.resources, resourceType: EResourceType.LOGO_DEFAULT, sizeType: ESizeType.X06, type: "find" });

    const contentOuterApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.contentPayload?.resources, resourceType: EResourceType.OUTER_IMAGE, type: "find" });
    const contentInnerApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.contentPayload?.resources, resourceType: EResourceType.INNER_IMAGE, type: "find" });
    const contentImage = reject([contentInnerApplicationDocument, contentOuterApplicationDocument], isEmpty)?.[0];

    const productOunterApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.productPayload?.variants?.[0]?.resources, resourceType: EResourceType.OUTER_IMAGE, type: "find" });
    const productInnerApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.productPayload?.variants?.[0]?.resources, resourceType: EResourceType.INNER_IMAGE, type: "find" });
    const productImageApplicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ resources: props.productPayload?.variants?.[0]?.resources, resourceType: EResourceType.IMAGE, type: "find" });
    const productImage = reject([productInnerApplicationDocument, productOunterApplicationDocument, productImageApplicationDocument], isEmpty)?.[0];

    const _resourcePayload = {
    
        applicationIcon,
        defaultApplicationDocument,

        contentOuterApplicationDocument,
        contentInnerApplicationDocument,
        contentImage,
        
        productOunterApplicationDocument,
        productInnerApplicationDocument,
        productImageApplicationDocument,
        productImage

    }

    let _props = { ...props };

    if ( props.type === "header" ) {

        _props.enableIcon = true;
        _props.enableAuthor = true;
        _props.enableLDJSONSearch = true;
        _props.enableLDJSONOrganization = true;

    }
    else if ( props.type === "home-page" || props.type === "content-page" || props.type === "showroom-page" || props.type === "product-page" ) {

        _props.enableTitle = true;
        _props.enableDescription = true;
        _props.enableKeywords = true;
        _props.enableBase = true;
        _props.enableTwitter = true;
        _props.enableCanonical = false;
        _props.enableOpenGraph = true;
    
        if ( props.type === "home-page" || props.type === "content-page" || props.type === "product-page" ) {
            _props.enableCanonical = true;
        }

    }
    
    enableTitle( _props, _languagePayload, _resourcePayload, payload );
    enableIcon( _props, _languagePayload, _resourcePayload, payload );
    enableAuthor( _props, _languagePayload, _resourcePayload, payload );
    enableDescription( _props, _languagePayload, _resourcePayload, payload );
    enableKeywords( _props, _languagePayload, _resourcePayload, payload );
    enableBase( _props, _languagePayload, _resourcePayload, payload );
    enableTwitter( _props, _languagePayload, _resourcePayload, payload );
    enableCanonical( _props, _languagePayload, _resourcePayload, payload );
    enableOpenGraph( _props, _languagePayload, _resourcePayload, payload );
    enableGoogleSiteVerification( _props, _languagePayload, _resourcePayload, payload );

    enableLDJSONSearch( _props, _languagePayload, _resourcePayload, payload );
    enableLDJSONOrganization( _props, _languagePayload, _resourcePayload, payload );

    if ( !isEmpty(payload) && payload ) {

        return (<Helmet>{payload}</Helmet>)

    }

    return null;

}

SEO.defaultProps = {

    enableTitle : false,
    enableIcon : false,
    enableAuthor : false,

    enableDescription : false,
    enableKeywords : false,

    enableAnalytics : false,

    enableBase : false,
    enableTwitter : false,
    enableCanonical : false,
    enableOpenGraph : false,

    enableLDJSONSearch : false,
    enableLDJSONOrganization : false,

}

export default SEO;
export type {
    TSEO
};
