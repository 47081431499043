import { combineReducers } from 'redux';
// reducers
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// interfaces
import ICoreReduxStore from 'core/interfaces/ICoreReduxStore';
// enums
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';

const reduxStorages = new CoreReduxStore( { screenCode: "CMS", serviceCode: "Core" } as ICoreReduxStore )

/**
 * - Uygulama ana verileri burada depolanır
 * - Herhangi bir sayfaya bağlı kalmaksızın verilerin depolanması için 
 *   screenCode CMS ve serviceCode Core(Core) olarak set edilmiştir.
 */

const CoreStore = combineReducers({

    mobileMenu: reduxStorages.BASE({ 
        storeKey: "floorsandextras",
        protocolKey: "MM",
        initialState: EReduxStoreInitialState.DEFAULT
    })["floorsandextras"],

    mobileSearch: reduxStorages.BASE({ 
        storeKey: "floorsandextras",
        protocolKey: "MS",
        initialState: EReduxStoreInitialState.DEFAULT
    })["floorsandextras"],

});

export default CoreStore;
