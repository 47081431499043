import React from 'react';
import CoreDefault from 'core/Defaults';
// utilities
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
// types
import ApplicationDocumentSelector, { TApplicationDocumentSelector } from 'cms/utilities/ApplicationDocumentSelector';
import { isEmpty, reject } from 'lodash';
import RenderText from './RenderText';
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';
import useProgressiveImage from 'core/hooks/useProgressiveImage';


type TContentHeaderRow = {

    title?: string|null;
    description?: string|null;
    imagePath?: string|null;
    
} & TApplicationDocumentSelector;

function ContentHeaderRow(props: TContentHeaderRow) {
    console.log("ContentHeaderRow: ", props);

    const applicationDocument = ApplicationDocumentSelector<IApplicationDocument>({
        resources: props.resources,
        resourceType: props.resourceType,
        sizeType: props.sizeType,
        type: "find"
    });

    const image = reject([
        props.imagePath ? `${CoreDefault.cdn}/${props.imagePath.replace("\\", "/")}` : null,
        applicationDocument.filePath ? `${CoreDefault.cdn}/${applicationDocument.filePath.replace("\\", "/")}` : null
    ], isEmpty)?.[0];

    const loaded = useProgressiveImage({ src:image ? `${image}` : null });

    return (
        <div
            className="flex justify-center p-6 md:p-10 2xl:p-8 relative bg-no-repeat bg-center bg-cover"
            style={loaded?{backgroundImage: `url('${loaded}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}:undefined}
        >
            <div className="absolute top-0 left-0 bg-black w-full h-full opacity-30 transition-opacity duration-500 group-hover:opacity-80" />
            <div className="w-full flex items-center justify-center text-center relative z-10 py-10 md:py-14 lg:py-20 xl:py-24 2xl:py-32">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-white">
                    <RenderText html={props.title} />
                    {
                        props.description &&
                            (
                                <span className="block text-base mb-3 text-white ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.description) }} />
                            )
                    }
                </h2>
            </div>
        </div>
    )

}

export default ContentHeaderRow;
export type {
    TContentHeaderRow
};

