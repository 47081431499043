// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientSiteSettingService from "cms/services/ClientSiteSettingService";


class ClientSiteSettingServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientSiteSettingService, screenCode);
    }

}

export default ClientSiteSettingServiceAction;
