import _StockStatusServiceAction from "ecommerce/actions/services/enum/StockStatusServiceAction";


const screenCode = "405";
class StockStatusServiceAction extends _StockStatusServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default StockStatusServiceAction;
