import { isEmpty } from "lodash";
import CoreDefault from "core/Defaults";
// interfaces
import IResource from "cms/interfaces/IResource";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// utilities
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
// enums
import { EResourceType } from "cms/enums/EResourceType";
// assets
import defaultImage from "assets/img/default-image.webp";


type TRMDRVariantImage = {

    resources: IResource[];

    alt?: string|null;
    title?: string|null;

}

function RMDRVariantImage ( props:TRMDRVariantImage ) {
    console.log("RMDRVariantImage: ", props);

    // let filePath:string|null = null;

    const resource = ApplicationDocumentSelector<IApplicationDocument>({
        resources: props.resources,
        resourceType: EResourceType.IMAGE,
        type: "find"
    });

    function replaceImage (error:any) {
        error.target.path = defaultImage;
    }

    if ( resource && !isEmpty(resource) ) {
        
        return <img src={`${CoreDefault.cdn}/${resource?.filePath}`} alt={resource.name||props.alt||""} title={props.title||undefined} onError={replaceImage} loading="lazy" />

    }

    return null;

}

export default RMDRVariantImage;
export type {
    TRMDRVariantImage
};
