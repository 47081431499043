import { useState } from 'react';
import CoreDefaults from "core/Defaults";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { isEmpty } from 'lodash';

// types
import { TGallery } from '../type';
// utilities
import ApplicationDocumentSelector from 'cms/utilities/ApplicationDocumentSelector';
// interfaces
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';
// 
import "../gallery05.scss";


function Gallery05 ( props:TGallery ) {

    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    const images = ApplicationDocumentSelector<IApplicationDocument[]>({...props, type: "filter"});

    if ( !isEmpty(images) ) {
        return (
            <>
                <Swiper
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Thumbs, Navigation]}
                    className="mySwiper2"
                >
                    {
                        images.map((item, index) => (
                            <SwiperSlide key={`img-${index}`}>
                                <img src={`${CoreDefaults.cdn}/${item.filePath.replace("\\", "/")}`} alt={item.name||props.alt||""} title={props.title||undefined} loading='lazy' />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    navigation={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Thumbs, Navigation]}
                    className="mySwiper"
                >
                    {
                        images.map((item, index) => (
                            <SwiperSlide key={`tmb-${index}`}>
                                <img src={`${CoreDefaults.cdn}/${item.filePath.replace("\\", "/")}`} alt={item.name||props.alt||""} title={props.title||undefined} loading='lazy' />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </>
        );    
    }
}

export default Gallery05;
