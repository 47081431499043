import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientCatalogCategoriesService extends ReadOnlyOdataServiceBase {

    static code = "401";
    code = "401";

    constructor () {
        super({
            sourcePath: "client-catalog-categories"
        });
    }

}

export default ClientCatalogCategoriesService;
