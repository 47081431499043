import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import SectionHeader from "components/SectionHeader";
import { ProductsBlockLoader } from "components/ProductsBlock";
import ContainerV2 from "components/ContainerV2";
import NoData from "components/NoData";
import Gallery06 from "components/RMDRGallery/components/Gallery06";
import { HiOutlineNewspaper } from "react-icons/hi2";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
// actions
import ClientProductsServiceAction from "ecommerce/actions/components/MainPageLayouts/MNPG015/ClientProductsServiceAction";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// types
import TValidations from "cms/types/TValidations";
import { TMainPageLayout } from "../type";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";


function mapStateToProps ( state: any, props:TMainPageLayout<{}> ) {
    console.log("MNPG015.mapStateToProps: ", state, props);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail         = state.SiteSettingStore.details?.payload;

    const tagId = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "tagId" }
        ],
        type: "find"
    });

    const productListPayload:IClientProductList[]|undefined         = state.MNPG015Store.ClientProductsStore.get?.[`_${tagId?.value}`];
    const productListError:TValidations[]|null                      = state.MNPG015Store.ClientProductsStore.get.error?.value;
    const productListIsLoading:boolean                              = state.MNPG015Store.ClientProductsStore.get.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        
        productListPayload,
        productListError,
        productListIsLoading,

        tagId: tagId?.value,
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG015.mapDispatchToProps");

    const clientProductsServiceAction = new ClientProductsServiceAction(dispatch);

    return {

        _list: ( tagId:string, webApplicationLanguageCode:string, destinationName:string ) => {

            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `tags/any(d: d/id eq ${tagId})`],
                top: 1000,
                orderBy: ["name asc", "price asc"],
            };

            clientProductsServiceAction.search({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
            
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG015Loader () {

    return (
        <div className="animate-pulse">
            <ProductsBlockLoader variant='gridModern' length={5} />
        </div>
    )

}

function MNPG015 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG015.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect(
        () => {

            if ( props.tagId && !isEmpty(props.tagId) ) {
                props._list( props.tagId, props.webApplicationLanguageCode, `_${props.tagId}` );
            }

        }, []
    )

    return (
        <ContainerV2>
            <div className='mb-12 md:mb-14 xl:mb-16'>
                <SectionHeader
                    sectionHeading={parentLanguageVersion?.name}
                    className='pb-0.5 mb-1 sm:mb-1.5 md:mb-2 lg:mb-3 2xl:mb-4 3xl:mb-5'
                />
                    {
                        props.productListIsLoading && isEmpty(props.productListError) && (
                            <MMNPG015Loader />
                        )
                    }
                    {
                        !props.productListIsLoading && isEmpty(props.productListPayload) && (
                            <NoData
                                icon={<HiOutlineNewspaper className="text-8xl stroke-1" />}
                                className="flex flex-col items-center justify-center h-80 bg-gray-100"
                                textClassName="text-center text-xl"
                                siteSettingPayload={props.siteSettingDetailPayload}
                            />
                        )
                    }
                    {
                        props.productListPayload && !isEmpty(props.productListPayload) && (
                            <Gallery06
                                payload={props.productListPayload}
                                variant='gridModern'
                            />
                        )
                    }
            </div>
        </ContainerV2>
    )

}

const Component = React.memo(MNPG015, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG015.memo", { prevProps, nextProps });

    return !(
        prevProps.productListPayload !== nextProps.productListPayload
    );
});

export default connector(Component);
