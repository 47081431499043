import _ClientCatalogCategoriesServiceAction from "ecommerce/actions/services/ClientCatalogCategoriesServiceAction";


const screenCode = "400";
class ClientCatalogCategoriesServiceAction extends _ClientCatalogCategoriesServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientCatalogCategoriesServiceAction;
