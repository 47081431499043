/* Common */
import ECommerceStore from "ecommerce/reducers/common/ECommerceStore";
import ClientCatalogCategoryStore from "ecommerce/reducers/common/ClientCatalogCategoryStore";


/* Components */
import CategoryFilterStore from "ecommerce/reducers/components/filters/CategoryFilterStore";
import BrandFilterStore from "ecommerce/reducers/components/filters/BrandFilterStore";
import TagFilterStore from "ecommerce/reducers/components/filters/TagFilterStore";
// InnerLayouts
import INNR006Store from "ecommerce/reducers/components/InnerLayouts/INNR006Store";
// MainPageLayouts
import MNPG003Store from "ecommerce/reducers/components/MainPageLayouts/MNPG003Store";
import MNPG007Store from "ecommerce/reducers/components/MainPageLayouts/MNPG007Store";
import MNPG015Store from "ecommerce/reducers/components/MainPageLayouts/MNPG015Store";
/* Screens */
import MarketPlaceStore from "ecommerce/reducers/screens/MarketPlaceStore";


const ECommerceReducers = {

    /* Common */
    ECommerceStore,
    ClientCatalogCategoryStore,

    /* Components */
    CategoryFilterStore,
    BrandFilterStore,
    TagFilterStore,
    // InnerLayouts
    INNR006Store,
    // MainPageLayouts
    MNPG003Store,
    MNPG007Store,
    MNPG015Store,
    
    /* Screens */
    MarketPlaceStore,

}

export default ECommerceReducers;
