import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isEmpty, isNaN } from "lodash";
import { FaMagnifyingGlass } from "react-icons/fa6";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EViewType } from "cms/enums/EViewType";
import { EView } from "cms/enums/EView";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";


type TPriceFilter = {

    siteSettingDetailPayload:IClientSiteSettingDetail;

}

function PriceFilter ( props:TPriceFilter ) {
    console.log("BrandFilter: ");

	const [ searchParams, setSearchParams ] = useSearchParams();

    const minRef = React.createRef<HTMLInputElement>();
    const maxRef = React.createRef<HTMLInputElement>();

    const min = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "min" }
        ],
        type: "find"
    });

    const max = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "max" }
        ],
        type: "find"
    });

    const price = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "price" }
        ],
        type: "find"
    });

    useEffect (
        () => {
            
            let pricePayload:any = searchParams.get("p");
            if ( !isEmpty(pricePayload) && pricePayload ) {
                pricePayload = pricePayload?.split("-");
            }

            if ( minRef?.current && !isNaN(parseInt(pricePayload?.[0])) ) minRef.current.value = pricePayload[0];
            if ( maxRef?.current && !isNaN(parseInt(pricePayload?.[1])) ) maxRef.current.value = pricePayload[1];

        }, []
    )

    function onSubmit(event:React.SyntheticEvent) { 
        event.preventDefault();

        const target = event.target as typeof event.target & {
            min: { value: string },
            max: { value: string },
        };

        let min = target.min.value
        let max = target.max.value

        if ( !isEmpty(min) || !isEmpty(max) ) {
            searchParams.set("p", `${min||"min"}-${max||"max"}`);
            searchParams.set("cp", "1" );
            setSearchParams(searchParams, { replace:true });
        }
    }

	return (
		<div className="block border-b border-gray-300 pb-7 mb-7">
			<h3 className="text-heading text-sm md:text-base font-semibold mb-7">
				{price?.value}
			</h3>
			<div className="mt-2">
                <form className="flex flex-row" onSubmit={onSubmit}>
                    <input ref={minRef} name="min" type="number" placeholder={min?.value||""} className={"p-1.5 px-3 w-full border rounded-md border-gray-300 h-10"} />
                    <span className="p-1 px-3"> - </span>
                    <input ref={maxRef} name="max" type="number" placeholder={max?.value||""} className={"p-1.5 px-3 w-full border rounded-md border-gray-300 h-10"} />
                    <button className="p-1.5 px-3 ml-5 border rounded-md border-gray-300 h-10 bg-floorsAndExtras-600 text-white" type="submit" >
                        <FaMagnifyingGlass />
                    </button>
                </form>
			</div>
		</div>
	);
}

export default PriceFilter;

