import React, { useState } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import BrandCard from "components/BrandCard";
import SectionHeader from "components/SectionHeader";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// actions
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import ContainerV2 from "components/ContainerV2";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import NoData from "components/NoData";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import Gallery07 from "components/RMDRGallery/components/Gallery07";


function mapStateToProps ( state: any ) {
    console.log("MNPG009.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG009.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG009Loader () {

    return (
        <></>
    )

}

function MNPG009 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG009.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });
    
    return (
        <ContainerV2>
            <div className="mb-12 md:mb-14 xl:mb-16">
                <SectionHeader sectionHeading={parentLanguageVersion?.name} />
                {
                    isEmpty(props.childsPayload) ? (
                        <NoData
                            className="flex flex-col items-center justify-center h-80 bg-gray-100"
                            textClassName="text-center text-xl"
                            iconClassName="stroke-1 text-8xl"
                            siteSettingPayload={props.siteSettingDetailPayload}
                        />
                    ) : (
                        <Gallery07 payload={props.childsPayload} />
                    )
                }
            </div>
        </ContainerV2>
    );

}

const Component = React.memo(MNPG009, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG009.memo", { prevProps, nextProps });

    return !(false);
});

export default connector(Component);
