import React, { useEffect } from "react";
import Lodash from "lodash";
import { connect, ConnectedProps } from "react-redux"
import { useOutletContext } from "react-router-dom";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientContentsServiceAction from "cms/actions/screens/Content/ClientContentsServiceAction";
import ContentMetricsServiceAction from "cms/actions/screens/Content/ContentMetricsServiceAction";
// components
import SEO from "components/SEO";
import Layouts from "components/Layouts";
// interfaces
import Error404 from "../components/Error404";
import { Dispatch } from "@reduxjs/toolkit";
import IClientLanguage from 'core/interfaces/IClientLanguage';
import TValidations from "cms/types/TValidations";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientContentDetail from "cms/interfaces/IClientContentDetail";
import { EContentMetricsType } from "core/enums/EContentMetricsType";

function mapStateToProps ( state: any ) {
    console.log("Content.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage|null|undefined = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string|null|undefined      = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string|null|undefined   = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null            = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                    = state.SiteSettingStore.details?.isLoading;

    const contentDetailPayload:IClientContentDetail|null        = state.ContentStore.ClientContentsStore.details?.payload;
    const contentDetailError:TValidations[]|null                = state.ContentStore.ClientContentsStore.details?.error;
    const contentDetailIsLoading:boolean                        = state.ContentStore.ClientContentsStore.details?.isLoading;

    const errors:TValidations[]|null|undefined  = Lodash.reject( [siteSettingDetailError, contentDetailError], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                     = [siteSettingDetailIsLoading, contentDetailIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        contentDetailPayload,

        errors,
        isLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Content.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const clientContentsServiceAction = new ClientContentsServiceAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {
        // list
        // post
        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },
        // details
        _detailsContent: ( contentId:string, webApplicationLanguageCode:string ) => {
            clientContentsServiceAction.details({ servicePayload: { key: contentId, language: webApplicationLanguageCode }});
        },

        // others
        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        },

        _clean: () => {
            clientContentsServiceAction.detailsClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TContent<T> = {

} & T;


/* component */
function ContentLoader () {

    return (
        <></>
    )

}

function Content ( props:TContent<PropsFromRedux> ) {
    console.log("Content.rendered: ", props);

    const { contentName, contentId, location } = useOutletContext<any>();

    useEffect(
        () => {
            props._showLoader();

            if ( !Lodash.isEmpty(props.webApplicationLanguageCode) && props.webApplicationLanguageCode ) {    
                props._clean();
                props._detailsContent(contentId, props.webApplicationLanguageCode);
                props._viewEvent(contentId, props.webApplicationLanguageCode);
            }
        }, [ contentId, props.webApplicationLanguageCode ]
    )

    if ( !Lodash.isEmpty(props.contentDetailPayload) && !props.isLoading ) {

        const layoutName = props.contentDetailPayload?.layouts?.[0]?.layoutName;
        let Layout:any = Layouts?.[layoutName];

        if ( Layout ) {

            return ( 
                <Layout contentDetailPayload={props.contentDetailPayload} />
            );

        } else {

            return <Error404 />

        }

    }

    return null;

}

const Component = React.memo(Content, (prevProps:TContent<PropsFromRedux>, nextProps:TContent<PropsFromRedux>) => {
    console.log("Content.memo", { prevProps, nextProps });

    return !(
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.webApplicationLanguageCode !== nextProps.webApplicationLanguageCode
    );
});

export default connector(Component);
