import { combineReducers } from 'redux';

// reducers
import ClientProductsServiceStore from "ecommerce/reducers/services/ClientProductsServiceStore";
import StockStatusServiceStore from "ecommerce/reducers/services/enum/StockStatusServiceStore";


const screenCode = "404";
const MNPG003Store = combineReducers({
 
    ClientProductsStore: ClientProductsServiceStore({ screenCode }),
    StockStatusStore: StockStatusServiceStore({ screenCode }),

});

export default MNPG003Store;
