import { useState, useEffect } from 'react';
// enums
import ESeverityLevel from 'cms/enums/ESeverityLevel';
// types
import TValidations from 'cms/types/TValidations';


type TRMDRTextBox = {

    name: string;
    title: string|null;
    rmdrRef?: any;
    
    value: string|null;
    defaultValue: string|null;
    placeholder: string|null;

    errors: TValidations[]|null;
    isDisabled: boolean;
    isAutoFocus: boolean;

    mode: string|null;

    onValueChanged: (name:string, value?:string|null) => void;

    className: string|null;
    disabled: boolean;

}

function RMDRTextBox ( props:TRMDRTextBox ) {
    
    const [validation, setValidation] = useState<any>(null);

    useEffect(
        () => {
            let __value = props.errors?.find((item:any) => item.propertyName === props.name);

            if ( __value ) { 
                let className = "";

                if (__value.severity === ESeverityLevel.ERROR) className = "bg-red-100 rounded-t-none text-red-700 p-2 rounded text-sm"
                else if (__value.severity === ESeverityLevel.WARNING) className = "bg-orange-100 rounded-t-none text-orange-700 p-2 rounded text-sm"
                else if (__value.severity === ESeverityLevel.INFO) className = "bg-blue-100 rounded-t-none text-blue-700 p-2 rounded text-sm"
                
                setValidation({ ...__value, className }); 
            }

            if ( !__value && validation ) setValidation(null);
        }, [ props.errors ]
    )

    function valueChanged ( event:any ) {
        if ( props.onValueChanged && event.event !== undefined ) props.onValueChanged(props.name, event.value??undefined);
        if ( validation !== null ) setValidation(null);
    }

    return (
        <>
            <input
                ref={props.rmdrRef}
                
                name={props.name}
                type="text"
                placeholder={props.placeholder||""}
                disabled={props.disabled}
                onChange={valueChanged}

                className={`${props.className} ${validation ? "rounded-b-none":""}`}
            />
            {
                validation && <p className={validation.className}>{ validation.errorMessage }</p>
            }
        </>
    );
}

RMDRTextBox.defaultProps = {

    name: "RMDRTextBox",
    title: null,
    rmdrRef: undefined,
    
    value: "",
    defaultValue: "",
    placeholder: "",

    validations: [],
    isDisabled: false,
    isAutoFocus: false,

    mode: "text",
    lookupDataSource: null,

    onValueChanged: null,

    className: "",
    disabled: false,

}

export default RMDRTextBox;
export type {
    TRMDRTextBox
};
