import FetchExtension from 'core/extensions/FetchExtension';
// service
import ServiceBase from 'core/services/ServiceBase';
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IApiGetRequest } from 'core/interfaces/IGetRequest';
// enum
import HttpMethodEnum from "core/enums/EHttpMethod";


class EnumService extends ServiceBase {
	
    static code = "003";
    code = "003";

	constructor () {
		super({
			sourcePath: "enums"
		});
	}

    get( params: IApiGetRequest ): Promise<any> {
        
		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.GET,
            language: params.language,

            sourcePath: `${this.sourcePath}/${params.key}`,
		}

		return FetchExtension(apiFetchConfig);

	}

}

export default EnumService;
