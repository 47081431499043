import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// actions
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import SectionHeader from "components/SectionHeader";
import IconCard, { IconCardLoader } from 'components/IconCard';
import ContainerV2 from "components/ContainerV2";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IClientCatalogCategoryList from "ecommerce/interfaces/IClientCatalogCategoryList";
import NoData from "components/NoData";
import { isEmpty } from "lodash";
import ContentMetricsServiceAction from "cms/actions/components/MainPageLayouts/ContentMetricsServiceAction";
import { EContentMetricsType } from "core/enums/EContentMetricsType";


function mapStateToProps ( state: any ) {
    console.log("MNPG002.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    const catalogCategoryListPayload:IClientCatalogCategoryList[] = state.ClientCatalogCategoryStore.list.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        
        catalogCategoryListPayload,
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG002.mapDispatchToProps");

    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {

        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG002Loader () {

    return (
        <div className="grid gap-3 bg-white grid-cols-3 sm:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 animate-pulse">
            {
                Array.from({length:6}).map((item) => (
                    <div className="flex-shrink-0 w-full relative transition-transform block">
                        <IconCardLoader variant="default" />
                    </div>
                ))
            }
        </div>
    )

}

function MNPG002 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG002.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT },
        ],
        type: "find"
    });

    return (
        <ContainerV2>
            <div className="mb-12 md:mb-14 xl:mb-16">
                <SectionHeader sectionHeading={parentLanguageVersion?.name} />

                {/* {
                    props.isLoading && isEmpty(props.error) && (
                        <MMNPG002Loader />
                    )
                } */}

                {
                    isEmpty(props.childsPayload) && (
                        <NoData
                            className="flex flex-col items-center justify-center h-80 bg-gray-100"
                            textClassName="text-center text-xl"
                            iconClassName="stroke-1 text-8xl"
                            siteSettingPayload={props.siteSettingDetailPayload}
                        />
                    )
                }
                
                <div className="grid gap-3 bg-white grid-cols-3 sm:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6">
                    {
                        props.childsPayload?.map((item, index) => {

                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: "find"
                            });

                            const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
                                ],
                                type: "find"
                            });

                            const hierarchyId = props.catalogCategoryListPayload.find((x) => x.id === item.contentId)?.hierarchyId;
                            const navigationURL = `/${props.webApplicationLanguageISOCode}/shop?c=${hierarchyId}`;

                            return (
                                <div key={item.id} className="flex-shrink-0 w-full h-full relative transition-transform block">
                                    <IconCard
                                        languageVersion={languageVersion}
                                        redirectUrl={redirectUrl?.value}
                                        navigationURL={navigationURL}

                                        hasDestinationNavigation={item.hasDestinationNavigation}
                                        redirectType={item.redirectType}
                                        resources={item.resources}

                                        effectActive={true}
                                        variant="default"

                                        onClick={(event:any) => { props._viewEvent(item.id, props.webApplicationLanguageCode) }}
                                    />
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        </ContainerV2>
    )

}

const Component = React.memo(MNPG002, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG002.memo", { prevProps, nextProps });

    return !(true);
});

export default connector(Component);
