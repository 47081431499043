const ProductWishIcon = ({ className = '' }) => {
  return (
    <svg
      viewBox='0 0 52 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M35.3619 13.5618C34.7656 12.6163 33.8924 11.8776 32.8611 11.4465C31.6926 10.956 30.3801 10.8677 29.0657 11.1918C27.8612 11.4888 26.6879 12.1331 25.641 13.0676C24.594 12.133 23.4206 11.4887 22.2159 11.1917C20.9014 10.8675 19.5889 10.9559 18.4204 11.4467C17.3891 11.878 16.5159 12.6168 15.9197 13.5624C15.2954 14.5494 14.9778 15.7496 15.0012 17.0332C15.1053 22.7397 23.6057 27.9287 25.3087 28.911C25.4097 28.9693 25.5243 29 25.641 29C25.7577 29 25.8723 28.9693 25.9733 28.911C27.6764 27.9286 36.1776 22.7386 36.2808 17.0322C36.304 15.7487 35.9863 14.5486 35.3619 13.5618V13.5618ZM34.9509 17.0083C34.9177 18.8534 33.6877 21.0045 31.3944 23.2289C29.2705 25.2889 26.8547 26.8416 25.641 27.564C24.4272 26.8417 22.0118 25.2892 19.8882 23.2292C17.5947 21.0049 16.3648 18.8539 16.3311 17.0089C16.2943 14.9943 17.268 13.3734 18.9355 12.673C19.482 12.4447 20.0687 12.3283 20.6609 12.3306C22.1904 12.3306 23.8082 13.071 25.1667 14.4524C25.2286 14.5153 25.3024 14.5653 25.3839 14.5994C25.4653 14.6335 25.5526 14.6511 25.6409 14.6511C25.7292 14.6511 25.8166 14.6335 25.898 14.5994C25.9794 14.5653 26.0532 14.5153 26.1151 14.4524C28.0025 12.5333 30.3902 11.8514 32.3459 12.6729C34.0136 13.3731 34.9873 14.9937 34.9509 17.0082V17.0083Z'
        fill='#212121'
      />
    </svg>
  )
}

export default ProductWishIcon
