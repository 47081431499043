import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from "react-redux"
import Scrollbar from 'components/Scrollbar';
import Logo from 'components/Logo';
import { IoClose } from 'react-icons/io5';
import CMSAction from 'cms/actions/common/CMSAction';
import ContentMetricsServiceAction from "cms/actions/components/Header/ContentMetricsServiceAction";
import { Dispatch } from '@reduxjs/toolkit';
import IClientLanguage from 'core/interfaces/IClientLanguage';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import SocialMedia01 from 'components/SocialMedia/components/SocialMedia01';
import TMenu from 'cms/types/TMenu';
import IClientMenuItemList from 'cms/interfaces/IClientMenuItemList';
import TValidations from 'cms/types/TValidations';
import MenuComposer from 'cms/utilities/MenuComposer';
import { isEmpty } from 'lodash';
import { IoIosArrowDown } from 'react-icons/io';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import { EContentType } from 'cms/enums/EContentType';
import { EViewType } from 'cms/enums/EViewType';
import { EView } from 'cms/enums/EView';
import RMDRNavLink from 'components/RMDRNavLink';
import CoreDefault from "core/Defaults";
import { EResourceType } from 'cms/enums/EResourceType';
import ApplicationDocumentSelector from 'cms/utilities/ApplicationDocumentSelector';
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';
import { EContentMetricsType } from 'core/enums/EContentMetricsType';


function mapStateToProps ( state: any ) {
    console.log("MobileMenu.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail         = state.SiteSettingStore.details?.payload;

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const clientMenuItemGet:IClientMenuItemList[]|null              = state.HeaderStore.ClientMenuItemStore.get?.["MNGRP001"];
    const clientMenuItemGetError:TValidations[]|null                = state.HeaderStore.ClientMenuItemStore.get?.error;
    const clientMenuItemGetIsLoading:boolean                        = state.HeaderStore.ClientMenuItemStore.get?.isLoading;

    return {

        siteSettingDetailPayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        clientMenuItemGet,
        clientMenuItemGetError,
        clientMenuItemGetIsLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MobileMenu.mapDispatchToProps");

    const cmsAction = new CMSAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {
        
        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

        _openMobileMenu: () => {
            cmsAction.openMobileMenu();
        },

        _closeMobileMenu: () => {
            cmsAction.closeMobileMenu();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TMobileMenu<T> = {
    
} & T;


/* component */
function MobileMenuLoader () {

    return (
        <></>
    )

}

function MobileMenu ( props:TMobileMenu<PropsFromRedux> ) {
    console.log("MobileMenu.rendered: ", props);

    const [activeMenus, setActiveMenus] = useState<any>([]);
    const [ navigation, setNavigation ] = useState<TMenu[]|null>(null);

    useEffect(
        () => {

            if ( !isEmpty(props.clientMenuItemGet) ) {
                let parentMenuItems = props.clientMenuItemGet?.filter((item:any) => item.parentId === null);
                let navigation = MenuComposer(props.clientMenuItemGet, parentMenuItems);
                setNavigation(navigation);
            }

        }, [ props.clientMenuItemGet ]
    )

    const handleArrowClick = (menuName: string) => {
        let newActiveMenus = [...activeMenus];

        if (newActiveMenus.includes(menuName)) {
            var index = newActiveMenus.indexOf(menuName);
            if (index > -1) {
                newActiveMenus.splice(index, 1);
            }
        } else {
            newActiveMenus.push(menuName);
        }

        setActiveMenus(newActiveMenus);
    };

    function ListMenu ({
        dept,
        data,
        hasSubMenu,
        menuName,
        menuIndex,
        className = '',
    }: any) {
        const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: data.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });
        const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: data.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
            ],
            type: "find"
        });

        let layout = data.layouts?.[0];

        if ( layout && layout.layoutName === "MN_001" ) {
            const applicationDocument = ApplicationDocumentSelector<IApplicationDocument>({
                resources: data.resources,
                resourceType: EResourceType.DATA,
                type: "find"
            });

            return (
                <li className={`mb-0.5 ${className}`}>
                    <div className="relative flex items-center justify-between">
                        <RMDRNavLink
                            title={languageVersion?.name}
                            text={languageVersion?.name}
                            hasNavigationDestination={data.hasDestinationNavigation}
                            isShowAlways={true}
    
                            navigationURL={`${CoreDefault.cdn}/${applicationDocument?.filePath}`}
    
                            redirectType={data.redirectType}
                            redirectUrl={redirectUrl?.value}
                            className="w-full text-[15px] menu-item relative py-3 pl-5 md:pl-6 pr-4 transition duration-300 ease-in-out"
                            onClick={(event:any) => { props._viewEvent(data.id, props.webApplicationLanguageCode) }}
                            variant="div"
                        />
                    </div>
                </li>
            )
        } else if ( layout && layout.layoutName === "MN_002" ) {
            const tagId = LanguageVersionSelector<ILanguageVersionList|null>({
                languageVersions: data.languageVersions,
                filters: [
                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "tagId" }
                ],
                type: "find"
            });

            const navigationURL = `/${props.webApplicationLanguageISOCode}/shop?t=${tagId?.value}`;

            return (
                <li className={`mb-0.5 ${className}`}>
                    <div className="relative flex items-center justify-between">
                        <RMDRNavLink
                            title={languageVersion?.name}
                            text={languageVersion?.name}
                            hasNavigationDestination={data.hasDestinationNavigation}
                            isShowAlways={true}
    
                            navigationURL={navigationURL}
    
                            redirectType={data.redirectType}
                            redirectUrl={redirectUrl?.value}
                            className="w-full text-[15px] menu-item relative py-3 pl-5 md:pl-6 pr-4 transition duration-300 ease-in-out"
                            onClick={(event:any) => { props._viewEvent(data.id, props.webApplicationLanguageCode) }}
                            variant="div"
                        />
                    </div>
                </li>
            )
        } else {
            return (
                <li className={`mb-0.5 ${className}`}>
                    <div className="relative flex items-center justify-between">
                        <RMDRNavLink
                            title={languageVersion?.name}
                            text={languageVersion?.name}
                            hasNavigationDestination={data.hasDestinationNavigation}
                            isShowAlways={true}
    
                            navigationURL={languageVersion?.contentRouteNavigationURL}
    
                            redirectType={data.redirectType}
                            redirectUrl={redirectUrl?.value}
                            className="w-full text-[15px] menu-item relative py-3 pl-5 md:pl-6 pr-4 transition duration-300 ease-in-out"

                            onClick={() => { props._closeMobileMenu(); }}
                            variant="div"
                        />
                        {
                            hasSubMenu && (
                                <div
                                    className="absolute top-0 flex items-center justify-end w-full h-full text-lg cursor-pointer left-0 pr-5"
                                    onClick={() => { handleArrowClick(menuName); }}
                                >
                                    <IoIosArrowDown
                                        className={`transition duration-200 ease-in-out transform text-heading ${activeMenus.includes(menuName) ? '-rotate-180' : 'rotate-0'}`}
                                    />
                                </div>
                            )
                        }
                    </div>
                    {
                        hasSubMenu && (
                            <SubMenu
                                dept={dept}
                                data={data.subMenu}
                                toggle={activeMenus.includes(menuName)}
                                menuIndex={menuIndex}
                            />
                        )
                    }
                </li>
            )
        }

        
    }

    function SubMenu ( { dept, data, toggle, menuIndex }:any ) {
        if (!toggle) return null;

        dept = dept + 1;

        return (
            <ul className="pt-0.5">
                {
                    data?.map((menu: any, index: number) => {
                        const menuName: string = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

                        return (
                            <ListMenu
                                dept={dept}
                                data={menu}
                                hasSubMenu={menu.subMenu}
                                menuName={menuName}
                                key={menuName}
                                menuIndex={index}
                                className={dept > 1 && 'pl-4'}
                                onClick={(event:any, contentId?:string) => { if (contentId) props._viewEvent(contentId, props.webApplicationLanguageCode) } }
                            />
                        );
                    })
                }
            </ul>
        );
    };

    return (
        <div className="flex flex-col justify-between w-full h-full">
            <div className="w-full border-b border-gray-100 flex justify-between items-center relative pl-5 md:pl-7 flex-shrink-0 py-0.5">
                <Logo siteSettingPayload={props.siteSettingDetailPayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} />

                <button
                    className="flex items-center justify-center px-4 py-6 text-2xl text-gray-500 transition-opacity md:px-6 lg:py-8 focus:outline-none hover:opacity-60"
                    onClick={() => { props._closeMobileMenu() }}
                    aria-label="close"
                >
                    <IoClose className="text-black mt-1 md:mt-0.5" />
                </button>
            </div>

            <Scrollbar className="flex-grow mb-auto menu-scrollbar">
                <div className="flex flex-col px-0 py-7 lg:px-2 text-heading">
                    <ul className="mobileMenu">
                        {
                            navigation?.map((menuItem, index:number) => {
                                const dept: number = 1;
                                const menuName: string = `sidebar-menu-${dept}-${index}`;

                                return (
                                    <ListMenu
                                        dept={dept}
                                        data={menuItem}
                                        hasSubMenu={menuItem.items}
                                        menuName={menuName}
                                        key={menuName}
                                        menuIndex={index}
                                        onClick={(event:any, contentId?:string) => { if (contentId) props._viewEvent(contentId, props.webApplicationLanguageCode) } }
                                    />
                                );
                            })
                        }
                    </ul>
                </div>
            </Scrollbar>
            
            <SocialMedia01 
                payload={props.siteSettingDetailPayload.languageVersions} 
                className='flex items-center justify-center flex-shrink-0 bg-white border-t border-gray-100 gap-x-1 px-7' 
                itemClassName='text-xl text-heading p-5 opacity-60 !m-0'
            />
        </div>
    );
}




const Component = React.memo(MobileMenu, ( prevProps:TMobileMenu<PropsFromRedux>, nextProps:TMobileMenu<PropsFromRedux> ) => {
    return !(
        prevProps.clientMenuItemGet !== nextProps.clientMenuItemGet
    );
});

export default connector(Component);
