import ReadOnlyOdataServiceBase from "core/services/OdataReadOnlyServiceBase";


class ClientBrandsService extends ReadOnlyOdataServiceBase {

    static code = "404";
    code = "404";

    constructor () {
        super({
            sourcePath: "client-brands"
        });
    }

}

export default ClientBrandsService;
