import React from 'react';

import IClientProductDetails from 'ecommerce/interfaces/IClientProductDetails';
import { isEmpty } from 'lodash';
import IClientCatalogCategoryList from 'ecommerce/interfaces/IClientCatalogCategoryList';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import RMDRLink from './RMDRLink';
import ERedirectType from 'cms/enums/ERedirectType';



interface TBreadcrumb {

    payload: IClientProductDetails;
    catalogCategories: IClientCatalogCategoryList[];
    webApplicationLanguageISOCode: string;

}

function Breadcrumb ( props:TBreadcrumb ) {
    console.log("Breadcrumb: ", props);

    if ( isEmpty(props.payload) || isEmpty(props.catalogCategories) ) return;

    let hierarchyNames:any[] = [];
    let hierarchy:string|undefined = props.payload.catalogCategory.hierarchy;
    if ( hierarchy && !isEmpty(hierarchy) ) {
        let hierarchySplit:number[]|undefined = hierarchy?.split(".")?.map((item) => parseInt(item));
        let catalogCategoryList = props.catalogCategories.filter((item) => hierarchySplit?.includes(item.hierarchyId));
        for ( let item of hierarchySplit ) {
            let hierarchyLanguageVersion = catalogCategoryList?.find((x) => x.hierarchyId === item)?.languageVersions?.find((y) => y.view === EView.OUTTER && y.viewType === EViewType.NAME_DESCRIPTION);
            hierarchyNames.push({name: hierarchyLanguageVersion?.name, hierarchyId: item });
        }
    }

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload.languageVersions,
        filters: [
            {
                view: EView.OUTTER,
                viewType: EViewType.NAME_DESCRIPTION
            }
        ],
        type: "find"
    });

    if ( hierarchyNames && !isEmpty(hierarchyNames) ) {
        return (
            <div className="flex items-center chawkbazarBreadcrumb">
                <ol className="flex items-center w-full overflow-hidden flex-wrap">
                    {
                        hierarchyNames.map((item:any, index:number) => (
                            <>
                                { index > 0 && <li className="text-base text-body mt-0.5">/</li> }
                                <li className="text-sm text-body px-2.5 transition duration-200 ease-in ltr:first:pl-0 rtl:first:pr-0 ltr:last:pr-0 rtl:last:pl-0 hover:text-heading">
                                    <span>
                                        <RMDRLink
                                            title={item.name}
                                            text={item.name}
                                            hasNavigationDestination={true}
                                            redirectType={ERedirectType.STAY_CURRENT_PAGE}
                                            redirectUrl={`/${props.webApplicationLanguageISOCode}/shop${index>0?`?c=${item.hierarchyId}`:""}`}
                                        />
                                    </span>
                                </li>
                            </>
                        ))
                    }
                    <li className="text-base text-body mt-0.5">/</li>
                    <li className="text-sm text-body px-2.5 transition duration-200 ease-in ltr:first:pl-0 rtl:first:pr-0 ltr:last:pr-0 rtl:last:pl-0 hover:text-heading">
                        <span>{languageVersion?.name}</span>
                    </li>
                </ol>
            </div>
        );
    }

    return null;    

};

export default Breadcrumb;
