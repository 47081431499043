import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
import Lodash from 'lodash';
import { Outlet } from "react-router-dom";
// components
import Footer from 'components/Footer';
import Header from "components/Header";
//
import SiteSettingServiceAction from "cms/actions/common/SiteSettingServiceAction";
import ClientCatalogCategoriesServiceAction from "ecommerce/actions/common/ClientCatalogCategoriesServiceAction";
import PutManifestFileDynamic from "cms/utilities/PutManifestFileDynamic";
import MobileNavigation from 'components/MobileNavigation';
import Search from "components/Search";
import CookieBar from "components/CookieBar";
import Button from "components/Button";
import ProductPopup from "components/ProductPopup";
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingList from "cms/interfaces/IClientSiteSettingList";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import IClientLanguage from "core/interfaces/IClientLanguage";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationWrapperLayout.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingListPayload:IClientSiteSettingList[]|null      = state.SiteSettingStore.list?.payload;
    const siteSettingListError:TValidations[]|null                  = state.SiteSettingStore.list?.error;
    const siteSettingListIsLoading:boolean                          = state.SiteSettingStore.list?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail|null    = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null                = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                        = state.SiteSettingStore.details?.isLoading;

    const error:TValidations[]|null|undefined       = Lodash.reject( [siteSettingListError, siteSettingDetailError], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                         = [siteSettingListIsLoading, siteSettingDetailIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingListPayload,
        siteSettingDetailPayload,

        error,
        isLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("WebApplicationWrapperLayout.mapDispatchToProps");

    const siteSettingServiceAction = new SiteSettingServiceAction(dispatch);
    const clientCatalogCategoriesServiceAction = new ClientCatalogCategoriesServiceAction(dispatch);

    return {

        // list
        _listSiteSetting: ( webApplicationLanguageCode:string ) => {
            siteSettingServiceAction.list({ servicePayload: { language: webApplicationLanguageCode } });
        },
        
        _listCatalogCategory: ( webApplicationLanguageCode:string ) => {            
            clientCatalogCategoriesServiceAction.list({ servicePayload: { queryOptions: { top: 1000 }, language: webApplicationLanguageCode } });
        },



        // details
        _detailsSiteSetting: ( id:string, webApplicationLanguageCode:string ) => {
            siteSettingServiceAction.details({ servicePayload: { key: id, language: webApplicationLanguageCode } });
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TWebApplicationWrapperLayout<T> = {
    
} & T;


/* component */
function WebApplicationWrapperLayoutLoader () {

    return (
        <></>
    )

}

function WebApplicationWrapperLayout ( props:TWebApplicationWrapperLayout<PropsFromRedux> ) {
    console.log("WebApplicationWrapperLayout.rendered: ", props);

    const acceptedCookies = false;

    useEffect(
        () => {
            if ( !Lodash.isEmpty(props.webApplicationLanguageCode)) {
                props._listSiteSetting(props.webApplicationLanguageCode);
                props._listCatalogCategory(props.webApplicationLanguageCode);
            }
        }, [ props.webApplicationLanguageCode ]
    )

    useEffect (
        () => {
            if ( !Lodash.isEmpty(props.siteSettingListPayload) && props.siteSettingListPayload && props.siteSettingListPayload.length > 0 ) {
                props._detailsSiteSetting(props.siteSettingListPayload[0].id, props.webApplicationLanguageCode)
            }
        }, [ props.siteSettingListPayload ]
    )

    if ( !Lodash.isEmpty(props.siteSettingListPayload) && !Lodash.isEmpty(props.siteSettingDetailPayload) ) {

        PutManifestFileDynamic(document, props.siteSettingDetailPayload);

        return (
            <div className="flex flex-col min-h-screen">  
                <Header />
                
                <main
                    className="relative flex-grow"
                    style={{
                        minHeight: '-webkit-fill-available',
                        WebkitOverflowScrolling: 'touch',
                    }}
                >
                    <Outlet />
                </main>

                <Footer mapLayoutName="MNPGGRP_002" />
                
                <MobileNavigation />
                
                {/* Mobil de search açmak için kullanılıyor */}
                <Search  />
                
                {/* Ürünlerin detaylarını göstermek için kullanılıyor */}
                <ProductPopup variations={[]} />

                {/* <CookieBar
                    title={t['text-cookies-title']}
                    hide={acceptedCookies}
                    action={
                        <Button onClick={() => { 
                            // onAcceptCookies()
                        }} variant="type1-slim">
                            {t['text-accept-cookies']}
                        </Button>
                    }
                /> */}
            </div>
        )

    }
    
    return null;

};

const Component = React.memo(WebApplicationWrapperLayout, ( prevProps:TWebApplicationWrapperLayout<PropsFromRedux>, nextProps:TWebApplicationWrapperLayout<PropsFromRedux> ) => {
    console.log("WebApplicationWrapperLayout.memo", { prevProps, nextProps });

    return !(
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.webApplicationLanguageCode !== nextProps.webApplicationLanguageCode
        || prevProps.siteSettingDetailPayload !== nextProps.siteSettingDetailPayload
    )
});

export default connector(Component);
