import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import ContainerV2 from "components/ContainerV2";
import SectionHeader from "components/SectionHeader";
import IconCard from "components/IconCard";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// actions
import TValidations from "cms/types/TValidations";
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ECommerceAction from 'ecommerce/actions/common/ECommerceAction';
import StockStatusServiceAction from "ecommerce/actions/components/MainPageLayouts/MNPG007/StockStatusServiceAction";
import ClientProductsServiceAction from "ecommerce/actions/components/MainPageLayouts/MNPG007/ClientProductsServiceAction";
import IEnum from "core/interfaces/IEnum";
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import { EContentStatus } from "cms/enums/EContentStatus";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import { isEmpty } from "lodash";
import ERedirectType from "cms/enums/ERedirectType";
import ProductCardV2, { ProductCardV2Loader } from "components/ProductCardV2";
import Countdown from 'react-countdown';
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import NoData from "components/NoData";
import { HiOutlineNewspaper } from "react-icons/hi2";


function mapStateToProps ( state: any ) {
    console.log("MNPG007.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    const productListPayload:any                                = state.MNPG007Store.ClientProductsStore.get;
    const productListError:TValidations[]|null                  = state.MNPG007Store.ClientProductsStore.get.error?.value;
    const productListIsLoading:boolean                          = state.MNPG007Store.ClientProductsStore.get.isLoading;

    const stockStatusPayload:IEnum[]                            = state.MarketPlaceStore.StockStatusStore.list.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        productListPayload,
        productListError,
        productListIsLoading,

        stockStatusPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG007.mapDispatchToProps");

    const clientProductsServiceAction = new ClientProductsServiceAction(dispatch);
    const stockStatusServiceAction = new StockStatusServiceAction(dispatch);
    const ecommerceAction = new ECommerceAction(dispatch);

    return {

        _list: ( tagId:string, webApplicationLanguageCode:string, destinationName:string ) => {

            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `tags/any(d: d/id eq ${tagId})`],
                count: true,
                top: 8,
                skip: 0,
                orderBy: ["id desc"]
            };

            clientProductsServiceAction.search({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
            
        },

        _getStockStatus: ( webApplicationLanguageCode:string ) => {

            stockStatusServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        top: 1000
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _openProductDetailPopup: ( value:IClientProductList ) => {

            ecommerceAction.openProductDetailPopup(value);
        
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TWrapperProps = {
    sectionHeading?: string|null;
    className?: string;
    date?: any;
    disableSectionPadding?: boolean;
    disableSectionBorder?: boolean;
    hideCountdown?: boolean;
    TwoXlCols?: number;
    itemGap?: number;
    demoVariant?: 'ancient';
    disableBorderRadius?: boolean;
    bgGray?: boolean;
}

type TProductsProps =  {
    limit?: number;
    itemVariant?:
    | 'grid'
    | 'gridSlim'
    | 'list'
    | 'listSmall'
    | 'gridModern'
    | 'gridModernWide'
    | 'gridTrendy'
    | 'rounded'
    | 'circle';
} & TWrapperProps & PropsFromRedux;



/* component */
function MMNPG007Loader () {

    return (
        <div className="animate-pulse">
            <div className="grid grid-cols-1 gap-x-3 gap-y-4 md:grid-cols-2 md:gap-x-5 lg:grid-cols-4 lg:gap-y-5 xl:gap-x-7 xl:gap-y-6 2xl:gap-y-8" >
                {
                    Array.from({ length:4 }).map((item) => (
                        <ProductCardV2Loader variant='gridModern' />
                    ))
                }
            </div>
        </div>
    )

}

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
        // Render a completed state
        return <span>Time Over!</span>;
    } else {
        // Render a countdown
        return (
            <div className="flex items-center gap-x-1.5 md:gap-x-2.5">
                <div className="text-heading text-10px md:text-xs text-center uppercase">
                    <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                        {days}
                    </span>
                    days
                </div>
                <div className="text-heading text-10px md:text-xs text-center uppercase">
                    <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                        {hours}
                    </span>
                    hours
                </div>
                <div className="text-heading text-10px md:text-xs text-center uppercase">
                    <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                        {minutes}
                    </span>
                    mins
                </div>
                <div className="text-heading text-10px md:text-xs text-center uppercase">
                    <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                        {seconds}
                    </span>
                    secs
                </div>
            </div>
        );
    }
};

function ProductFlashSaleWrapper({
    children,
    className,
    disableSectionBorder,
    disableSectionPadding,
    sectionHeading,
    hideCountdown,
    date,
}: React.PropsWithChildren<TWrapperProps>) {
    return (
        <>
            <div
                className={`${className} ${!disableSectionBorder && 'border border-gray-300'
                    } rounded-md ${!disableSectionPadding &&
                    'pt-5 md:pt-6 lg:pt-7 pb-5 lg:pb-7 px-4 md:px-5 lg:px-7'
                    }`}
            >
                <div className="flex justify-between items-center flex-wrap mb-5 md:mb-6">
                    <SectionHeader
                        sectionHeading={sectionHeading}
                        className="mb-0"
                    />
                    {!hideCountdown && (
                        <Countdown date={date} intervalDelay={1000} renderer={renderer} />
                    )}
                </div>
                {children}
            </div>
        </>
    );
}

function MNPG007 ( props:TMainPageLayout<TProductsProps> ) {
    console.log("MNPG007.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });
    const mainPageItemLanguageVersion = LanguageVersionSelector<ILanguageVersionList[]|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.TAB, contentType: EContentType.MAIN_PAGE_ITEM }
        ],
        type: "filter"
    });

    const tagId = mainPageItemLanguageVersion?.[0]?.value;
    const key = `P${tagId}`


    useEffect(
        () => {
            
            props._getStockStatus(props.webApplicationLanguageCode);

            if ( !isEmpty(mainPageItemLanguageVersion?.[0]) ) {
                const tagId = mainPageItemLanguageVersion[0].value;
                if ( tagId && !isEmpty(tagId) ) {
                    props._list( tagId, props.webApplicationLanguageCode, `P${tagId}` );       
                }
            }

        }, [props.parentPayload]
    )

    function onClick ( event: React.MouseEvent<HTMLDivElement, MouseEvent>, value:IClientProductList ) {

        props._openProductDetailPopup(value);

    }

    return (
        <ContainerV2>
            <ProductFlashSaleWrapper
                sectionHeading={parentLanguageVersion?.name}
                className="mb-7 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-[75px]"
                disableSectionPadding={true}
                disableSectionBorder={true}
                hideCountdown={true}
            >

                {
                    props.productListIsLoading && isEmpty(props.productListError)  && ( 
                        <MMNPG007Loader /> 
                    )
                }
                
                {
                    !props.productListIsLoading && isEmpty(props.productListPayload?.[key as any]) && (
                        <NoData
                            icon={<HiOutlineNewspaper className="text-8xl stroke-1" />}
                            className="flex flex-col items-center justify-center h-80 bg-gray-100"
                            textClassName="text-center text-xl"
                            siteSettingPayload={props.siteSettingDetailPayload}
                        />
                    )
                }

                {
                    mainPageItemLanguageVersion && !isEmpty(mainPageItemLanguageVersion) && !isEmpty(props.productListPayload?.[key as any]) &&
                        (
                            <div
                                className={"grid grid-cols-1 gap-x-3 gap-y-4 md:grid-cols-2 md:gap-x-5 lg:grid-cols-4 lg:gap-y-5 xl:gap-x-7 xl:gap-y-6 2xl:gap-y-8"}
                            >
                                {
                                    props.productListPayload?.[key as any]?.map((item:IClientProductList, index:number) => (
                                        <ProductCardV2
                                            key={`product--key${item.id}`}
                                            payload={item}
                                            stockStatusDataSource={props.stockStatusPayload}
                                            imgWidth={324}
                                            imgHeight={324}
                                            variant={'listSmall'}
                                            disableBorderRadius={true}
                                            demoVariant={props.demoVariant}
                                            bgGray={true}
                                            onClick={(event) => { onClick(event, item) }}
                                        />
                                    ))
                                }
                            </div>
                        )              
                }
                            
            </ProductFlashSaleWrapper>
        </ContainerV2>
    )

}

const Component = React.memo(MNPG007, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG007.memo", { prevProps, nextProps });

    return !(
        prevProps.productListPayload !== nextProps.productListPayload
        || prevProps.productListIsLoading !== nextProps.productListIsLoading
    );
});

export default connector(Component);
