import { isEmpty } from 'lodash';
import IClientMenuItemList from "cms/interfaces/IClientMenuItemList";
import TMenu from 'cms/types/TMenu';
import IClientCatalogCategoryList from 'ecommerce/interfaces/IClientCatalogCategoryList';


function MenuComposer ( value:IClientMenuItemList[]|null, parentMenuItems:IClientMenuItemList[]|undefined|null, catalogCategoryPayload?:IClientCatalogCategoryList[]) {

    let _value:TMenu[] = [];

    if ( value && parentMenuItems && !isEmpty(value) && !isEmpty(parentMenuItems) ) {

        for ( let parentMenuItem of parentMenuItems ) {
            
            let hierarchyId = undefined;
            if ( !isEmpty(catalogCategoryPayload) ) {
                hierarchyId = catalogCategoryPayload?.find((x) => x.id === parentMenuItem.contentId)?.hierarchyId;
            }

            let _parentMenuItem:TMenu = {...parentMenuItem, hierarchyId};
    
            let childMenuItems = value.filter((item:any) => item.parentId === parentMenuItem.id);
            if ( childMenuItems.length > 0 ) {
                    let response = MenuComposer(value, childMenuItems, catalogCategoryPayload);
                    if ( response.length > 0 ) {
                        _value.push({ ..._parentMenuItem, items: response });
                    }
            }
            else {
                _value.push(_parentMenuItem);
            }
        }

    }

    return _value;

}

export default MenuComposer;
