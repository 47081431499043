import React, { useEffect, useState, createRef, useCallback } from "react";
import { isEmpty } from "lodash";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import { IoIosCloseCircle } from "react-icons/io";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


type TRMDRPopup = {

    id:string;
    title?:string;
    payload:string;

    redirectUrl?:string|null;

}

function RMDRPopup ( props:Readonly<TRMDRPopup> ) {

    const [ isShow, setIsShow ] = useState("flex");
    const [ popupRef, setPopupRef ] = useState<any>();

    // useEffect(
    //     () => {

    //         const mainPagePopupLocalItem = JSON.parse(localStorage.getItem("mainPagePopup")||"{}");
    //         if ( !isEmpty(mainPagePopupLocalItem) ) {
                
    //             const popupDate = new Date(`${mainPagePopupLocalItem?.date}T00:00:00Z`) as any;
    //             const currentDateString = new Date().toISOString().split("T")[0];
    //             const currentDate = new Date(`${currentDateString}T00:00:00Z`) as any;

    //             if ( mainPagePopupLocalItem?.status !== true || currentDate-popupDate > 0 ) {
    //                 setIsShow("flex");
    //             }

    //         } else {

    //             setIsShow("flex");
            
    //         }

    //     }, []
    // )

    useEffect(() => {

        console.log("handleClickOutside.useEffect: ", popupRef);

        if ( !isEmpty(popupRef) ) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return function cleanup() {
            document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [ popupRef ])

    function closeRMDRPopup ( event:any ) {

        setIsShow("hidden");
        // const date = new Date().toISOString().split("T");
        // const mainPagePopupLocalItem = {
        //     id:props.id,
        //     date: date[0],
        //     status: true,
        // }
        // localStorage.setItem("mainPagePopup", JSON.stringify(mainPagePopupLocalItem));

    }

    function handleClickOutside (event:any) {

        if (popupRef && !popupRef.contains(event.target)) {
            closeRMDRPopup( event );
        }
    }

    return (
        <div id="static-modal" aria-hidden="true" className={`${isShow} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-black bg-opacity-60`}>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <IoIosCloseCircle className="absolute right-8 top-8 z-[1] w-8 h-8 text-black cursor-pointer" onClick={closeRMDRPopup} />
                <div ref={(payload:any) => {setPopupRef(payload)}} className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {
                        !isEmpty(props.title) && props.title && (
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    { props.title }
                                </h3>
                            </div>
                        )
                    }
                    <div className="p-4 md:p-5 space-y-4">
                        {
                            props.redirectUrl && !isEmpty(props.redirectUrl) ? (
                                <a href={props.redirectUrl} className="ck-content" dangerouslySetInnerHTML={{__html: SanitizeHTMLString(props.payload)} } /> 
                            ) : (
                                <div className="ck-content" dangerouslySetInnerHTML={{__html: SanitizeHTMLString(props.payload)} } /> 
                            )
                        }
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )

};

export default RMDRPopup;