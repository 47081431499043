import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Lodash from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import ClientMainPageItemsServiceAction from "cms/actions/components/MainPagePopup/ClientMainPageItemsServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientMainPageItemList from "cms/interfaces/IClientMainPageItemList";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
// enums
import { EContentStatus } from "cms/enums/EContentStatus";
// types
import TValidations from "cms/types/TValidations";
// styles
import 'react-tabs/style/react-tabs.css';
import RMDRPopup from "./RMDRPopup";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function mapStateToProps ( state: any ) {
    console.log("MainPagePopup.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const mainPagePopupListPayload:IClientMainPageItemList     = state.MainPagePopupStore.ClientMainPageItemsStore.list?.payload?.[0];
    const mainPagePopupListError:TValidations[]|null           = state.MainPagePopupStore.ClientMainPageItemsStore.list?.error;
    const mainPagePopupListIsLoading:boolean                   = state.MainPagePopupStore.ClientMainPageItemsStore.list?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null           = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                   = state.SiteSettingStore.details?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        mainPagePopupListPayload,
        mainPagePopupListError,
        mainPagePopupListIsLoading,

        siteSettingDetailPayload,
        siteSettingDetailError,
        siteSettingDetailIsLoading,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MainPagePopup.mapDispatchToProps");

    const clientMainPageItemsServiceAction = new ClientMainPageItemsServiceAction(dispatch);

    return {
        // list
        _listClientMainPagePopup: ( mapLayoutName:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1
            };

            clientMainPageItemsServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TMainPagePopup<T> = {

    mapLayoutName?: string;
    
} & T;


/* component */
function MainPagePopupLoader () {

    return (
        <></>
    )

}

function MainPagePopup (props: TMainPagePopup<PropsFromRedux>) {
    console.log("MainPagePopup.rendered: ", props);

    const [ mainPageParentLayouts, setMainPageParentLayouts ] = useState<IClientMainPageItemList[]|null>(null);

    useEffect(
        () => {

            if ( props.mapLayoutName && !Lodash.isEmpty(props.mapLayoutName) ) {
                props._listClientMainPagePopup( props.mapLayoutName, props.webApplicationLanguageCode );
            }
                
        }, [ props.mapLayoutName ]
    )
    
    if ( props.mainPagePopupListPayload && !Lodash.isEmpty(props.mainPagePopupListPayload) && !props.mainPagePopupListIsLoading ) {

        const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.mainPagePopupListPayload.languageVersions,
            filters:[
                { view: EView.INNER, viewType: EViewType.TEXT, contentType:EContentType.DEFAULT }
            ],
            type: "find"
        });

        const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.mainPagePopupListPayload.languageVersions,
            filters:[
                { view: EView.META_DATA, viewType: EViewType.TEXT, contentType:EContentType.DEFAULT, name: "redirectUrl" }
            ],
            type: "find"
        })

        return (
            <RMDRPopup 
                id={props.mainPagePopupListPayload.id}
                payload={languageVersion?.value||""}
                redirectUrl={redirectUrl?.value}
            />
        )

    }

    return null;
    
}

MainPagePopup.defaultProps = {

    mapLayoutName: "MNPGGRP_003"

}

const Component = React.memo(MainPagePopup, (prevProps:TMainPagePopup<PropsFromRedux>, nextProps:TMainPagePopup<PropsFromRedux>) => {
    console.log("MainPagePopup.memo", { prevProps, nextProps });
    return !(prevProps.mainPagePopupListIsLoading !== nextProps.mainPagePopupListIsLoading );
});

export default connector(Component);
