import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux"
import Lodash from 'lodash';
import { Outlet } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import LanguageServiceAction from "cms/actions/components/WebApplicationStarter/LanguageServiceAction";
import CoreAction from "core/actions/common/CoreAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
// utilities
import DetectLanguage from "cms/utilities/DetectLanguage";
import IClientLanguage from "core/interfaces/IClientLanguage";
// types
import TValidations from "cms/types/TValidations";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationStarter.mapStateToProps: ", state);

    const languageStorePayload:IClientLanguage[]    = state.LanguageStore.list?.payload;
    const languageStoreError:TValidations[]|null    = state.LanguageStore.list?.error;
    const languageStoreIsLoading:boolean            = state.LanguageStore.list?.isLoading;

    const detectLanguage                = DetectLanguage(languageStorePayload);
    const webApplicationLanguage        = detectLanguage.webApplicationLanguage;
    const webApplicationLanguageISOCode = detectLanguage.webApplicationLanguageISOCode
    const webApplicationLanguageCode    = detectLanguage.webApplicationLanguageCode

    const error:TValidations[]|null|undefined   = Lodash.reject([ languageStoreError ], Lodash.isEmpty )[0];
    const isLoading:boolean                     = [ languageStoreIsLoading ].includes(true);

    return {

        languageStorePayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        error,
        isLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("WebApplicationStarter.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const languageServiceAction = new LanguageServiceAction(dispatch);

    return {

        _listLanguages: () => {
            let queryOptions:IOdataQueryOptions = {
                filter: ["isActive eq true"],
                top: 1000
            };

            languageServiceAction.list({ servicePayload: { queryOptions }, args:{ "keysToBeRemoved": ["id"] } });
        },

        _setWebApplicationLanguage: ( language:any ) => {
            coreAction.setWebApplicationLanguage(language);
        }
    
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TWebApplicationStarter<T> = {

} & T;


/* component */
function WebApplicationStarterLoader () {

    return (
        <></>
    )

}

function WebApplicationStarter ( props:TWebApplicationStarter<PropsFromRedux> ) {
    console.log("WebApplicationStarter.rendered: ", props);

    useEffect(
        () => {

            props._listLanguages();
        
        }, []
    )

    if ( !Lodash.isEmpty(props.webApplicationLanguageISOCode) && props.webApplicationLanguageISOCode && !props.isLoading ) {

        props._setWebApplicationLanguage(props.webApplicationLanguage);
        document.documentElement.lang = props.webApplicationLanguageISOCode;
        return <Outlet />;
    
    }

    return null;

};

const Component = React.memo(WebApplicationStarter, (prevProps:TWebApplicationStarter<PropsFromRedux>, nextProps:TWebApplicationStarter<PropsFromRedux>) => {
    console.log("WebApplicationStarter.memo", { prevProps, nextProps });

    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode    
    )
});

export default connector(Component);
