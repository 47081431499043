import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from "react-redux"
import Lodash from "lodash";
import { Dispatch } from '@reduxjs/toolkit';

import SearchIcon from 'components/Icons/search-icon';
import UserIcon from 'components/Icons/user-icon';
import MenuIcon from 'components/Icons/menu-icon';
import HomeIcon from 'components/Icons/home-icon';
import { Drawer } from 'components/Drawer/Drawer';
import motionProps from 'components/Drawer/Motion';
import CartButton from 'components/Cart/CartButton';
import MobileMenu from 'components/Header/components/MobileMenu';
import { Link } from 'react-router-dom';
import CMSAction from 'cms/actions/common/CMSAction';



function mapStateToProps ( state: any ) {
    console.log("MobileNavigation.mapStateToProps: ", state);

    const mobileMenuPayload = state.CMSStore.mobileMenu.isLoading;

    return {

        mobileMenuPayload

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MobileNavigation.mapDispatchToProps");

    const cmsAction = new CMSAction(dispatch);

    return {
        
        _openMobileMenu: () => {
            cmsAction.openMobileMenu();
        },

        _closeMobileMenu: () => {
            cmsAction.closeMobileMenu();
        },

        _openMobileSearch: () => {
            cmsAction.openMobileSearch();
        },

        _closeMobileSearch: () => {
            cmsAction.closeMobileSearch();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TMobileNavigation<T> = {

} & T;


/* component */
function MobileNavigationLoader () {

    return (
        <></>
    )

}

function MobileNavigation ( props:TMobileNavigation<PropsFromRedux> ) {
    console.log("MobileNavigation.rendered: ", props)

	return (
		<>
			<div className="lg:hidden fixed z-10 bottom-0 flex items-center justify-between shadow-bottomNavigation text-gray-700 body-font bg-white w-full h-14 sm:h-16 px-4 md:px-8">
				<button
					aria-label="Menu"
					className="menuBtn flex flex-col items-center justify-center flex-shrink-0 outline-none focus:outline-none"
					onClick={() => { props._openMobileMenu() }}
				>
					<MenuIcon />
				</button>

				<button
					className="flex items-center justify-center flex-shrink-0 h-auto relative focus:outline-none"
					onClick={() => { props._openMobileSearch() }}
					aria-label="search-button"
				>
					<SearchIcon />
				</button>

				<Link 
                    to="/" 
                    className="flex-shrink-0"
                >
					<HomeIcon />
				</Link>

                <div></div>
                <div></div>
                <div></div>
			</div>

			<Drawer
				placement={'left'}
				open={props.mobileMenuPayload}
				onClose={() => { props._closeMobileMenu() }}
				{...motionProps}
			>
				<MobileMenu />
			</Drawer>
		</>
	);
};

const Component = React.memo(MobileNavigation, ( prevProps:TMobileNavigation<PropsFromRedux>, nextProps:TMobileNavigation<PropsFromRedux> ) => {
    console.log("MobileNavigation.memo", { prevProps, nextProps });

    return !( 
        prevProps.mobileMenuPayload !== nextProps.mobileMenuPayload
    );
});

export default connector(Component);
