import SLDRGRP_001 from "./components/SLDRGRP_001";
import SLDRGRP_002 from "./components/SLDRGRP_002";
import SLDRGRP_003 from "./components/SLDRGRP_003";
import SLDRGRP_004 from "./components/SLDRGRP_004";


const SliderGroupLayouts:any = {
    
    SLDRGRP_001,
    SLDRGRP_002,
    SLDRGRP_003,
    SLDRGRP_004

}

export default SliderGroupLayouts;
