import React, { useEffect } from "react";
import { isEmpty, isNumber } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
// components
import SectionHeader from "components/SectionHeader";
import ProductsBlock, { ProductsBlockLoader } from "components/ProductsBlock";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// actions
import TValidations from "cms/types/TValidations";
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ContainerV2 from "components/ContainerV2";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import ClientProductsServiceAction from "ecommerce/actions/components/MainPageLayouts/MNPG003/ClientProductsServiceAction";
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import { EContentStatus } from "cms/enums/EContentStatus";
import StockStatusServiceAction from "ecommerce/actions/components/MainPageLayouts/MNPG003/StockStatusServiceAction";
import IEnum from "core/interfaces/IEnum";
import ECommerceAction from 'ecommerce/actions/common/ECommerceAction';
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import NoData from "components/NoData";
import { HiOutlineNewspaper } from "react-icons/hi2";
import Gallery06 from "components/RMDRGallery/components/Gallery06";


function mapStateToProps ( state: any ) {
    console.log("MNPG003.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const productListPayload:{[key:string]: IClientProductList[]}   = state.MNPG003Store.ClientProductsStore.get;
    const productListError:TValidations[]|null                      = state.MNPG003Store.ClientProductsStore.get.error?.value;
    const productListIsLoading:boolean                              = state.MNPG003Store.ClientProductsStore.get.isLoading;

    const stockStatusPayload:IEnum[]                            = state.MarketPlaceStore.StockStatusStore.list.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        
        productListPayload,
        productListError,
        productListIsLoading,

        stockStatusPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG003.mapDispatchToProps");

    const clientProductsServiceAction = new ClientProductsServiceAction(dispatch);
    const stockStatusServiceAction = new StockStatusServiceAction(dispatch);
    const ecommerceAction = new ECommerceAction(dispatch);


    return {

        _list: ( tagId:string, webApplicationLanguageCode:string, destinationName:string ) => {

            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `tags/any(d: d/id eq ${tagId})`],
                top: 1000,
                orderBy: ["name asc", "price asc"],
            };

            clientProductsServiceAction.search({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
            
        },

        _getStockStatus: ( webApplicationLanguageCode:string ) => {

            stockStatusServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        top: 1000
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _openProductDetailPopup: ( value:IClientProductList ) => {

            ecommerceAction.openProductDetailPopup(value);
        
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG003Loader () {

    return (
        <div className="animate-pulse">
            <ProductsBlockLoader variant='gridModern' length={5} />
        </div>
    )

}

function MNPG003 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG003.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const mainPageItemLanguageVersionList = LanguageVersionSelector<ILanguageVersionList[]|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.TAB, contentType: EContentType.DEFAULT }
        ],
        type: "filter"
    });

    const itemList = [
        mainPageItemLanguageVersionList?.find((item) => item.value === "36"),
        mainPageItemLanguageVersionList?.find((item) => item.value === "20")
    ];

    useEffect(
        () => {
            
            props._getStockStatus(props.webApplicationLanguageCode);

        }, []
    )

    useEffect(
        () => {

            if ( mainPageItemLanguageVersionList && !isEmpty(mainPageItemLanguageVersionList) ) {
                for ( let item of mainPageItemLanguageVersionList ) {
                    const tagId = item.value;
                    if ( tagId && !isEmpty(tagId) ) {
                        props._list( tagId, props.webApplicationLanguageCode, `P${tagId}` );
                    }
                }
            }

        }, [ props.parentPayload ]
    )

    function onClick ( event: React.MouseEvent<HTMLDivElement, MouseEvent>, value:IClientProductList ) {

        props._openProductDetailPopup(value);

    }

    return (
        <ContainerV2>
            <div className='mb-12 md:mb-14 xl:mb-16'>
                <SectionHeader
                    sectionHeading=''
                    className='pb-0.5 mb-1 sm:mb-1.5 md:mb-2 lg:mb-3 2xl:mb-4 3xl:mb-5'
                />

                <TabGroup as='div' className=''>
                    <TabList as='ul' className='tab-ul'>
                        {
                            itemList?.map((item, index) => {
                                return (
                                    <Tab
                                        key={`tab-${index}`}
                                        as='li'
                                        className={({ selected }) =>
                                            selected ? 'tab-li-selected' : 'tab-li'
                                        }
                                    >
                                        <p>{item?.name}</p>
                                    </Tab>
                                )
                            })
                        }
                    </TabList>

                    <TabPanels>
                        {
                            itemList?.map((item:any, index) => {
                                const tagId = item.value;
                                const key = `P${tagId}`;
                                
                                if ( props.productListIsLoading && isEmpty(props.productListError) ) {
                                    return (
                                        <TabPanel key={tagId}>
                                            <MMNPG003Loader />
                                        </TabPanel>
                                    )
                                }
                                else if ( tagId && !isEmpty(tagId) && key && !isEmpty(props.productListPayload?.[key as any]) ) {
                                    return (
                                        <TabPanel key={tagId}>
                                            <Gallery06
                                                payload={props.productListPayload[key]}
                                                variant='gridModern'
                                            />
                                        </TabPanel>
                                    )
                                } else {
                                    return (
                                        <TabPanel key={tagId}>
                                            <NoData
                                                icon={<HiOutlineNewspaper className="text-8xl stroke-1" />}
                                                className="flex flex-col items-center justify-center h-80 bg-gray-100"
                                                textClassName="text-center text-xl"
                                                siteSettingPayload={props.siteSettingDetailPayload}
                                            />
                                        </TabPanel>
                                    )
                                }
                            })
                        }
                    </TabPanels>
                </TabGroup>
            </div>
        </ContainerV2>
    )

}

const Component = React.memo(MNPG003, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG003.memo", { prevProps, nextProps });

    return !(
        prevProps.productListPayload !== nextProps.productListPayload
    );
});

export default connector(Component);
