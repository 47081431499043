import CategoryFilter from "./CategoryFilter";
import BrandFilter from "./BrandFilter";
import PriceFilter from "./PriceFilter";
import TagFilter from "./TagFilter";
import { useSearchParams } from "react-router-dom";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";


type TShopFilters = {

    siteSettingPayload:IClientSiteSettingDetail;

}

function ShopFilters ( props:TShopFilters ) {
	
    const [ searchParams, setSearchParams ] = useSearchParams();

    const clearAll = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "clearAll" }
        ],
        type: "find"
    });

    const filters = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "filters" }
        ],
        type: "find"
    });

    return (
		<div className="pt-1">
			<div className="block border-b border-gray-300 pb-7 mb-7">
				<div className="flex items-center justify-between mb-2.5">
					<h2 className="font-semibold text-heading text-xl md:text-2xl">
						{filters?.value}
					</h2>
					<button
						className="flex-shrink text-xs mt-0.5 transition duration-150 ease-in focus:outline-none hover:text-heading"
						aria-label={clearAll?.value||""}
						onClick={() => {
                            setSearchParams({ o: searchParams.get("o")||"" }, { replace:true });
                        }}
					>
						{ clearAll?.value }
					</button>
				</div>
				{/* <div className="flex flex-wrap -m-1.5 pt-2">
					{!isEmpty(query) &&
						Object.values(query)
							.join(",")
							.split(",")
							.map((v, idx) => (
								<FilteredItem
									itemKey={
										Object.keys(query).find((k) => query[k]?.includes(v))!
									}
									itemValue={v}
									key={idx}
								/>
							))}
				</div> */}
			</div>

			<CategoryFilter />
			{/* <BrandFilter /> */}
            <TagFilter />
			<PriceFilter siteSettingDetailPayload={props.siteSettingPayload} />
		</div>
	)

}

export default ShopFilters;
