import { combineReducers } from 'redux';
// reducers
import ClientBrandsServiceStore from "ecommerce/reducers/services/ClientBrandsServiceStore";


const screenCode = "402";

const BrandFilterStore = combineReducers({

    ClientBrandsStore: ClientBrandsServiceStore({ screenCode }),
    
});

export default BrandFilterStore;
