import React, { useState } from 'react'
import { connect, ConnectedProps } from "react-redux"
import ProductAttributes from 'components/ProductAttributes';
import ECommerceAction from 'ecommerce/actions/common/ECommerceAction';
import Modal from 'components/Modal';
import { Dispatch } from '@reduxjs/toolkit';
import RMDRImage from 'components/RMDRImage';
import IClientProductList from 'ecommerce/interfaces/IClientProductList';
import { EResourceType } from 'cms/enums/EResourceType';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
import RMDRLink from 'components/RMDRLink';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import ERedirectType from 'cms/enums/ERedirectType';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import { MdKeyboardDoubleArrowDown } from "react-icons/md";


function mapStateToProps ( state: any ) {
    console.log("ProductPopup.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details.payload;

    const productDetailPopupIsOpen:boolean              = state.ECommerceStore.productDetailPopup.isLoading;
    const productDetailPopupPayload:IClientProductList  = state.ECommerceStore.productDetailPopup.payload;

    return {

        siteSettingDetailPayload,

        productDetailPopupPayload,
        productDetailPopupIsOpen

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("ProductPopup.mapDispatchToProps");

    const ecommerceAction = new ECommerceAction(dispatch);

    return {

        _closeProductDetailPopup: () => {
            ecommerceAction.closeProductDetailPopup();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TProductPopup<T> = {

    data?: {
        image: {
            original:any
        },
        name:string,
        description:string,
    };
    variations: {
        id: number;
        value: string;
        meta: string;
    }[];
    
} & T;


/* component */
function ProductPopupLoader () {

    return (
        <></>
    )

}

function ProductPopup ( props:TProductPopup<PropsFromRedux> ) {
    console.log("ProductPopup.rendered: ", props);
    
    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.productDetailPopupPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.productDetailPopupPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
        ],
        type: "find"
    });

    const variant = props.productDetailPopupPayload?.variants?.[0];

    const viewDetailsString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "viewDetailsString" }
        ],
        type: "find"
    });

    const viewCartString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "viewCartString" }
        ],
        type: "find"
    });

    const [attributes, setAttributes] = useState<{ [key: string]: string }>({});
    const [viewCartBtn ] = useState<boolean>(false);

    function navigateToProductPage() {
        
    }

    function handleAttribute(attribute: any) {
        setAttributes((prev) => ({
            ...prev,
            ...attribute,
        }));
    }

    function navigateToCartPage() {
        
    }

    return (
        <Modal open={props.productDetailPopupIsOpen} onClose={() => { props._closeProductDetailPopup(); }} >
            <div className="rounded-lg bg-white">
                <div className="flex flex-col lg:flex-row w-full md:w-[650px] lg:w-[960px] mx-auto overflow-hidden">
                    <div className="flex-shrink-0 flex items-center justify-center w-full lg:w-430px max-h-430px lg:max-h-full overflow-hidden bg-gray-300">
                        <RMDRImage
                            resources={props.productDetailPopupPayload?.variants?.[0]?.resources}
                            resourceType={EResourceType.OUTER_IMAGE}
                            alt={languageVersion?.name} 
                            title={languageVersion?.name} 
                            className="lg:object-cover lg:w-full lg:h-full"
                        />
                    </div>

                    <div className="flex flex-col p-5 md:p-8 w-full justify-between">
                        <div>
                            <RMDRLink
                                title={languageVersion?.name}
                                hasNavigationDestination={true}
                                navigationURL={languageVersion?.contentRouteNavigationURL}
                                redirectType={ERedirectType.REDIRECT_NEW_TAB}
                            >
                                <h2 className="text-heading text-md md:text-lg lg:text-xl 2xl:text-2xl font-bold hover:text-black">
                                    {languageVersion?.name}
                                </h2>
                            </RMDRLink>
                            <p className="text-body text-sm font-bold lg:text-base leading-6 lg:leading-8">
                                { props.productDetailPopupPayload?.catalogCategoryName }
                                {/* <RMDRLink
                                    title={languageCatalogCategory?.name}
                                    text={languageCatalogCategory?.name}
                                    hasNavigationDestination={true}
                                    navigationURL={languageCatalogCategory?.contentRouteNavigationURL}
                                    redirectType={ERedirectType.REDIRECT_NEW_TAB}
                                /> */}
                            </p>
                            <div className="flex items-center my-4 gap-3">
                                {
                                    variant?.discountedPercentage &&
                                        <div className="flex items-center font-bold text-base text-red-600 md:text-lg lg:text-xl 2xl:text-2xl">
                                            <MdKeyboardDoubleArrowDown className="text-red-600" />
                                            {variant?.discountedPercentage}%
                                        </div>
                                }
                                {
                                    variant?.discountedPercentage ? (
                                        <div className="text-heading font-bold text-base md:text-lg lg:text-xl 2xl:text-3xl">
                                            {variant?.currencySign||"$"}{variant?.discountedPrice}
                                        </div>
                                    ) : (
                                        <div className="text-heading font-bold text-base md:text-lg lg:text-xl 2xl:text-3xl">
                                            {
                                                variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                            }
                                        </div>
                                    )
                                }

                                {
                                    variant?.discountedPercentage && 
                                        (
                                            <del className="line-through font-segoe text-gray-400 text-sm md:text-base lg:text-lg xl:text-xl">
                                                {
                                                    variant?.price ? `${variant?.currencySign||"$"}${variant?.price}` : ""
                                                }
                                            </del>
                                        )
                                }
                            </div>

                            <p className="text-body text-sm lg:text-base leading-6 lg:leading-8" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersion?.value) }} />
                        </div>

                        {
                            props?.variations && Object.keys(props?.variations).map(( variation, index) => {
                                return (
                                    <ProductAttributes
                                        key={`popup-attribute-key${variation}`}
                                        title={variation}
                                        attributes={props.variations[variation as any] as any}
                                        active={attributes[variation]}
                                        onClick={handleAttribute}
                                    />
                                );
                            })
                        }

                        <div className="pt-2 md:pt-4">
                            {/* <div className="flex items-center justify-between mb-4 gap-x-3 sm:gap-x-4">
                                <Counter
                                    quantity={quantity}
                                    onIncrement={() => setQuantity((prev) => prev + 1)}
                                    onDecrement={() =>
                                        setQuantity((prev) => (prev !== 1 ? prev - 1 : 1))
                                    }
                                    disableDecrement={quantity === 1}
                                />
                                <Button
                                    onClick={addToCart}
                                    variant="flat"
                                    className={`w-full h-11 md:h-12 px-1.5 ${
                                    !isSelected && 'bg-gray-400 hover:bg-gray-400'
                                    }`}
                                    disabled={!isSelected}
                                    loading={addToCartLoader}
                                >
                                    {t('text-add-to-cart')}
                                </Button>
                                </div> */}

                            {
                                viewCartBtn && 
                                    (
                                        <button
                                            onClick={navigateToCartPage}
                                            className="w-full mb-4 h-11 md:h-12 rounded bg-gray-100 text-heading focus:outline-none border border-gray-300 transition-colors hover:bg-gray-50 focus:bg-gray-50"
                                        >
                                            {viewCartString?.value}
                                        </button>
                                    )
                            }

                            <RMDRLink
                                title={languageVersion?.name}
                                text={viewDetailsString?.value}
                                hasNavigationDestination={true}
                                navigationURL={languageVersion?.contentRouteNavigationURL}
                                redirectUrl={redirectUrl?.value}
                                redirectType={ERedirectType.REDIRECT_NEW_TAB}
                                className="bg-floorsAndExtras-600 text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:bg-floorsAndExtras-700 hover:shadow-cart text-[13px] md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none rounded-md w-full h-11 md:h-12"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const Component = React.memo(ProductPopup, ( prevProps:TProductPopup<PropsFromRedux>, nextProps:TProductPopup<PropsFromRedux> ) => {
    return !( 
        prevProps.productDetailPopupPayload !== nextProps.productDetailPopupPayload
        || prevProps.productDetailPopupIsOpen !== nextProps.productDetailPopupIsOpen
    )
});

export default connector(Component);
