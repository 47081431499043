import ActionCore from 'core/actions/ActionCore';


const screenCode = "CMS";
class CMSAction extends ActionCore {

    constructor( dispatch:any ) {
        super( dispatch, "", screenCode);

        this.serviceCode = "Core";
    }

    // sidebar
    openMobileMenu () {
        this.fetching("MM")
    }

    closeMobileMenu () {
        this.clean("MM");
    }

    // mobile search
    openMobileSearch () {
        this.fetching("MS")
    }

    closeMobileSearch () {
        this.clean("MS");
    }

}

export default CMSAction;
