import React, { JSXElementConstructor } from 'react';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';

interface Props {

    variant?: Variant;
    html?: string|null;

}

type Variant = 'mediumHeading' | 'heading' | 'body' | 'pageHeading' | 'subHeading';

function RenderText(props: Props) {

    const componentsMap: {
        [P in Variant]: React.ComponentType<any> | string;
    } = {
        body: 'p',
        mediumHeading: 'h3',
        heading: 'h4',
        pageHeading: 'h1',
        subHeading: 'h2',
    };

    const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = componentsMap![props.variant!];

    const htmlContentProps = props.html
        ? {
            dangerouslySetInnerHTML: { __html: SanitizeHTMLString(props.html) },
        }
        : {};

    return (
        <Component {...htmlContentProps} />
    );
};

RenderText.defaultProps = {

    variant: "body"

}

export default RenderText;
