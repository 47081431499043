import React from "react";
import Lodash from "lodash";
import { connect, ConnectedProps } from "react-redux";
import CoreDefault from "core/Defaults";
// actions
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { TINNRLayout } from "../type";
import { EResourceType } from "cms/enums/EResourceType";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";


function mapStateToProps ( state: any ) {
    console.log("INNR010.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const errors:TValidations[]|null|undefined      = Lodash.reject( [], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                         = [false].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        errors,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("INNR010.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


function INNR010 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR010.rendered: ", props);

    const data = ApplicationDocumentSelector<IApplicationDocument|null>({
        resources: props.contentDetailPayload?.resources,
        resourceType: EResourceType.DATA,
        type: "find"
    });

    window.location.href = `${CoreDefault.cdn}/${data?.filePath}`;

    return null;
}

const Component = React.memo(INNR010, ( prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR010.memo", { prevProps, nextProps });
    return !(
        prevProps.isLoading !== nextProps.isLoading 
    );
});

export default connector(Component);
