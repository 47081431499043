import { combineReducers } from 'redux';

// reducers
import ClientProductsServiceStore from "ecommerce/reducers/services/ClientProductsServiceStore";


const screenCode = "20F";
const INNR006Store = combineReducers({
 
    ClientProductsStore: ClientProductsServiceStore({ screenCode }),

});

export default INNR006Store;
