import Text from 'components/Text';


interface Props {
    sectionHeading?: string|null
    // categorySlug?: string
    className?: string
    textClassName?: string
}

function SectionHeader(props: Props) {

    return (
        <div className={`flex items-center justify-between -mt-2 ${props.className}`}>
            {
                props.sectionHeading && 
                    (
                        <Text className={props.textClassName} variant='mediumHeading'>
                            {props.sectionHeading}
                        </Text>
                    )
            }
            {/* {
                props.categorySlug && 
                    (
                        <Link
                            to={props.categorySlug}
                            className='text-xs lg:text-sm xl:text-base text-heading mt-0.5 lg:mt-1'
                        >
                            {t['text-see-all-product']}
                        </Link>
                    )
            } */}
        </div>
    )
}

SectionHeader.defaultProps = {

    sectionHeading: '',
    className: 'pb-0.5 mb-4 md:mb-5 lg:mb-6 2xl:mb-7 3xl:mb-8',
    textClassName: '',

}

export default SectionHeader
