import cn from "classnames";


interface Props {
	className?: string;
	children?: any;
	el?: HTMLElement;
	clean?: boolean;
}

function ContainerV2 ( props:Props ) {
	const rootClassName = cn(props.className, {
		"mx-auto max-w-[1920px] px-2.5": !props.clean,
	});

	let Component: React.ComponentType<
		React.HTMLAttributes<HTMLDivElement>
	> = props.el as any;

	return <Component className={rootClassName}>{props.children}</Component>;
};

ContainerV2.defaultProps = {
    el: "div",
};

export default ContainerV2;
