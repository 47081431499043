import _ClientProductsServiceAction from "ecommerce/actions/services/ClientProductsServiceAction";


const screenCode = "20F";
class ClientProductsServiceAction extends _ClientProductsServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);
    }

}

export default ClientProductsServiceAction;
