const ProductViewIcon = ({ className = '' }) => {
  return (
    <svg
      viewBox='0 0 52 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.8958 11C32.9484 11 35 13.1392 35 16.3224V23.6776C35 26.8608 32.9484 29 29.8958 29H22.0952C19.0471 29 17 26.8608 17 23.6776V16.3224C17 13.1392 19.0471 11 22.0952 11H29.8958ZM29.8958 12.3534H22.0952C19.8217 12.3534 18.3527 13.9116 18.3527 16.3224V23.6776C18.3527 26.0893 19.8217 27.6466 22.0952 27.6466H29.8958C32.1747 27.6466 33.6473 26.0893 33.6473 23.6776V16.3224C33.6473 13.9116 32.1747 12.3534 29.8958 12.3534ZM30.472 20.1122C30.4777 20.1169 30.4834 20.1215 30.4951 20.1325L30.5125 20.1491C30.5161 20.1525 30.5199 20.1563 30.5242 20.1605L30.5745 20.2105C30.7372 20.3734 31.1867 20.8327 32.5264 22.2091C32.787 22.4761 32.7825 22.9047 32.5146 23.1655C32.2477 23.428 31.8184 23.4199 31.5578 23.1528C31.5578 23.1528 29.71 21.2554 29.5784 21.1273C29.4386 21.0127 29.214 20.9459 28.9931 20.9676C28.7685 20.9901 28.5665 21.0975 28.4231 21.2716C26.3274 23.8151 26.3021 23.8395 26.2678 23.8728C25.4941 24.6325 24.2451 24.6199 23.4849 23.844C23.4849 23.844 22.6462 22.9922 22.6318 22.9751C22.4234 22.782 22.0519 22.7946 21.8292 23.0301L20.4494 24.4855C20.3159 24.6262 20.1374 24.6966 19.9588 24.6966C19.7911 24.6966 19.6242 24.6352 19.4935 24.5107C19.222 24.2545 19.2112 23.825 19.4682 23.5552L20.8462 22.0999C21.5758 21.3249 22.8067 21.287 23.585 22.0178L24.4489 22.8948C24.6897 23.1384 25.0811 23.1429 25.3227 22.9047C25.4138 22.7974 27.378 20.4109 27.378 20.4109C27.7513 19.958 28.278 19.6783 28.8632 19.6205C29.4494 19.5691 30.0184 19.7396 30.472 20.1122ZM22.9142 15.1765C24.1605 15.1774 25.1732 16.1915 25.1732 17.4357C25.1732 18.6817 24.1596 19.6958 22.9142 19.6958C21.6688 19.6958 20.6561 18.6817 20.6561 17.4357C20.6561 16.1897 21.6688 15.1765 22.9142 15.1765ZM22.9133 16.5298C22.4146 16.5298 22.0088 16.9358 22.0088 17.4357C22.0088 17.9355 22.4146 18.3425 22.9142 18.3425C23.4138 18.3425 23.8205 17.9355 23.8205 17.4357C23.8205 16.9368 23.4138 16.5307 22.9133 16.5298Z'
        fill='#212121'
      />
    </svg>
  )
}

export default ProductViewIcon
