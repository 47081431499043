import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import StockStatusService from 'ecommerce/services/enum/StockStatusService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';


function StockStatusServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: StockStatusService.code });

    return combineReducers({

        ...reduxStorages.LIST()
    
    });
}

export default StockStatusServiceStore;
