import React from "react";
import { connect, ConnectedProps } from "react-redux"
import Lodash from 'lodash';
import { Navigate } from "react-router-dom";


function mapStateToProps ( state: any ) {
    console.log("WebApplicationRedirector.mapStateToProps: ", state);

    const webApplicationLanguage        = state.CoreStore.language?.payload;
    const webApplicationLanguageISOCode = state.CoreStore.languageCode?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageISOCode
    
    }
}

const connector = connect( mapStateToProps );


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TWebApplicationRedirector<T> = {
    
} & T;


/* component */
function MainPageItemsLoader () {

    return (
        <></>
    )

}

function WebApplicationRedirector ( props:TWebApplicationRedirector<PropsFromRedux> ) {
    console.log("WebApplicationRedirector.rendered: ", props);

    if ( !Lodash.isEmpty(props.webApplicationLanguageISOCode) ) {

        return <Navigate to={`/${props.webApplicationLanguageISOCode}`} replace={true} />;
    
    }

    return null;

};

const Component = React.memo(WebApplicationRedirector, ( prevProps:TWebApplicationRedirector<PropsFromRedux>, nextProps:TWebApplicationRedirector<PropsFromRedux> ) => {
    console.log("WebApplicationRedirector.memo", { prevProps, nextProps });

    return !( 
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode 
        || !Lodash.isEmpty(nextProps.webApplicationLanguageISOCode)
    )
});

export default connector(Component);
