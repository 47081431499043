import { useState } from 'react';
import CoreDefaults from "core/Defaults";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { isEmpty } from 'lodash';
import ProductCard from "components/ProductCard";
import cn from "classnames";

// types
import { TGallery } from '../type';
// 
import "../gallery07.scss";
import IClientProductList from 'ecommerce/interfaces/IClientProductList';
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
import BrandCard from 'components/BrandCard';

type TVariant = "circle"
    | "rounded"
    | "listSmall"
    | "grid"
    | "gridSlim"
    | "list"
    | "gridModern"
    | "gridModernWide"
    | "gridTrendy"
    | undefined;

type TGallery07 = {

    payload: IClientMainPageItemList[];

    hideProductDescription?: boolean;
    showCategory?: boolean;
    showRating?: boolean;
    disableBorderRadius?: boolean;

    variant?: TVariant;
    demoVariant?: "ancient";

};


function Gallery07 ( props:TGallery<TGallery07> ) {

    if ( !isEmpty(props.payload) ) {
        return (
            <Swiper
                slidesPerView={2}
                spaceBetween={8}
                loop={true}
                navigation={true}
                pagination={{
                    clickable: true, 
                }}
                modules={[FreeMode, Pagination, Navigation]}
                className="Gallery07"
                breakpoints={{
                    480: {
                      slidesPerView: 4,
                      spaceBetween: 8,
                    },
                    1500: {
                        slidesPerView: 6,
                        spaceBetween: 8,
                    },
                }}
            >  
                {
                    props.payload?.map((item, index) => (
                        <SwiperSlide key={`ss_${item.id}`}>
                            <BrandCard payload={item} index={index} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        );    
    }
}

Gallery07.defaultProps = {

    variant: "grid",
    hideProductDescription: false,
    showCategory: false,
    showRating: false,
    disableBorderRadius: false,

}

export default Gallery07;
