import { IoLocationSharp, IoMail, IoCallSharp } from 'react-icons/io5';
// interfaces
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';


/* types */
type TContactInfoBlock = {

    title?:string|null;
    languageVersions?:ILanguageVersionList[]|ILanguageVersionDetail[]|null;
    className?:string;

}


/* component */
function ContactInfoBlockLoader () {

    return (
        <></>
    )

}

function ContactInfoBlock ( props:TContactInfoBlock ) {

    if ( props.languageVersions ) {

        const addressString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "addressString" }
            ],
            type: "find"
        });
        const address = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "address" }
            ],
            type: "find"
        });
        const phoneString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "phoneString" }
            ],
            type: "find"
        });
        const phone = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "phone" }
            ],
            type: "find"
        });
        const emailString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "emailString" }
            ],
            type: "find"
        });
        const email = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }
            ],
            type: "find"
        });

        return (
            <div className={`${props.className} mt-10`}>
                
                {
                    props.title &&
                        (
                            <h4 className="text-2xl md:text-lg font-bold text-heading pb-7 md:pb-10 lg:pb-6 -mt-1">
                                {props.title}
                            </h4>
                        )
                }

                <div className="flex pb-7">
                    <div className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10">
                        <IoLocationSharp />
                    </div>
                    <div className="flex flex-col pl-3 2xl:pl-4">
                        <h5 className="text-sm font-bold ">
                            {addressString?.value}
                        </h5>
                        <div className="text-sm mt-0">
                            {address?.value}
                        </div>
                    </div>
                </div>

                <div className="flex pb-7">
                    <div className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10">
                        <IoCallSharp />
                    </div>
                    <div className="flex flex-col pl-3 2xl:pl-4">
                        <h5 className="text-sm font-bold ">
                            {emailString?.value}
                        </h5>
                        <div className="text-sm mt-0">
                            {email?.value}
                        </div>
                    </div>
                </div>

                <div className="flex pb-7">
                    <div className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10">
                        <IoMail />
                    </div>
                    <div className="flex flex-col pl-3 2xl:pl-4">
                        <h5 className="text-sm font-bold ">
                            {phoneString?.value}
                        </h5>
                        <div className="text-sm mt-0">
                            {phone?.value}
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    return <ContactInfoBlockLoader />
};

export default ContactInfoBlock;
