import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import Moment from "moment";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// types
import TValidations from "cms/types/TValidations";
import { TSHWRMLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientPageList from "cms/interfaces/IClientPageList";
import ClientPagesServiceAction from "cms/actions/components/ShowroomLayouts/ClientPagesServiceAction"
import CoreAction from "core/actions/common/CoreAction";
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import { EContentStatus } from "cms/enums/EContentStatus";
import { isEmpty } from "lodash";
import NoData from "components/NoData";
import { HiOutlineNewspaper } from "react-icons/hi2";
import Pagination from "components/Pagination";
import Container from "components/Container";
import PageGrid from "components/PageGrid";
import ContentHeaderRow from "components/ContentHeaderRow";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import SEO from "components/SEO";


function mapStateToProps ( state: any ) {
    console.log("SHWRM001.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;
    const siteSettingDetailError:TValidations[]|null            = state.SiteSettingStore.details?.error;
    const siteSettingDetailIsLoading:boolean                    = state.SiteSettingStore.details?.isLoading;

    const pageListPayload:IClientPageList[]                     = state.ShowroomLayoutStore.ClientPagesStore.list.payload;
    const pageListCount:number                                  = state.ShowroomLayoutStore.ClientPagesStore.list?.count;
    const pageListError:TValidations[]|null                     = state.ShowroomLayoutStore.ClientPagesStore.list.error?.value;
    const pageListIsLoading:boolean                             = state.ShowroomLayoutStore.ClientPagesStore.list.isLoading;

    const paginationPayload:any                                 = state.ShowroomLayoutStore.ClientPagesStore.pagination?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        siteSettingDetailError,
        siteSettingDetailIsLoading,

        pageListPayload,
        pageListCount,
        pageListError,
        pageListIsLoading,

        paginationPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("SHWRM001.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);

    return {
        // list
        _listPages: ( contentId:string, webApplicationLanguageCode:string, orderBy: [], top:number, currentPage:number ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`contentId eq ${contentId}`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy,
                top,
                skip: (currentPage-1) * top,
                count: true,
            };

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // pagination
        _setPaginationInit: (contentId:string) => {
            clientPagesServiceAction.modifyPagination([
                {key:"orderBy", value: ["creationDateTime desc"]},
                {key:"itemsPerPage", value: 12},
                {key:"currentPage", value: 1},
                {key:"hasSearch", value: true},
                {key:"hasFirstSearch", value: true},
                {key:"scrollTo", value: true},

                {key:"contentId", value: contentId},
            ]);
        },
        _setCurrentPage: ( currentPage:number ) => {
            clientPagesServiceAction.modifyPagination([
                {key:"currentPage", value: currentPage},
                {key:"hasSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },
        _setLastPage: ( lastPage:number ) => {
            clientPagesServiceAction.modifyPagination([
                {key:"lastPage", value: lastPage},
            ]);
        },
        _setFirstSearch: ( value:boolean ) => {
            clientPagesServiceAction.modifyPagination([
                {key:"hasFirstSearch", value}
            ]);
        },
        _setHasSearch: ( value:boolean = true ) => {            
            clientPagesServiceAction.modifyPagination([
                {key:"hasSearch", value},
            ]);
        },
        _setScrollTo: ( value:boolean ) => {
            clientPagesServiceAction.modifyPagination([
                {key:"scrollTo", value},
            ]);
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
            clientPagesServiceAction.paginationClean();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function SHWRM001Loader () {

    return (
        <></>
    )

}

const SHWRM001 = ( props:TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM001.rendered", props)

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });
    
    useEffect(
        () => {
            // props._showLoader();
            props._setPaginationInit(props.contentDetailPayload.id);

            return () => {
                props._clean();
                props._showLoader();
            }
        }, []
    )

    useEffect(
        () => {          
            if ( !isEmpty(props.paginationPayload.contentId) && props.paginationPayload.hasSearch ) {
                // props._showLoader();
                props._listPages( props.paginationPayload.contentId, props.webApplicationLanguageCode, props.paginationPayload.orderBy, props.paginationPayload.itemsPerPage, props.paginationPayload.currentPage );
                props._setHasSearch(false);
            }
        }, [ props.paginationPayload ]
    )

    useEffect (
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);
        
        }, [ props.pageListPayload ]
    )

    useEffect(
        () => {
            try {
                if ( props.pageListCount > 0 ) {
                    let _lastPage = Math.ceil(props.pageListCount/props.paginationPayload.itemsPerPage);
                    props._setLastPage(_lastPage);
                }
            }
            catch(ex) {}
        }, [props.pageListCount]
    )

    useEffect(
        () => {
            if (props.paginationPayload?.scrollTo) {
                window.scrollTo({ top: 0 });
                props._setScrollTo(false);
            }
        }, [props.paginationPayload]
    )

    function paginationOnClick(event:any, index:number) {
    
        props._setCurrentPage(index);
    
    }

    return (
        <>
            <SEO contentPayload={props.contentDetailPayload} siteSettingPayload={props.siteSettingDetailPayload} type="showroom-page" />

            <ContentHeaderRow
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload?.resources} 
                resourceType={EResourceType.PARALLAX} 
            />

            {
                isEmpty(props.pageListPayload) && !props.pageListIsLoading ? (
                    <div className="row">
                        <NoData
                            icon={<HiOutlineNewspaper className="text-8xl stroke-1" />}
                            className="flex flex-col items-center justify-center py-60"
                            textClassName="text-center text-2xl"
                            siteSettingPayload={props.siteSettingDetailPayload}
                        />
                    </div>
                ) : ( 
                    <Container>
                        <div className={`pt-8 pb-16 lg:pb-20`}>
                            
                            <div className="w-full">
                                {/* <TopFilter totalItemCount={props.clientProductsListCount} /> */}
                                <PageGrid payload={props.pageListPayload} isLoading={props.pageListIsLoading} siteSettingPayload={props.siteSettingDetailPayload} />

                                {
                                    props.paginationPayload.lastPage > 1 &&
                                        <Pagination currentPage={props.paginationPayload.currentPage} pageSize={props.paginationPayload.lastPage} disabled={props.pageListIsLoading} onClick={paginationOnClick} className="mt-10 md:mt-20" />
                                }
                            </div>
                        </div>
                    </Container>
                )
            }

            
        </>
    )

    // return (
    //     <div>

    //         <div>
    //             {
    //                 props.pageListPayload?.map((item:IClientPageList, index:number) => {
    //                     const languageVersion = item.languageVersions?.find((item) => item.view === EView.OUTTER && item.viewType === EViewType.NAME_DESCRIPTION && item.contentType === EContentType.DEFAULT );
    //                     const redirectUrl = item.languageVersions.find((lvItem) => lvItem.view === EView.META_DATA && lvItem.viewType === EViewType.TEXT && lvItem.name === "redirectURL")?.value;

    //                     const datetime = Moment(item.creationDateTime).format('MM-DD-YYYY HH:mm'); 
                        
    //                     return (
    //                         <>
    //                         This is page
    //                         </>
    //                     )
    //                 })
    //             }
    //         </div>

    //         {
    //             props.paginationPayload.lastPage > 1 &&
    //                 <Pagination currentPage={props.paginationPayload.currentPage} pageSize={props.paginationPayload.lastPage} disabled={props.pageListIsLoading} onClick={paginationOnClick} className="mt-40 mb-45" />
    //         }

    //     </div>
    // )

}


const Component = React.memo(SHWRM001, ( prevProps:TSHWRMLayout<PropsFromRedux>, nextProps:TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM001.memo", { prevProps, nextProps });

    return !(
        prevProps.pageListPayload !== nextProps.pageListPayload
        || prevProps.paginationPayload !== nextProps.paginationPayload
        || prevProps.pageListIsLoading !== nextProps.pageListIsLoading 
    );
});

export default connector(Component);