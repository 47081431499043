import React from "react";
import { isEmpty } from "lodash";
// components
import CompanyIntroduction from "./CompanyIntroduction";
// import QuickLinks from "./QuickLinks";
// types
import { TFooterComponent } from '../type';
import { ELayoutType } from "cms/enums/ELayoutType";
import ContainerV2 from 'components/ContainerV2'
import SocialMedia02 from "components/SocialMedia/components/SocialMedia02";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import Contact from "./Contact";
import ListContents01 from "./ListContents01";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function MiddleBarLoader () {

    return (
        <></>
    )

}

const MiddleBar = ( props:TFooterComponent ) => {
    console.log("MiddleBar.rendered", props);

    const parentItem = props.payload?.find((item) => item.layouts?.filter((litem) => litem.layoutName === props.layoutName).length > 0);
    console.log("MiddleBar.parentItem", parentItem);

    if ( parentItem && !isEmpty(parentItem) ) {

        const childItems = props.payload?.filter((item) => item.parentId === parentItem.id);
        console.log("MiddleBar.childItems", childItems);

        return (
            <ContainerV2>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-9 xl:gap-5  pb-9 md:pb-14 lg:pb-16 2xl:pb-20 3xl:pb-24 lg:mb-0.5 2xl:mb-0 3xl:-mb-1 xl:grid-cols-7">

                    {
                        childItems?.map((item) => {
                            
                            const layout = item.layouts.find((item) => item.layoutType === ELayoutType.MAIN_PAGE);
                            const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: "find"
                            });

                            if ( layout?.layoutName === "MNPG_010" ) {
                                return (
                                    <CompanyIntroduction
                                        payload={props.payload}
                                        webApplicationLanguageISOCode={props.webApplicationLanguageISOCode}
                                        parentPayload={item}
                                        className="pb-3 md:pb-0"
                                    />
                                )
                            }
                            else if ( layout?.layoutName === "MNPG_011" ) {
                                const childItem = props.payload?.find((cItem) => cItem.parentId === item.id);

                                return (
                                    <div className="pb-3 md:pb-0">
                                        <h4 className="mb-5 text-sm font-semibold text-heading md:text-base xl:text-lg 2xl:mb-6 3xl:mb-7">
                                            {parentLanguageVersion?.name}
                                        </h4>
                                        <SocialMedia02
                                            payload={childItem?.languageVersions}
                                            className="text-xs lg:text-sm text-body flex flex-col space-y-3 lg:space-y-3.5"
                                        />
                                    </div>
                                )
                            }
                            else if ( layout?.layoutName === "MNPG_012" ) {
                                return (
                                    <div className="pb-3 md:pb-0">
                                        <ListContents01
                                            payload={props.payload}
                                            webApplicationLanguageISOCode={props.webApplicationLanguageISOCode}
                                            parentPayload={item}
                                            className="text-xs lg:text-sm text-body flex flex-col space-y-3 lg:space-y-3.5"
                                        />
                                    </div>
                                )
                            }
                            else if ( layout?.layoutName === "MNPG_013" ) {
                                return (
                                    <div className="pb-3 md:pb-0">
                                        <h4 className="mb-5 text-sm font-semibold text-heading md:text-base xl:text-lg 2xl:mb-6 3xl:mb-7">
                                            {parentLanguageVersion?.name}
                                        </h4>
                                        <Contact
                                            payload={props.payload}
                                            webApplicationLanguageISOCode={props.webApplicationLanguageISOCode}
                                            parentPayload={item}
                                            className="text-xs lg:text-sm text-body flex flex-col space-y-3 lg:space-y-3.5"
                                        />
                                    </div>
                                )
                            }

                            return null;

                        })
                    }
                </div>
            </ContainerV2>
        )
    }

    return <MiddleBarLoader />;

}

export default MiddleBar;
