import _ClientProductsServiceAction from "ecommerce/actions/services/ClientProductsServiceAction";
import EActionType from "core/enums/EActionType";
import { IActionGetOdata } from "core/interfaces/IActionGet";
import IActionRunParams from "core/interfaces/IActionRunParams";


const screenCode = "405";
class ClientProductsServiceAction extends _ClientProductsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
    async search ( params: IActionGetOdata ) {

        this.fetching(EActionType.GET);

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: EActionType.GET
            },
            servicePayload: params.servicePayload
        }

        this.executeService( this.service.search.bind(this.service), serviceParams );
        
    }

}

export default ClientProductsServiceAction;
