import React from "react";
// types
import { TFooterComponent } from '../type';
import RMDRLink from "components/RMDRLink";
import RMDRImage from "components/RMDRImage";
import ERedirectType from "cms/enums/ERedirectType";
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import SocialMedia01 from "components/SocialMedia/components/SocialMedia01";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function CompanyIntroductionLoader () {

    return (
        <></>
    )

}

const CompanyIntroduction = ( props:TFooterComponent ) => {
    console.log("CompanyIntroduction.rendered", props);

    if ( props.parentPayload?.id ) {

        const childItems = props.payload?.filter((item) => item.parentId === props.parentPayload?.id);

        const siteSettingsLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItems?.[0]?.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });
        const aboutUsLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItems?.[1]?.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });

        return (
            <div
                className={`${props.className} col-span-2`}
            >   
                <div className="mr-4 flex flex-col space-y-7 lg:space-y-7.5">
                    
                    <RMDRLink
                        title={siteSettingsLanguageVersion?.name}
                        hasNavigationDestination={true}
                        navigationURL={`/${props.webApplicationLanguageISOCode}`}
                        redirectType={ERedirectType.STAY_CURRENT_PAGE}
                        className="inline-flex focus:outline-none"
                    >
                        <RMDRImage
                            resourceType={EResourceType.LOGO_DEFAULT} 
                            resources={childItems?.[0]?.resources} 
                            alt={siteSettingsLanguageVersion?.name} 
                            title={siteSettingsLanguageVersion?.name} 
                            sizeType={ESizeType.X06}
                            className="max-w-52"
                        />
                    </RMDRLink>

                    <p className="text-sm font-normal text-[#1D1E1F] leading-6 max-w-[334px]" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(aboutUsLanguageVersion?.value)}} />

                    <SocialMedia01
                        payload={childItems?.[0]?.languageVersions}
                        className="text-xs lg:text-sm text-body flex items-center gap-x-3 lg:gap-x-3.5"
                    />

                </div>
            </div>
        );  

    }

    return <CompanyIntroductionLoader />
     
}

export default CompanyIntroduction;
