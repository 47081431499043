import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import CoreDefaults from "core/Defaults";
import { isEmpty } from "lodash";
// components
import RMDRLink from "components/RMDRLink";
import RenderText from "components/RenderText";
import ContainerV2 from "components/ContainerV2";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
// actions
import { TMainPageLayout } from "../type";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
// style
import { IoCall } from 'react-icons/io5';
// utilities
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";


function mapStateToProps ( state: any ) {
    console.log("MNPG004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG004.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function MMNPG004Loader () {

    return (
        <></>
    )

}

function MNPG004 ( props:TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG004.rendered", props)

    const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.MAIN_PAGE_ITEM },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const phone = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.childsPayload?.[0]?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "phone" }
        ],
        type: "find"
    });

    const parallax = ApplicationDocumentSelector<IApplicationDocument>({
        resources: props.parentPayload.resources,
        resourceType: EResourceType.PARALLAX,
        type: "find"
    });

    const child01LanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.childsPayload?.[1]?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const child01RediretURL = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.childsPayload?.[1]?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
        ],
        type: "find"
    });

    return (
        <ContainerV2>
            <div className="mx-auto text-white text-center bg-no-repeat bg-cover bg-center rounded-xl mb-12 md:mb-14 xl:mb-16 pb-0.5 md:pb-0 lg:pb-1 xl:pb-0 md:-mt-2.5" style={{ backgroundImage: `url(${CoreDefaults.cdn}/${parallax?.filePath})` }} >
                <p className='pt-10 inline-block text-lg'><IoCall className="float-left mr-2" />{phone?.value}</p>
                <p className='text-5xl font-bold'>{parentLanguageVersion?.name}</p>
                <RenderText html={parentLanguageVersion?.value} />
                {
                    !isEmpty(child01LanguageVersion) && child01LanguageVersion &&
                        (
                            <p className='pb-10 inline-block'>
                                <RMDRLink
                                    title={child01LanguageVersion?.name?.toUpperCase()}
                                    text={child01LanguageVersion?.name?.toUpperCase()}
                                    hasNavigationDestination={props.childsPayload[1].hasDestinationNavigation}
                                    navigationURL={child01LanguageVersion.contentRouteNavigationURL}
                                    redirectType={props.childsPayload[1].redirectType}
                                    redirectUrl={child01RediretURL?.value}
                                    className="flex items-center justify-center gap-2 px-3.5 xl:px-4 text-m relative before:absolute before:-bottom-2.5 before:h-2.5 before:w-full before:z-10 font-semibold text-white hover:text-white transition-colors rounded-md cursor-pointer h-11 bg-floorsAndExtras-600 hover:bg-floorsAndExtras-700"
                                />
                            </p>
                        )
                }
            </div>
        </ContainerV2>
    )

}

const Component = React.memo(MNPG004, ( prevProps: TMainPageLayout<PropsFromRedux>, nextProps: TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG004.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
