import { isEmpty } from 'lodash';
import ContainerV2 from 'components/ContainerV2';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { TFooterComponent } from '../type';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import { EView } from 'cms/enums/EView';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';


function BottomBarLoader () {

    return (
        <></>
    )

}

function BottomBar ( props:TFooterComponent ) {
    console.log("BottomBar.rendered", props)

    const parentItem = props.payload?.find((item) => item.layouts?.filter((litem) => litem.layoutName === props.layoutName).length > 0);

    if ( parentItem && !isEmpty(parentItem) ) {

        const childItems = props.payload?.filter((item) => item.parentId === parentItem.id);

        const copyrightString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItems?.[0]?.languageVersions,
            filters: [
                { view: EView.META_DATA, name: "copyrightString" }
            ],
            type: "find"
        });
        const scrollToTopString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItems?.[0]?.languageVersions,
            filters: [
                { view: EView.META_DATA, name: "scrollToTopString" }
            ],
            type: "find"
        });

        return (
            <div className="pt-5 pb-16 mb-2 border-t border-gray-300 sm:pb-20 md:pb-5 sm:mb-0">
                <ContainerV2
                    className="grid grid-cols-1 md:grid-cols-3"
                >
                    <p className="inline-flex items-end justify-center md:justify-start p-0 m-0 pb-4 md:pb-0" >
                        <span className='ck-content text-body text-xs lg:text-sm leading-6' dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(copyrightString?.value) }} />
                    </p>   
                    <p className="inline-flex items-center justify-center  p-0 m-0" >
                        <a href="https://www.armadorteknoloji.com" title='Armador Yazılım ve Teknoloji A.Ş.' className='inline-flex flex-col items-center justify-center'>
                            <img src="https://cdn.armadorteknoloji.com/armador-software-and-technology-inc.webp" alt="Armador Yazılım ve Teknoloji A.Ş" className='h-8' />
                            <span className='ck-content text-body text-xs lg:text-sm leading-6'>Powered By Armador Yazılım ve Teknoloji A.Ş</span>
                        </a>
                    </p>
                    {/* <ul className="flex-wrap items-center justify-center hidden mx-auto mb-1 md:flex gap-x-4 xs:gap-x-5 lg:gap-x-7 md:mb-0 md:mx-0"></ul> */}
                    <p className="inline-flex items-end justify-center md:justify-end text-sm font-semibold leading-[19px] text-[#212121] cursor-pointer py-4">
                        <span onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); }} >{scrollToTopString?.value}</span>
                        <AiOutlineArrowUp className="inline ml-3" />
                    </p>

                </ContainerV2>
            </div>
        );

    }

    return <BottomBarLoader />;
    
    
}

export default BottomBar;
