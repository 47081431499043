import _ClientStoresServiceAction from "ecommerce/actions/services/ClientStoresServiceAction";


const screenCode = "400";
class ClientStoresServiceAction extends _ClientStoresServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ClientStoresServiceAction;
