import { combineReducers } from 'redux';

// reducers
import ClientProductsServiceStore from "ecommerce/reducers/services/ClientProductsServiceStore";
import StockStatusServiceStore from "ecommerce/reducers/services/enum/StockStatusServiceStore";


const screenCode = "405";
const MNPG007Store = combineReducers({
 
    ClientProductsStore: ClientProductsServiceStore({ screenCode }),
    StockStatusStore: StockStatusServiceStore({ screenCode }),

});

export default MNPG007Store;
