import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Lodash from "lodash";
// actions
import CoreAction from "core/actions/common/CoreAction";
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
import ContactUsServiceAction from "cms/actions/components/InnerLayouts/INNR003/ContactUsServiceAction";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import IContactUsPost from "cms/interfaces/IContactUsPost";
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import Container from "components/Container";
import ContactForm from "components/ContactForm";
// import Subscription from "components/Subscription";
import ContactInfoBlock from "components/ContactInfoBlock";
import RMDRGoogleMaps from "components/RMDRGoogleMaps";
import ContentHeaderRow from "components/ContentHeaderRow";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { TINNRLayout } from "../type";
import SEO from "components/SEO";


function mapStateToProps ( state: any ) {
    console.log("INNR003.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const contactUsPostPayload:IContactUsPost           = state.INNR003Store.ContactUsStore.post?.payload;
    const contactUsPostListError:TValidations[]|null    = state.INNR003Store.ContactUsStore.post.error?.value;
    const contactUsPostListIsLoading:boolean            = state.INNR003Store.ContactUsStore.post?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    const errors:TValidations[]|null|undefined  = Lodash.reject( [contactUsPostListError], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                     = [contactUsPostListIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        contactUsPostPayload,
        siteSettingDetailPayload,

        errors,
        isLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("INNR003.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const messagePublisherAction = new MessagePublisherAction(dispatch);
    const contactUsServiceAction = new ContactUsServiceAction(dispatch);

    return {
        // list
        // post
        _post: ( payload:any, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: payload, language: webApplicationLanguageCode } })
        },
        
        // details
        // others
        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        },

        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message)
        },

        _clean: () => {
            contactUsServiceAction.postClean();
        }
    }
}

const connector = connect ( mapStateToProps, mapDispatchToProps );


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function INNR003Loader () {

    return (
        <></>
    )

}

function INNR003 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR003.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const getInTouchString = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "getInTouchString" }
        ],
        type: "find"
    });

    const findUsHereString = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "findUsHereString" }
        ],
        type: "find"
    });
    
    useEffect(
        () => {

            return function cleanup() {
                props._clean();
                props._showLoader();
            }

        }, [ ]
    )

    useEffect(
        () => {
            
            setTimeout(() => { props._hideLoader(); }, 100);

        }, [ props.contentDetailPayload ]
    )

    return (
        <>
            <SEO contentPayload={props.contentDetailPayload} siteSettingPayload={props.siteSettingDetailPayload} type="content-page" />

            <ContentHeaderRow
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload?.resources} 
                resourceType={EResourceType.PARALLAX} 
            />

            <Container className='mb-10'>
                <div className="my-14 lg:my-16 xl:my-20 px-0 pb-2 lg: xl:max-w-screen-xl mx-auto flex flex-col md:flex-row w-full">
                    <div className="md:w-full lg:w-2/5 2xl:w-2/6 flex flex-col h-full">
                        <ContactInfoBlock languageVersions={props.contentDetailPayload?.languageVersions} title={findUsHereString?.value} className="mb-6 lg:border lg:rounded-md border-gray-300 lg:p-7 !mt-0" />
                    </div>

                    <div className="md:w-full lg:w-3/5 2xl:w-4/6 flex h-full md:ml-7 flex-col lg:pl-7">
                        <div className="flex pb-7 md:pb-9 mt-7 md:-mt-1.5">
                            <h4 className="text-2xl 2xl:text-3xl font-bold text-heading">
                                {getInTouchString?.value}
                            </h4>
                        </div>

                        <ContactForm languageVersions={props.contentDetailPayload?.languageVersions} />
                    </div>
                    
                </div>
            </Container>
            
            <RMDRGoogleMaps languageVersions={props.contentDetailPayload?.languageVersions} />
            
            {/* <Container className='my-10'>
                <Subscription />
            </Container> */}
        </>
    )
}

const Component = React.memo(INNR003, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR003.memo", { prevProps, nextProps });
    return !( prevProps.isLoading !== nextProps.isLoading );
});

export default connector(Component);

