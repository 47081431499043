import { combineReducers } from 'redux';
// reducers
import ClientCatalogCategoriesServiceStore from "ecommerce/reducers/services/ClientCatalogCategoriesServiceStore";


const screenCode = "401";

const CategoryFilterStore = combineReducers({

    ClientCatalogCategoriesStore: ClientCatalogCategoriesServiceStore({ screenCode }),
    
});

export default CategoryFilterStore;
