
import Text from 'components/Text'
import { FaLink } from 'react-icons/fa';
import cn from 'classnames';
import RMDRLink from 'components/RMDRLink';
import RMDRImage from 'components/RMDRImage';
import { EResourceType } from 'cms/enums/EResourceType';
import IResource from 'cms/interfaces/IResource';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import ERedirectType from 'cms/enums/ERedirectType';


type TIconCard = {

    languageVersion?:ILanguageVersionList|ILanguageVersionDetail|null;
    resources?: IResource[];
    navigationURL?: string|null;
    redirectUrl?: string|null;
    hasDestinationNavigation?: boolean;
    redirectType?: ERedirectType;
    effectActive?: boolean;
    variant?: 'default' | 'modern' | 'circle' | 'list';

    onClick?:(event:any) => void;
}

function IconCardLoader ( props: TIconCard ) {

    return (
        <div 
            className={
                cn(
                    'rounded-lg h-80 bg-gray-100', 
                    {
                        'flex-col h-28 sm:h-[8.5rem] md:h-40 xl:h-[11.5rem] 2xl:h-44 3xl:h-60 bg-gray-200': props.variant === 'default',
                        'flex-col px-6 lg:px-8 pt-7 lg:pt-10 pb-5 lg:pb-8 bg-gray-200': props.variant === 'modern',
                        'flex-col items-center': props.variant === 'circle',
                        'gap-[25px] xl:gap-[30px] 2xl:gap-[40px] 3xl:gap-[50px] px-3 lg:px-4 py-5 lg:py-8 bg-gray-200': props.variant === 'list',
                    }
                )
            }
         />
    )

}

function IconCard ( props: TIconCard ) {
    console.log("IconCard.rendered: ", props);

    return (
        <RMDRLink
            title={props.languageVersion?.name}
            hasNavigationDestination={props.hasDestinationNavigation}
            navigationURL={props.navigationURL||props.languageVersion?.contentRouteNavigationURL}
            redirectType={props.redirectType}
            redirectUrl={props.redirectUrl}
            className={
                cn(
                    'group flex justify-center  rounded-lg', 
                    {
                        'flex-col h-28 sm:h-[8.5rem] md:h-40 xl:h-[11.5rem] 2xl:h-44 3xl:h-60 bg-gray-200': props.variant === 'default',
                        'flex-col px-6 lg:px-8 pt-7 lg:pt-10 pb-5 lg:pb-8 bg-gray-200': props.variant === 'modern',
                        'flex-col items-center': props.variant === 'circle',
                        'gap-[25px] xl:gap-[30px] 2xl:gap-[40px] 3xl:gap-[50px] px-3 lg:px-4 py-5 lg:py-8 bg-gray-200': props.variant === 'list',
                    }
                )
            }
            onClick={props.onClick}
        >
            <div
                className={
                    cn(
                        'relative flex items-center', 
                        {
                            'mb-3.5 md:mb-4 lg:mb-5 xl:mb-2 2xl:mb-6 3xl:mb-8 lg:h-24 mx-auto': props.variant === 'default',
                            'mr-auto h-16': props.variant === 'modern',
                            'bg-gray-200 justify-center rounded-full mb-3.5 md:mb-4 lg:mb-5 w-[105px] md:w-32 lg:w-[140px] xl:w-44 h-[105px] md:h-32 lg:h-[140px] xl:h-44 max-w-full': props.variant === 'circle',
                            'flex-shrink-0': props.variant === 'list',
                        }
                    )
                }
            >
                <RMDRImage
                    resourceType={EResourceType.OUTER_IMAGE} 
                    resources={props.resources} 
                    alt={props.languageVersion?.name} 
                    title={props.languageVersion?.name} 
                    className={
                        cn(
                            'mb-0', 
                            {
                                'mx-auto mb-4 sm:mb-6 w-2/4 sm:w-2/3 md:w-8/12 3xl:w-full': props.variant === 'default',
                                'mb-4 sm:mb-6 w-2/4': props.variant === 'modern',
                                'transform scale-[0.6] lg:scale-75 2xl:scale-85 3xl:scale-90': props.variant === 'circle',
                                'mx-auto w-[40%] lg:w-2/4': props.variant === 'list',
                            }
                        )
                    }
                />
                {
                    props.effectActive === true && props.variant === 'circle' && (
                        <>
                            <div className="absolute top-0 left-0 bg-black w-full h-full opacity-0 transition-opacity duration-300 group-hover:opacity-30 rounded-full" />
                            <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center rounded-full">
                                <FaLink className="text-white text-base sm:text-xl lg:text-2xl xl:text-3xl transform opacity-0 scale-0 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:scale-100" />
                            </div>
                        </>
                    )
                }
            </div>

            <div
                className={
                    cn(
                        'flex flex-col', 
                        {
                            'overflow-hidden gap-y-2 mx-auto': props.variant === 'list',
                        }
                    )
                }
            >
                <Text
                    variant="heading"
                    className={
                        cn(
                            'capitalize', 
                            {
                                'absolute text-center bottom-2 sm:bottom-2.5 md:bottom-3 xl:bottom-4 inset-x-0 h-10 sm:h-14 line-clamp-2': props.variant === 'default',
                                'mb-1': props.variant === 'modern',
                            }
                        )
                    }
                >
                    {props.languageVersion?.name}
                    {/* {
                        description &&
                        <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate px-5 ">
                            {description}
                        </p>
                    } */}
                </Text>

                {/* {(props.variant === 'modern' || props.variant === 'list') && (
                    <Text
                        className={cn('pb-0.5 truncate', {
                            '': props.variant === 'list',
                        })}
                    >
                        {`${tags?.length} ${t['text-brands']}, ${productCount}+ ${t['text-products']}`}
                    </Text>
                )} */}
            </div>

            {
                props.effectActive === true && props.variant !== 'circle' && (
                    <>
                        <div className="absolute top-0 left-0 bg-black w-full h-full opacity-0 transition-opacity duration-300 group-hover:opacity-30 rounded-lg" />
                        <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center  rounded-lg">
                            <FaLink className="text-white text-base sm:text-xl lg:text-2xl xl:text-3xl transform opacity-0 scale-0 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:scale-100" />
                        </div>
                    </>
                )
            }
        </RMDRLink>
    );
};

IconCard.deaultProps = {

    effectActive: false,
    variant: 'default',

}

export default IconCard;
export {
    IconCardLoader
}
