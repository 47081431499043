import React, { useEffect, useState } from "react";
// componenet
import PaginationItem from "./PaginationItem";
// utilities
import calculatePageNumbers from "cms/utilities/CalculatePageNumber";
import { useSearchParams } from "react-router-dom";


type TPagination = {

    currentPage?: number;
    pageSize: number;
    className?: string;

    onClick?: (event:React.MouseEvent<HTMLDivElement, MouseEvent>, value:number) => void;

    disabled: boolean;

}


function Pagination ( props:TPagination ) {

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ currentPage, setCurrentPage ] = useState<number>(0);

    useEffect(
        () => {

            if ( !props.currentPage ) {
                let _currentPage = parseInt(searchParams.get("cp")?.trim()||"1");
                if ( isNaN(_currentPage) ) _currentPage = 1;
                if ( _currentPage !== currentPage ) setCurrentPage(_currentPage);
            }

        }, [ searchParams ]
    )

    useEffect(
        () => {

            if ( currentPage > 0 ) {
                searchParams.set("cp", currentPage.toString() );
                setSearchParams(searchParams, { replace:true });
            }

        }, [ currentPage ]
    )
    
    
    function onClick(event:React.MouseEvent<HTMLDivElement, MouseEvent>, value:number) {

        if ( props.onClick ) props.onClick(event, value);
        else setCurrentPage(value);

    }

    if ( currentPage > 0 || (props.currentPage && props.currentPage > 0) ) {

        const [first_page_numbers, middle_page_numbers, last_page_numbers] = calculatePageNumbers( currentPage, props.pageSize );
        return (
            <>
                <div className={`flex flex-row gap-2 justify-center ${props.className}`} data-wow-delay=".3s">
                    { 
                        first_page_numbers?.length > 0 && first_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }
                    { 
                        first_page_numbers?.length > 0 && 
                            <span className="px-5">...</span>
                    }
                    
                    { 
                        middle_page_numbers?.length > 0 && middle_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }

                    { 
                        last_page_numbers?.length > 0 &&  
                            <span className="px-5">...</span> 
                    }

                    { 
                        last_page_numbers?.length > 0 && last_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }
                </div>
                
                {/* <div className={`lg:hidden flex flex-row gap-2 justify-center pt-8 xl:pt-14 ${props.className}`} data-wow-delay=".3s">
                    { 
                        first_page_numbers?.length > 0 && first_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }
                    { 
                        first_page_numbers?.length > 0 && 
                            <span className="px-5">...</span>
                    }
                    
                    { 
                        middle_page_numbers?.length > 0 && middle_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }

                    { 
                        last_page_numbers?.length > 0 &&  
                            <span className="px-5">...</span> 
                    }

                    { 
                        last_page_numbers?.length > 0 && last_page_numbers.map(
                            (item, index) => <PaginationItem 
                                                currentPage={props.currentPage || currentPage} 
                                                value={item} 
                                                disabled={props.disabled} 
                                                onClick={onClick} 
                                                className="text-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer" 
                                                activeClassName="bg-floorsAndExtras-600 text-white"
                                            />
                        )
                    }
                </div> */}
            </>
        );

    }

    return null;

}

const Component = React.memo(Pagination, ( prevProps:TPagination, nextProps:TPagination ) => {
    console.log("Pagination.memo", { prevProps, nextProps });
    return !(
        prevProps.currentPage !== nextProps.currentPage
        || prevProps.pageSize !== nextProps.pageSize
        || prevProps.disabled !== nextProps.disabled
    )
});

export default Component;
