// types
import { TSocialMedia } from "../type";
import {
    IoLogoInstagram,
    IoLogoFacebook,
    IoLogoYoutube,
    IoLogoLinkedin,
} from 'react-icons/io5';
import {
    FaXTwitter
} from 'react-icons/fa6'


function SocialMedia01 ( props:TSocialMedia ) {
    console.log("SocialMedia01: ", props);

    const facebook = props?.payload?.find((item) => item.name === "facebook");
    const x = props?.payload?.find((item) => item.name === "x");
    const instagram = props?.payload?.find((item) => item.name === "instagram");
    const linkedin = props?.payload?.find((item) => item.name === "linkedin");
    const youtube = props?.payload?.find((item) => item.name === "youtube");
    
    if ( props?.payload ) {
        return (
            <ul className={props.className} style={props.style}>
                { facebook && <li className="flex items-baseline"><a rel="noreferrer" href={facebook.value||"#"} title="Facebook" data-tooltip="Facebook" aria-label="Facebook" target="_blank" className={`mr-3 relative text-lg lg:text-xl ${props.itemClassName}`}><IoLogoFacebook /></a></li>}
                { x && <li className="flex items-baseline"><a rel="noreferrer" href={x.value||"#"} title="X" data-tooltip="X" aria-label="X" target="_blank" className={`mr-3 relative text-lg lg:text-xl ${props.itemClassName}`}><FaXTwitter /></a></li>}
                { linkedin && <li className="flex items-baseline"><a rel="noreferrer" href={linkedin.value||"#"} title="LinkedIn" data-tooltip="LinkedIn" aria-label="LinkedIn" target="_blank" className={`mr-3 relative text-lg lg:text-xl ${props.itemClassName}`}><IoLogoLinkedin /></a></li>}
                { instagram && <li className="flex items-baseline"><a rel="noreferrer" href={instagram.value||"#"} title="Instagram" data-tooltip="Instagram" aria-label="Instagram" target="_blank" className={`mr-3 relative text-lg lg:text-xl ${props.itemClassName}`}><IoLogoInstagram /></a></li>}
                { youtube && <li className="flex items-baseline"><a rel="noreferrer" href={youtube.value||"#"} title="Youtube" data-tooltip="Youtube" aria-label="Youtube" target="_blank" className={`mr-3 relative text-lg lg:text-xl ${props.itemClassName}`}><IoLogoYoutube /></a></li>}
            </ul>
        );
    }
    
    return (
        <ul className={`animate-pulse ${props.className}`} style={props.style}>
            <li className="flex items-baseline"><span className="relative text-sm lg:text-xl rounded-full bg-gray-100 h-8 w-8"></span></li>
            <li className="flex items-baseline"><span className="relative text-sm lg:text-xl rounded-full bg-gray-100 h-8 w-8"></span></li>
            <li className="flex items-baseline"><span className="relative text-sm lg:text-xl rounded-full bg-gray-100 h-8 w-8"></span></li>
            <li className="flex items-baseline"><span className="relative text-sm lg:text-xl rounded-full bg-gray-100 h-8 w-8"></span></li>
        </ul>
    );

}

export default SocialMedia01;
