import React from 'react';
import Moment from "moment";
// types
import { EResourceType } from 'cms/enums/EResourceType';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import RMDRImage from './RMDRImage';
import IClientContentDetail from "cms/interfaces/IClientContentDetail";
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';


type TContentInnerHeader = {

    payload?: IClientContentDetail;
    metaInformationEnabled?: boolean;

}

function ContentInnerHeader(props: TContentInnerHeader) {
    
    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const datetime = "";
    // const datetime = Moment(props.payload?.creationDateTime).format('MM-DD-YYYY HH:mm'); 
    // TODO: önceden modelin içinde "creationDateTime" geliyordu şuan gelmiyor. 
    //       Sayfada gösterilmek isteniyorse gelmesi gerekiyor

    return (
        <>
            <div className="blog-thumb ">
                <RMDRImage
                    resourceType={EResourceType.INNER_IMAGE} 
                    resources={props.payload?.resources} 
                    alt={languageVersion?.name} 
                    title={languageVersion?.name} 
                    style={{ width: "100%", height: "auto" }} 
                    width={500} height={500} 
                />
            </div>
            
            {
                props.metaInformationEnabled &&
                    <div className="blog-meta">
                        <div className="blog-date">
                            <i className="fa-solid fa-calendar-days"></i>
                            <span>{datetime}</span>
                        </div>
                        {/* <div className="blog-user">
                            <i className="fa-regular fa-user"></i>
                            <span>{item?.author}</span>
                        </div> */}
                        {/* <div className="blog-comrent">
                            <i className="fal fa-comments"></i>
                            <span>
                                {item?.comment > 1
                                    ? `${item?.comment} comments`
                                    : `${item?.comment} comment`}
                            </span>
                        </div> */}
                    </div>
            }
        </>
    )
}

export default ContentInnerHeader;
export type {
    TContentInnerHeader
};
