import { useRef, useEffect } from 'react';
import { Portal } from '@reach/portal';
import { motion, AnimatePresence } from 'framer-motion';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import cn from 'classnames';
import { IoClose } from 'react-icons/io5';
import fadeInOut from 'cms/utilities/motion/FadeInOut';
import zoomOutIn from 'cms/utilities/motion/zoom-out-in';
import useOnClickOutside from 'cms/utilities/UseClickOutside';


type ModalProps = {
    open?: boolean;
    onClose: () => void;
    rootClassName?: string;
    useBlurBackdrop?: boolean;
    containerClassName?: string;
    variant?: 'center' | 'bottom';
};
type DivElementRef = React.MutableRefObject<HTMLDivElement>;
type Props = React.PropsWithChildren<ModalProps>;


// variant based classes for modal root, container & close btn
const rootClasses = {
    center: 'p-4 md:p-5',
    bottom: 'p-5 pb-0',
};

const containerClasses = {
    center: 'h-auto max-h-full top-1/2 -translate-y-1/2 rounded-lg',
    bottom: 'h-full max-h-70vh bottom-0 rounded-tr-2xl rounded-tl-2xl',
};

const closeBtnClasses = {
    center: '-top-3 md:-top-4 -right-3 md:-right-4',
    bottom: 'top-1/4 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
};

function Modal ( props: Props ) {

    const modalRootRef = useRef() as DivElementRef;
    const modalInnerRef = useRef() as DivElementRef;

    useOnClickOutside(modalInnerRef, () => props.onClose());

    useEffect(() => {
        if (modalInnerRef.current) {
            if (props.open) {
                disableBodyScroll(modalInnerRef.current);
            } else {
                enableBodyScroll(modalInnerRef.current);
            }
        }
        return () => {
            clearAllBodyScrollLocks();
        };
    }, [props.open]);

    return (
        <Portal>
            <AnimatePresence>
                {
                    props.open && 
                        (
                            <motion.div
                                ref={modalRootRef}
                                key="modal"
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={fadeInOut(0.25)}
                                className={
                                    cn(
                                        'modal-root fixed bg-black bg-opacity-70 inset-0 z-50 cursor-pointer',
                                        props.useBlurBackdrop && 'backdrop-filter backdrop-blur-sm',
                                        rootClasses[props.variant as keyof typeof rootClasses],
                                        props.rootClassName
                                    )
                                }
                            >
                                <motion.div
                                    initial="from"
                                    animate="to"
                                    exit="from"
                                    variants={zoomOutIn()}
                                    className="relative w-full h-full mx-auto"
                                >
                                    <div
                                        className={
                                            cn(
                                                'w-full md:w-auto absolute left-1/2 transform -translate-x-1/2 shadow-xl',
                                                containerClasses[props.variant as keyof typeof containerClasses],
                                                props.containerClassName
                                            )
                                        }
                                    >
                                        <button
                                            onClick={props.onClose}
                                            aria-label="Close panel"
                                            className={
                                                cn(
                                                    'fixed z-10 inline-flex items-center justify-center w-7 h-7 md:w-8 md:h-8 rounded-full bg-white shadow text-gray-600 transition duration-200 focus:outline-none focus:text-gray-800 focus:shadow-md hover:text-gray-800 hover:shadow-md',
                                                    closeBtnClasses[props.variant as keyof typeof closeBtnClasses]
                                                )
                                            }
                                        >
                                            <IoClose className="text-xl" />
                                        </button>
                                        <div
                                            ref={modalInnerRef}
                                            className="h-full overflow-y-auto rounded-lg"
                                            style={{ maxHeight: 'calc(100vh - 120px)' }}
                                        >
                                            {props.children}
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        )
                }
            </AnimatePresence>
        </Portal>
    );
}

Modal.defaultProps = {

    variant: 'center'

}

export default Modal;
