import React, { useEffect } from "react";
import Lodash from "lodash";
import { connect, ConnectedProps } from "react-redux";
// actions
import CoreAction from "core/actions/common/CoreAction";
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { TINNRLayout } from "../type";
import RMDRPDFViever from "components/RMDRPDFViever";
import ContentHeaderRow from "components/ContentHeaderRow";
import { EResourceType } from "cms/enums/EResourceType";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import SEO from "components/SEO";


function mapStateToProps ( state: any ) {
    console.log("INNR005.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    const errors:TValidations[]|null|undefined      = Lodash.reject( [], Lodash.isEmpty )[0] || [];
    const isLoading:boolean                         = [false].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        errors,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("INNR005.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {
        // others
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        },

        _clean: () => {

        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function INNR005Loader () {

    return (
        <></>
    )

}

function INNR005 ( props:TINNRLayout<PropsFromRedux> ) {
    console.log("INNR005.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

            return function cleanup() {
                props._showLoader();
            }

        }, [ ]
    )

    return (
        <>
            <SEO contentPayload={props.contentDetailPayload} siteSettingPayload={props.siteSettingDetailPayload} type="content-page" />

            <ContentHeaderRow
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload?.resources} 
                resourceType={EResourceType.PARALLAX} 
            />

            <div className="py-5 h-full">
                <RMDRPDFViever width="100%" resources={props.contentDetailPayload.resources} resourceType={EResourceType.DATA} className="h-[75vh] md:h-[100vh]" />
            </div>
        </>
    )
    
}

const Component = React.memo(INNR005, ( prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR005.memo", { prevProps, nextProps });
    return !(
        prevProps.isLoading !== nextProps.isLoading 
    );
});

export default connector(Component);
