import ActionCore from 'core/actions/ActionCore';


const screenCode = "ECOM";
class CMSAction extends ActionCore {

    constructor( dispatch:any ) {
        super( dispatch, "", screenCode);

        this.serviceCode = "Core";
    }

    // product details popup
    openProductDetailPopup ( payload:any ) {
        this.store("PDP", payload);
        this.fetching("PDP");
    }

    closeProductDetailPopup () {
        this.clean("PDP");
        this.store("PDP", {});
    }

    

}

export default CMSAction;
