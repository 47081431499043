
// interfaces
import RMDRImage from "components/RMDRImage";
import RMDRLink from "components/RMDRLink";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import IClientMainPageItemList from "cms/interfaces/IClientMainPageItemList";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";


type TBrandCard = {

    payload:IClientMainPageItemList,
    index: number;

}

function BrandCard ( props:TBrandCard ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
        ],
        type: "find"
    });

    function calcBGColor(index:number) {

        if ( Math.floor(props.index/6)%2 === 0 ) {
            return props.index%2===0?"bg-[#fcf5ef]":"bg-[#f9f9f9]";
        } else {
            return props.index%2===0?"bg-[#f9f9f9]":"bg-[#fcf5ef]";
        }

    }

    return (
        <RMDRLink
            title={languageVersion?.name}
            hasNavigationDestination={props.payload?.hasDestinationNavigation}
            navigationURL={languageVersion?.contentRouteNavigationURL}
            redirectType={props.payload?.redirectType}
            redirectUrl={redirectUrl?.value}
            isShowAlways
            className={`group flex justify-center text-center relative overflow-hidden rounded-md ${calcBGColor(props.index)}`}
            variant="div"
        >
            <span className="box-border inline-block overflow-hidden w-[initial] h-[initial] bg-none opacity-100 border-0 m-0 p-0 relative max-w-full">
                <span className="box-border block w-[initial] h-[initial] bg-none opacity-100 b-0 m-0 p-0 max-w-full">
                    <img aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27428%27%20height=%27428%27/%3e" className="block max-w-full w-[initial] h-[initial] bg-none opacity-100 b-0 m-0 p-0" />
                </span>
            </span>

            <div className="absolute top left bg-black w-full h-full opacity-0 transition-opacity duration-500 group-hover:opacity-30" />
            <div className="absolute top left h-full w-full flex items-center justify-center p-8">
                <RMDRImage
                    resourceType={EResourceType.OUTER_IMAGE} 
                    resources={props.payload?.resources} 
                    alt={languageVersion?.name}
                    title={languageVersion?.name}
                    className="flex-shrink-0"
                />
            </div>
        </RMDRLink>
    );

};

export default BrandCard;
