import _ContentMetricsServiceAction from "core/actions/services/metrics/ContentMetricsServiceAction";


const screenCode = "213";
class ContentMetricsServiceAction extends _ContentMetricsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ContentMetricsServiceAction;
