import _ClientCatalogCategoriesServiceAction from "ecommerce/actions/services/ClientCatalogCategoriesServiceAction";


const screenCode = "COM";
class ClientCatalogCategoriesServiceAction extends _ClientCatalogCategoriesServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);

        this.serviceCode = "CCC";
    }

}

export default ClientCatalogCategoriesServiceAction;
