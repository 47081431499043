import React, { JSXElementConstructor, CSSProperties } from 'react';
import cn from 'classnames';
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';

interface Props {
    variant?: Variant;
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode | any;
    html?: string;
}

type Variant = 'mediumHeading' | 'heading' | 'body' | 'pageHeading' | 'subHeading';

function Text(props: Props) {

    const componentsMap: {
        [P in Variant]: React.ComponentType<any> | string;
    } = {
        body: 'p',
        mediumHeading: 'h3',
        heading: 'h4',
        pageHeading: 'h1',
        subHeading: 'h2',
    };

    const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = componentsMap![props.variant!];

    const htmlContentProps = props.html
        ? {
            dangerouslySetInnerHTML: { __html: SanitizeHTMLString(props.html) },
        }
        : {};

    return (
        <Component
            className={cn(
                {
                    'text-sm sm:leading-6 leading-7': props.variant === 'body',
                    'text-body': props.variant === 'body',
                    'text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold': props.variant === 'mediumHeading',
                    'text-heading': props.variant === 'mediumHeading' || props.variant === 'heading' || props.variant === 'pageHeading' || props.variant === 'subHeading',
                    'text-sm md:text-base xl:text-lg font-semibold': props.variant === 'heading',
                    'text-2xl font-bold': props.variant === 'pageHeading',
                    'text-lg md:text-2xl xl:text-3xl 2xl:text-4xl  font-bold': props.variant === 'subHeading',
                },
                props.className
            )}
            style={props.style}
            {...htmlContentProps}
        >
            {props.children}
        </Component>
    );
};

Text.defaultProps = {

    variant: 'body'

}

export default Text;
