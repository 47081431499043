import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
// components
import MegaMenu from './MegaMenu';
import ListMenu from './ListMenu';
import RMDRNavLink from "../../RMDRNavLink";
// types
import TValidations from "cms/types/TValidations";
import TMenu from "cms/types/TMenu";
// actions
import ClientMenuItemServiceAction from "cms/actions/components/Header/ClientMenuItemServiceAction";
import ContentMetricsServiceAction from "cms/actions/components/Header/ContentMetricsServiceAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientMenuItemList from 'cms/interfaces/IClientMenuItemList';
// enums
import { EContentStatus } from "cms/enums/EContentStatus";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
// utilities
import MenuComposer from "cms/utilities/MenuComposer";
// style
import { FaChevronDown } from 'react-icons/fa';
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import { EResourceType } from "cms/enums/EResourceType";
import CoreDefault from "core/Defaults";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
import { EContentMetricsType } from "core/enums/EContentMetricsType";


function mapStateToProps ( state: any ) {
    console.log("HeaderMenu.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const clientMenuItemGet:IClientMenuItemList[]|null              = state.HeaderStore.ClientMenuItemStore.get?.["MNGRP001"];
    const clientMenuItemGetError:TValidations[]|null                = state.HeaderStore.ClientMenuItemStore.get?.error;
    const clientMenuItemGetIsLoading:boolean                        = state.HeaderStore.ClientMenuItemStore.get?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        clientMenuItemGet,
        clientMenuItemGetError,
        clientMenuItemGetIsLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("HeaderMenu.mapDispatchToProps");

    const clientMenuItemServiceAction = new ClientMenuItemServiceAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {
        
        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

        _getClientMenuItems: ( webApplicationLanguageCode:string, mapLayoutName:string, destinationName:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`mapLayoutName eq '${mapLayoutName}'`, "and", `status eq ${EContentStatus.ACTIVE}`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMenuItemServiceAction.get({ 
                servicePayload: { queryOptions, language: webApplicationLanguageCode },
                args: { storeMapOptions: [{ destinationName, sourceName: "value" }]  } 
            });
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TMenuProps<T> = {

} & T;


/* component */
function HeaderMenuLoader () {

    return (
        <></>
    )

}

function HeaderMenu ( props:TMenuProps<PropsFromRedux> ) {

    const [ navigation, setNavigation ] = useState<TMenu[]|null>(null);

    useEffect(
        () => {
            if ( !isEmpty(props.webApplicationLanguageCode)) {
                props._getClientMenuItems(props.webApplicationLanguageCode, "MNGRP_001", "MNGRP001");
            }
        }, [ props.webApplicationLanguageCode ]
    )

    useEffect(
        () => {

            if ( !isEmpty(props.clientMenuItemGet) ) {
                let parentMenuItems = props.clientMenuItemGet?.filter((item:any) => item.parentId === null);
                let navigation = MenuComposer(props.clientMenuItemGet, parentMenuItems);
                setNavigation(navigation);
            }

        }, [ props.clientMenuItemGet ]
    )


    return (
        <nav className="headerMenu flex w-full relative hidden lg:flex pl-3.5 xl:pl-5">
            {
                navigation?.map((item) => {
                    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters: [
                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                        ],
                        type: "find"
                    });
                    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters: [
                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                        ],
                        type: "find"
                    });
                    
                    let layout = item.layouts?.[0];

                    if ( layout && layout.layoutName === "MN_001" ) {
                        const applicationDocument = ApplicationDocumentSelector<IApplicationDocument>({
                            resources: item.resources,
                            resourceType: EResourceType.DATA,
                            type: "find"
                        });

                        return (
                            <div
                                className={`menuItem group cursor-pointer py-7 ${item.items?.length && item.items?.length > 0 ? 'relative' : ''}`}
                                key={item.id}
                            >
                                <RMDRNavLink
                                    text={languageVersion?.name}
                                    title={languageVersion?.name}
                                    hasNavigationDestination={item.hasDestinationNavigation}
                                    isShowAlways={true}
    
                                    navigationURL={`${CoreDefault.cdn}/${applicationDocument?.filePath}`}
    
                                    redirectType={item.redirectType}
                                    redirectUrl={redirectUrl?.value}
                                    className="relative inline-flex items-center px-3 py-2 text-sm font-normal xl:text-base text-heading xl:px-4 group-hover:text-black"
                                    onClick={(event:any) => { props._viewEvent(item.id, props.webApplicationLanguageCode) }}
                                    variant="div"
                                />
                            </div>
                        )
                    } else if ( layout && layout.layoutName === "MN_002" ) {
                        const tagId = LanguageVersionSelector<ILanguageVersionList|null>({
                            languageVersions: item.languageVersions,
                            filters: [
                                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "tagId" }
                            ],
                            type: "find"
                        });
            
                        const navigationURL = `/${props.webApplicationLanguageISOCode}/shop?t=${tagId?.value}`;

                        return (
                            <div
                                className={`menuItem group cursor-pointer py-7 ${item.items?.length && item.items?.length > 0 ? 'relative' : ''}`}
                                key={item.id}
                            >
                                <RMDRNavLink
                                    text={languageVersion?.name}
                                    title={languageVersion?.name}
                                    hasNavigationDestination={item.hasDestinationNavigation}
                                    isShowAlways={true}
    
                                    navigationURL={navigationURL}
    
                                    redirectType={item.redirectType}
                                    redirectUrl={redirectUrl?.value}
                                    className="relative inline-flex items-center px-3 py-2 text-sm font-normal xl:text-base text-heading xl:px-4 group-hover:text-black"
                                    onClick={(event:any) => { props._viewEvent(item.id, props.webApplicationLanguageCode) }}
                                    variant="div"
                                />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div
                                className={`menuItem group cursor-pointer py-7 ${item.items?.length && item.items?.length > 0 ? 'relative' : ''}`}
                                key={item.id}
                            >
                                <RMDRNavLink
                                    title={languageVersion?.name}
                                    hasNavigationDestination={item.hasDestinationNavigation}
                                    isShowAlways={true}
    
                                    navigationURL={languageVersion?.contentRouteNavigationURL}
    
                                    redirectType={item.redirectType}
                                    redirectUrl={redirectUrl?.value}
                                    className="relative inline-flex items-center px-3 py-2 text-sm font-normal xl:text-base text-heading xl:px-4 group-hover:text-black"
                                    onClick={(event:any) => { props._viewEvent(item.id, props.webApplicationLanguageCode) }}
                                    variant="div"
                                >
                                    <>
                                        {languageVersion?.name}
                                        {
                                            item.items?.length && item.items?.length > 0 && (
                                                <span className="opacity-30 text-xs mt-1 xl:mt-0.5 w-4 flex justify-end">
                                                    <FaChevronDown className="transition duration-300 ease-in-out transform group-hover:-rotate-180" />
                                                </span>
                                            )
                                        }
                                    </>
                                </RMDRNavLink>
    
                                {/* {
                                    item?.columns && Array.isArray(item.columns) && 
                                        (
                                            <MegaMenu columns={item.columns} />
                                        )
                                } */}
    
                                {/* {
                                    item?.subMenu && Array.isArray(item.subMenu) && 
                                        (
                                            <div className="absolute invisible bg-gray-200 opacity-0 group-hover:visible subMenu shadow-header left-0 group-hover:opacity-100">
                                                <ul className="py-5 text-sm text-body">
                                                    {
                                                        item.subMenu.map((menu: any, index: number) => {
                                                            const dept: number = 1;
                                                            const menuName: string = `sidebar-menu-${dept}-${index}`;
    
                                                            return (
                                                                <ListMenu
                                                                    dept={dept}
                                                                    data={menu}
                                                                    hasSubMenu={menu.subMenu}
                                                                    menuName={menuName}
                                                                    key={menuName}
                                                                    menuIndex={index}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                } */}
                            </div>
                        )
                    }

                    
                })
                
            }
        </nav>
    );

};

const Component = React.memo(HeaderMenu, ( prevProps:TMenuProps<PropsFromRedux>, nextProps:TMenuProps<PropsFromRedux> ) => {
    console.log("HeaderMenu.memo", { prevProps, nextProps });

    return !(
        prevProps.clientMenuItemGet !== nextProps.clientMenuItemGet
    );
});

export default connector(Component);
