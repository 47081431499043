import React from "react";


type CheckBoxProps = {

    label:string;
    className?: string;

} & React.InputHTMLAttributes<HTMLInputElement>;;


function _CheckBox(props: CheckBoxProps, ref: any) {

    return (
        <label className="group flex items-center text-heading text-sm cursor-pointer !my-2">
            <input
                ref={ref}
                type="checkbox"
                className={props.className}
                checked={props.checked}
                onChange={props.onChange}
                name={props.name}
                value={props.value}
                style={props.style}
            />
            <span className="ms-4 -mt-0.5">{props.label}</span>
        </label>
    );

}

const RMDRCheckBox = React.forwardRef(_CheckBox);

RMDRCheckBox.defaultProps = {

    className: "form-checkbox w-5 h-5 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-floorsAndExtras-600 checked:hover:bg-floorsAndExtras-700 checked:focus:bg-floorsAndExtras-600"

}

export default RMDRCheckBox;
