import React from 'react';
import RMDRLink from 'components/RMDRLink';
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import TMenu from 'cms/types/TMenu';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';


type TMegaMenu = {

    payload?:TMenu[];
    webApplicationLanguageISOCode:string;

    onClick?: (event:any, contentId?:string) => void;

};

function MegaMenu ( props:TMegaMenu ) {
    console.log("MegaMenu.rendered: ", props);

    return (
        <div className="absolute bg-gray-200 megaMenu shadow-header -left-28 p-4 pb-4 h-full">

            {
                props.payload?.map((item) => {
                    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters: [
                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                        ],
                        type: "find"
                    });
                    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters: [
                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
                        ],
                        type: "find"
                    });

                    const navigationURL = `/${props.webApplicationLanguageISOCode}/shop?c=${item.hierarchyId}`;

                    return (
                        <ul
                            className="w-full pb-2 last:pb-0"
                            key={item.id}
                        >
                            <li >
                                <RMDRLink
                                    title={languageVersion?.name}
                                    text={languageVersion?.name}
                                    hasNavigationDestination={item.hasDestinationNavigation}
                                    navigationURL={navigationURL}
                                    redirectUrl={redirectUrl?.value}
                                    redirectType={item.redirectType}
                                    className="block text-sm py-1.5 text-heading font-semibold hover:text-heading hover:bg-gray-300"
                                    onClick={(event:any) => { if( props.onClick ) props.onClick(event, item.id) }}
                                />
                                {/* <Link
                                    to={item.path || ""}
                                    className="block text-sm py-1.5 text-heading font-semibold hover:text-heading hover:bg-gray-300"
                                >
                                    {t[item.label as keyof typeof t]}
                                </Link> */}
                            </li>
                            {
                                item?.items?.map((cItem) => {
                                    const cLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                        languageVersions: cItem.languageVersions,
                                        filters: [
                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                        ],
                                        type: "find"
                                    });
                                    const cRedirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
                                        languageVersions: cItem.languageVersions,
                                        filters: [
                                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
                                        ],
                                        type: "find"
                                    });

                                    const cNavigationURL = `/${props.webApplicationLanguageISOCode}/shop?c=${cItem.hierarchyId}`;

                                    return (
                                        <React.Fragment key={cItem.id}>
                                            <RMDRLink
                                                title={languageVersion?.name}
                                                text={cLanguageVersion?.name}
                                                hasNavigationDestination={cItem.hasDestinationNavigation}
                                                navigationURL={cNavigationURL}
                                                redirectUrl={cRedirectUrl?.value}
                                                redirectType={cItem.redirectType}
                                                className="text-body text-sm hover:text-heading hover:bg-gray-300"
                                                onClick={(event:any) => { if( props.onClick ) props.onClick(event, item.id) }}
                                            />
                                            {", "}
                                        </React.Fragment>
                                    )}
                                )
                            }
                        </ul>
                    )}
                )
            }

        </div>
    );
};

export default MegaMenu;
