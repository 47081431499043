import React from 'react';
// components
import RMDRLink from "components/RMDRLink";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
//
import { TFooterComponent } from '../type';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';


function ListContents01 ( props:TFooterComponent ) {
    console.log("ListContents01.rendered: ", props);

    if ( props.parentPayload?.id ) {

        const childItems = props.payload?.filter((cItem) => cItem.parentId === props.parentPayload?.id);
        const parentLanguageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.parentPayload.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });

        return (
            <>
                <h4 className="mb-5 text-sm font-semibold text-heading md:text-base xl:text-lg 2xl:mb-6 3xl:mb-7">
                    {parentLanguageVersion?.name}
                </h4>
                <ul className={props.className} style={props.style}>
                    {
                        childItems?.map((cItem) => {
                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: cItem?.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: "find"
                            });
                            const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: cItem?.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                ],
                                type: "find"
                            });

                            return (
                                <li className="flex items-baseline">
                                    <RMDRLink
                                        title={languageVersion?.name}
                                        text={languageVersion?.name}
                                        hasNavigationDestination={cItem?.hasDestinationNavigation}
                                        navigationURL={languageVersion?.contentRouteNavigationURL}
                                        redirectType={cItem?.redirectType}
                                        redirectUrl={redirectUrl?.value}
                                        className="transition-colors duration-200 hover:text-black"
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </>
        );

    }

}

export default ListContents01;
