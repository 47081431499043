import { isEmpty, reject } from "lodash";
// interfaces
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import ESizeType from "cms/enums/ESizeType";
// interfaces
import IResource from 'cms/interfaces/IResource';


type TApplicationDocumentSelector = {

    resources?: IResource[];
    
    resourceType?: EResourceType;
    sizeType?: ESizeType;

    type?: "find"|"filter";

}

function _find ( props:Readonly<TApplicationDocumentSelector> ) {

    let resource:IResource|undefined;
    if ( props.sizeType !== null && props.sizeType !== undefined ) {
        resource = props.resources?.find((item) => item.resourceTypeId === props.resourceType && item.applicationDocument.sizeType === props.sizeType);
    } else {
        resource = props.resources?.find((item) => item.resourceTypeId === props.resourceType);
    }

    const applicationDocument = resource?.applicationDocument;
    const derived = resource?.applicationDocument?.deriveds?.[0];
    
    if ( derived && !isEmpty(derived) ) return derived as any;
    else if ( applicationDocument && !isEmpty(applicationDocument) ) return applicationDocument as any;

    return null as any;
}

function _filter ( props:Readonly<TApplicationDocumentSelector> ) {

    let resources:IResource[]|undefined;
    if ( props.sizeType !== null && props.sizeType !== undefined ) {
        resources = props.resources?.filter((item) => item.resourceTypeId === props.resourceType && item.applicationDocument.sizeType === props.sizeType);
    } else {
        resources = props.resources?.filter((item) => item.resourceTypeId === props.resourceType);
    }

    const applicationDocuments = reject(resources?.map((item) => item.applicationDocument as IApplicationDocument), isEmpty);
    const deriveds = reject(resources?.map((item) => item.applicationDocument.deriveds?.[0]), isEmpty);

    if ( deriveds && !isEmpty(deriveds) ) return deriveds as any;
    else if ( applicationDocuments && !isEmpty(applicationDocuments) ) return applicationDocuments as any;

    return null as any;
}

function ApplicationDocumentSelector<T> ( props:TApplicationDocumentSelector ): T {

    if ( !isEmpty(props.resources) ) {

        if ( props.type === "find" ) {

            return _find(props);

        } 
        else if ( props.type === "filter" ) {

            return _filter(props);

        }

    }

    return null as any;

}


export type {
    TApplicationDocumentSelector
}
export default ApplicationDocumentSelector;
