import cn from 'classnames';
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
import { EResourceType } from 'cms/enums/EResourceType';
import BannerCard, { BannerCardLoader } from './BannerCard';


type TBannerProps = {

    payload?: IClientMainPageItemList[];

    className?: string;
    classNameInner?: string;

    variant?: 'rounded' | 'default';
    effectActive?: boolean;
    disableBorderRadius?:boolean

}

function SaleBannerGridLoader () {

    return (
        <div className={`mb-2 md:mb-2.5`}>
            <div className='grid md:grid-cols-2 gap-2 relative'>
                <BannerCardLoader showLoader={true} />
                <BannerCardLoader showLoader={true} />
            </div>     
        </div>
    )

}

function SaleBannerGrid ( props:TBannerProps ) {

    return (
        <div className={`mb-2 md:mb-2.5 ${props.className}`}>
            <div className='grid md:grid-cols-2 gap-2 relative'>
                {
                    props.payload?.map((item, index) => 
                        <BannerCard 
                            payload={item}
                            variant={props.variant}
                            resourceType={EResourceType.OUTER_IMAGE}
                            effectActive={props.effectActive}
                            className={cn(
                                "h-full",
                                props.className
                            )}
                            classNameInner={props.classNameInner}
                            disableBorderRadius={props.disableBorderRadius}
                        />
                    )
                }
            </div>     
        </div>
    );

};

SaleBannerGrid.defaultProps = {

    variant: 'default',

}

export default SaleBannerGrid;
export {
    SaleBannerGridLoader
}