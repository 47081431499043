import React, { useEffect, useState } from "react";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import IClientTagList from "ecommerce/interfaces/IClientTagList";
import { Dispatch } from "@reduxjs/toolkit";
import RMDRCheckBox from "components/RMDRCheckbox";
import { connect, ConnectedProps } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import ClientTagsServiceAction from "ecommerce/actions/components/filters/TagFilter/ClientTagsServiceAction";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import { EViewType } from "cms/enums/EViewType";
import { EView } from "cms/enums/EView";


function mapStateToProps ( state: any ) {
    console.log("TagFilter.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage                    = state.CoreStore.language.payload;
    const webApplicationLanguageCode:string                         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string                      = state.CoreStore.languageCode.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail|null    = state.SiteSettingStore.details.payload;

    const clientTagsListPayload:IClientTagList[]                    = state.TagFilterStore.ClientTagsStore.list.payload;
    const clientTagsListError:TValidations[]|null                   = state.TagFilterStore.ClientTagsStore.list.error;
    const clientTagsListIsLoading:boolean                           = state.TagFilterStore.ClientTagsStore.list.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
        
        siteSettingDetailPayload,
        clientTagsListPayload,

        errors: clientTagsListError,
        isLoading: clientTagsListIsLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("TagFilter.mapDispatchToProps");

    const clientTagsServiceAction = new ClientTagsServiceAction(dispatch);

    return {

        _getTags: ( webApplicationLanguageCode:string ) => {

            clientTagsServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        filter: [`isActive eq true`],
                        orderBy:["name asc"],
                        top: 1000
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _clean: () => {

            clientTagsServiceAction.listClean();

        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TTagFilter<T> = {

} & T;


/* component */
function TagFilterLoader () {

    return (
        <></>
    )

}

function TagFilter ( props:TTagFilter<PropsFromRedux> ) {
    console.log("TagFilter: ", props);

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ selectedItems, setSelectedItems ] = useState<number[]>([]);
    const [ didMount, setDidMount ] = useState<boolean>(false);

    const tag = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "tag" }
        ],
        type: "find"
    });

    useEffect(
        () => {
            
            props._getTags(props.webApplicationLanguageCode);

            return function cleanup () {
                props._clean();
            }

        }, []
    )

    useEffect(
        () => {

            if ( !isEmpty(props.clientTagsListPayload) ) {
                const tags = searchParams.get("t")?.split(",")?.map((item) => parseInt(item));
                if ( tags?.length !== selectedItems.length ) {
                    setSelectedItems(tags||[]);
                }
                setDidMount(true);
            }

        }, [ props.clientTagsListPayload, searchParams ]
    )

    useEffect(
        () => {

            if ( !isEmpty(selectedItems) ) {
                const categoryParams:string = selectedItems.join(",")
                searchParams.set("t", categoryParams );
                searchParams.set("cp", "1" );
                setSearchParams(searchParams, { replace:true });
            }
            else {
                if ( searchParams.has('t') && didMount ) {
                    searchParams.delete('t');
                    setSearchParams(searchParams, { replace:true });
                }
            }

        }, [ selectedItems ]
    )

    function onChange( event:React.ChangeEvent<HTMLInputElement>, value:IClientTagList ) {

        if ( event.target.checked ) {
            setSelectedItems((prevState) => Array.from(new Set([...prevState, value.id])));
        } 
        else {
            setSelectedItems((prevState) => prevState.filter((item) => item !== value.id));
        }
        
    }

    if ( props.isLoading ) {
        
        return <TagFilterLoader />

    }

    return (
        <div className="block border-b border-gray-300 pb-7 mb-7">
            <h3 className="text-heading text-sm md:text-base font-semibold mb-7">
                {tag?.value}
            </h3>
            <div className="rmdr-scroll-1 mt-2 flex flex-col space-y-4 max-h-80 overflow-hidden overflow-y-scroll">
                {
                    props.clientTagsListPayload?.map((item, index) => {

                        return (
                            <RMDRCheckBox
                                key={item.id}
                                label={item?.name||""}
                                name={item?.name||""}
                                checked={selectedItems.includes(item.id)}
                                value={item?.id||""}
                                onChange={(event) => { onChange(event, item) }}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
}

export default connector(TagFilter);
