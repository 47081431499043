import TValidations from "cms/types/TValidations";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import ContactUsServiceAction from "cms/actions/components/ContactForm/ContactUsServiceAction";
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IBase from "cms/interfaces/IBase";
import { Dispatch } from "@reduxjs/toolkit";
import RMDRTextBox from "./RMDRTextBox";
import RMDRTextArea from "./RMDRTextArea";
import RMDRButton from "./RMDRButton";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";


function mapStateToProps ( state: any ) {
    console.log("MNPG008.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const contactUsPostPayload:IBase                            = state.ContactFormStore.ContactUsStore.post.payload;
    const contactUsPostError:TValidations[]|null                = state.ContactFormStore.ContactUsStore.post.error?.value;
    const contactUsPostIsLoading:boolean                        = state.ContactFormStore.ContactUsStore.post.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        contactUsPostPayload,
        contactUsPostError,
        contactUsPostIsLoading,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG008.mapDispatchToProps");

    const messagePublisherAction = new MessagePublisherAction(dispatch);
    const contactUsServiceAction = new ContactUsServiceAction(dispatch);

    return {

        _post: ( payload: { nameSurname:string|null, email:string|null, title:string|null, message:string|null }, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: payload, language: webApplicationLanguageCode } });
        },

        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message);
        },

        _clean: () => {
            contactUsServiceAction.postClean();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TRMDRRegistrationForm<T> = {

    languageVersions?:ILanguageVersionList[]|null;
    labelClassName?:string;

} & T;

/* component */
function RMDRRegistrationFormLoader () {

    return (
        <></>
    )

}

function RMDRRegistrationForm ( props:TRMDRRegistrationForm<PropsFromRedux> ) {
    console.log("RMDRRegistrationForm.rendered: ", props);

    const yourNameString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "yourNameString" }
        ],
        type: "find"
    });
    const enterYourNameString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "enterYourNameString" }
        ],
        type: "find"
    });

    const yourEmailString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "yourEmailString" }
        ],
        type: "find"
    });
    const enterYourEmailString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "enterYourEmailString" }
        ],
        type: "find"
    });

    const titleString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "titleString" }
        ],
        type: "find"
    });

    const messageString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageString" }
        ],
        type: "find"
    });
    const writeYourMessageHereString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "writeYourMessageHereString" }
        ],
        type: "find"
    });

    const sendMessageString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "sendMessageString" }
        ],
        type: "find"
    });
    const messageSendingString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageSendingString" }
        ],
        type: "find"
    });
    const messageHasBeenSentString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageHasBeenSentString" }
        ],
        type: "find"
    });
    const titleDefaultValue = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "titleDefaultValue" }
        ],
        type: "find"
    });

    const [disabled, setDisabled] = useState(false);

    const contactUsNameSurname = useRef<any>(null);
    const contactUsEmail = useRef<any>(null);
    const contactUsMessage = useRef<any>(null);
    const contactUsTitle = useRef<any>(null);

    useEffect(
        () => {
        
            return function cleanup(){

                props._clean();

            }
            
        }, [ ]
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostPayload?.id) ) {
                contactUsNameSurname.current.value = "";
                contactUsEmail.current.value = "";
                contactUsMessage.current.value = "";
                if ( !isEmpty(titleDefaultValue) ) {
                    contactUsTitle.current.value = titleDefaultValue.value;
                } else {
                    contactUsTitle.current.value = "";
                }
                

                props._clean();
                setDisabled(false);
                props._showMessage(messageHasBeenSentString?.value||"");
                
            }
        }, [ props.contactUsPostPayload ]
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostError) ) {
                setDisabled(false);
            }
        }, [ props.contactUsPostError ]
    )

    function onSubmit(event:React.SyntheticEvent) { 
        event.preventDefault();

        setDisabled(true);

        const target = event.target as typeof event.target & {
            nameSurname: { value: string },
            title: { value: string },
            email: { value: string },
            message: { value: string },
        };

        const payload:any = {
            nameSurname: target.nameSurname.value,
            email: target.email.value,
            message: target.message.value
        }

        if ( !isEmpty(titleDefaultValue) ) {
            payload["title"] = titleDefaultValue.value;
        } else {
            payload["title"] = target.message.value;
        }

        props._post(payload, props.webApplicationLanguageCode);
    }

    return (
        <form
            onSubmit={onSubmit}
            className="w-full mx-auto flex flex-col justify-center "
            noValidate
        >
            <div className="flex flex-col space-y-5">
                
                <div className="relative">
                    <label htmlFor="title" className={`${props.labelClassName} block font-semibold text-sm leading-none mb-3`}>{titleString?.value}</label>
                    <RMDRTextBox 
                        rmdrRef={contactUsTitle} 
                        name="title" 
                        placeholder={titleDefaultValue?.value||enterYourNameString?.value} 
                        disabled={!isEmpty(titleDefaultValue?.value)?true:disabled} 
                        errors={props.contactUsPostError} 
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 relative">
                    <div className="w-full md:w-1/2 mt-2 md:mt-0">
                        <label htmlFor="email" className={`${props.labelClassName} block font-semibold text-sm leading-none mb-3`}>{yourEmailString?.value}</label>
                        <RMDRTextBox 
                            rmdrRef={contactUsEmail} 
                            name="email" 
                            placeholder={enterYourEmailString?.value} 
                            disabled={disabled} 
                            errors={props.contactUsPostError}
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                        />
                    </div>

                    <div className="w-full md:w-1/2 md:ml-2.5 lg:ml-5">
                        <label htmlFor="name" className={`${props.labelClassName} block font-semibold text-sm leading-none mb-3`}>{yourNameString?.value}</label>
                        <RMDRTextBox 
                            rmdrRef={contactUsNameSurname} 
                            name="nameSurname" 
                            placeholder={enterYourNameString?.value} 
                            disabled={disabled} 
                            errors={props.contactUsPostError} 
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                        />
                    </div>
                </div>

                <div className="relative mb-4">
                    <label htmlFor="message" className={`${props.labelClassName} block font-semibold text-sm leading-none mb-3`}>{messageString?.value}</label>
                    <RMDRTextArea 
                        rmdrRef={contactUsMessage}
                        name="message" 
                        placeholder={writeYourMessageHereString?.value}
                        disabled={disabled}
                        rows={5}
                        errors={props.contactUsPostError}
                        className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 bg-white border border-gray-300 focus:shadow focus:outline-none focus:border-heading placeholder-body"
                    />
                </div>

                <div className="relative">
                    <RMDRButton 
                        type="submit" 
                        value={sendMessageString?.value} 
                        disabledValue={messageSendingString?.value} 
                        disabled={disabled} 
                        className="text-[13px] md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none rounded-md  text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-floorsAndExtras-700 hover:bg-floorsAndExtras-800 hover:shadow-cart h-12 lg:h-14 mt-1 text-sm lg:text-base w-full sm:w-auto"
                    />
                </div>

            </div>
        </form>
    );
};

const Component = React.memo(RMDRRegistrationForm, ( prevProps:TRMDRRegistrationForm<PropsFromRedux> , nextProps:TRMDRRegistrationForm<PropsFromRedux> ) => {
    console.log("MNPG008.memo", { prevProps, nextProps });

    return !(
        prevProps.contactUsPostPayload !== nextProps.contactUsPostPayload
        || prevProps.contactUsPostError !== nextProps.contactUsPostError
    );
});

export default connector(Component);
