import FetchExtension from 'core/extensions/FetchExtension';
// service
import EnumService from 'core/services/enum/EnumService';
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IApiGetRequest } from 'core/interfaces/IGetRequest';
// enum
import HttpMethodEnum from "core/enums/EHttpMethod";


class StockStatusService extends EnumService {
	
    static code = "405";
    code = "405";

    get( params: IApiGetRequest ): Promise<any> {
        
		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.GET,
            language: params.language,

            sourcePath: `${this.sourcePath}/StockStatus`,
		}

		return FetchExtension(apiFetchConfig);

	}

}

export default StockStatusService;
