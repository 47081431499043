import FetchExtension from 'core/extensions/FetchExtension';
// service 
import OdataCoreServiceBase from 'core/services/OdataCoreServiceBase';
// interface
import IFetchExtension from 'core/interfaces/IFetchExtension';
import { IOdataDetailRequest } from 'core/interfaces/IDetailRequest';
// enum
import HttpMethodEnum from 'core/enums/EHttpMethod';
import EndpointProtocol from 'core/enums/EEndpointProtocol';


class OdataReadOnlyServiceBase extends OdataCoreServiceBase {

	async details( params: IOdataDetailRequest ): Promise<any> {

		const apiFetchConfig: IFetchExtension = {
            // protocol: EndpointProtocol.ODATA,
			method: HttpMethodEnum.GET,
            language: params.language,

			sourcePath: `${this.sourcePath}/${params.key}/details`,
		}

		return FetchExtension(apiFetchConfig);
	
    }

}

export default OdataReadOnlyServiceBase;
