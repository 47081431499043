import _ContactUsServiceAction from "cms/actions/services/ContactUsServiceAction";


const screenCode = "20C";
class ContactUsServiceAction extends _ContactUsServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default ContactUsServiceAction;
