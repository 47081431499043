import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// reducers
import ClientProductsServiceStore from "ecommerce/reducers/services/ClientProductsServiceStore";
import ClientStoresServiceStore from "ecommerce/reducers/services/ClientStoresServiceStore";
import ClientCatalogCategoriesServiceStore from "ecommerce/reducers/services/ClientCatalogCategoriesServiceStore";
import StockStatusServiceStore from "ecommerce/reducers/services/enum/StockStatusServiceStore";
// enums
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


const screenCode = "400";
const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: "" });

const MarketPlaceStore = combineReducers({
    
    ClientProductsStore: ClientProductsServiceStore({ screenCode }),
    ClientStoresStore: ClientStoresServiceStore({ screenCode }),
    ClientCatalogCategoriesStore: ClientCatalogCategoriesServiceStore({ screenCode }),
    StockStatusStore: StockStatusServiceStore({ screenCode }),
    
    MarketPlacePageStore: reduxStorages.BASE({ 
        storeKey: "marketplace",
        protocolKey: "MP",
        initialState: EReduxStoreInitialState.OBJECT
    })["marketplace"]
    
});

export default MarketPlaceStore;
