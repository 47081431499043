// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientCatalogCategoriesService from "ecommerce/services/ClientCatalogCategoriesService";


class ClientCatalogCategoriesServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientCatalogCategoriesService, screenCode);
    }

}

export default ClientCatalogCategoriesServiceAction;
