// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import ClientTagsService from "ecommerce/services/ClientTagsService";


class ClientTagsServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, ClientTagsService, screenCode);
    }

}

export default ClientTagsServiceAction;
