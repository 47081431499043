import ActionCore from 'core/actions/ActionCore';
// enum
import EActionStatus from "core/enums/EActionStatus";


const screenCode = "400";
class MarketPlacePageAction extends ActionCore {

    constructor( dispatch:any ) {
        super( dispatch, null, screenCode );
    }

    async modify ( payload: any ) {

        super.modify("MP", payload);

    }

    clean () {
        
        this.dispatch({ type: `${this.screenCode}MP${EActionStatus.CLEAN}` });

    }

}

export default MarketPlacePageAction;
