// action 
import OdataReadOnlyActionBase from "core/actions/OdataReadOnlyActionBase";
// service
import StockStatusService from "ecommerce/services/enum/StockStatusService";


class StockStatusServiceAction extends OdataReadOnlyActionBase {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, StockStatusService, screenCode);
    }

}

export default StockStatusServiceAction;
