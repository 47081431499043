import React, { useState } from "react";
import Lodash, { isEmpty } from "lodash";
// components
// enums
import { EViewType } from "cms/enums/EViewType";
// types
import { TRMDRAccordion } from "../type";
//
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import RenderText from "../../RenderText";


function heightCollapse() {
    return {
        from: {
            opacity: 0,
            height: 0,
            transition: {
            ease: [0.04, 0.62, 0.23, 0.98],
            },
        },
        to: {
            opacity: 1,
            height: "auto",
            transition: {
            ease: [0.04, 0.62, 0.23, 0.98],
            },
        },
    };
}


function Item({
    i,
    expanded,
    setExpanded,
    title,
    content,
}: any) {

    const isOpen = i === expanded;

    return (
        <div
            className={
                cn(
                    'rounded-md bg-gray-200',
                    { 'shadow-sm': isOpen }
                )
            }
        >
            <motion.header
                initial={false}
                onClick={() => setExpanded(isOpen ? false : i)}
                className='cursor-pointer flex items-center justify-between transition-colors py-5 md:py-6 px-6 md:px-8 lg:px-10'
            >
                <h2
                    className='text-sm font-semibold leading-relaxed text-heading pr-2 md:text-base'
                >
                    {title}
                </h2>
                <div className="relative flex items-center justify-center flex-shrink-0 w-4 h-4">
                    <div className="w-full h-0.5 bg-heading rounded-sm" />
                    <div
                        className={
                            cn(
                                'origin-bottom transform w-0.5 h-full bg-heading rounded-sm absolute bottom-0 transition-transform duration-500 ease-in-out',
                                { 'scale-0': isOpen },
                                { 'scale-100': !isOpen }
                            )
                        }
                    />
                </div>
            </motion.header>

            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        initial="from"
                        animate="to"
                        exit="from"
                        variants={heightCollapse()}
                    >
                        <div
                            className='pb-6 md:pb-7 leading-7 text-sm text-gray-600 pt-5 border-t border-gray-300 px-6 md:px-8 lg:px-10'
                        >
                            {content}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const RMDRAccordion01 = ( props:TRMDRAccordion ) => {
    console.log("RMDRAccordion01.rendered", props);

    const [expanded, setExpanded] = useState<number>(0);

    if ( props.languageVersion && !isEmpty(props.languageVersion) && props.languageVersions && !isEmpty(props.languageVersions) && props.languageVersion.viewType === EViewType.ACCORDION ) {

        let payloadChild = props.languageVersions.filter((item) => item.parentId === props.languageVersion?.id);
        payloadChild = Lodash.orderBy(payloadChild, ['order'],['asc']);

        if ( !isEmpty(payloadChild) ) {
            return (
                <div className="px-0 space-y-4">
                {
                    payloadChild?.map((item, index) => (
                        <Item
                            i={index}
                            title={item.name}
                            content={<RenderText html={item.value} />}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    ))}
                </div>
            );
        } 

    }

    return null;
}

const Component = React.memo(RMDRAccordion01, ( prevProps:TRMDRAccordion, nextProps:TRMDRAccordion ) => {
    console.log("RMDRAccordion01.memo", { prevProps, nextProps });

    return !( 
        prevProps.isLoading !== nextProps.isLoading
        || prevProps.languageVersion !== nextProps.languageVersion
    )
});

export default Component;