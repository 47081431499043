// import ProductCard from "@components/product/product-card";
// import Button from "@components/ui/button";
// import type { FC } from "react";
// import { useProductsQuery } from "@framework/product/get-all-products";
// import { useRouter } from "next/router";
// import ProductFeedLoader from "@components/ui/loaders/product-feed-loader";
// import { useTranslation } from "next-i18next";
// import { Product } from "@framework/types";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import ProductCard from "./ProductCard";
import IEnum from "core/interfaces/IEnum";
import NoData from "components/NoData";
import { HiOutlineNewspaper } from "react-icons/hi2";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { isEmpty } from "lodash";


type TProductGrid = {

    siteSettingPayload:IClientSiteSettingDetail;
    payload:IClientProductList[];
    stockStatusDataSource?:IEnum[];
    isLoading:boolean;
    className?:string;
    onClick: ( event: React.MouseEvent<HTMLDivElement, MouseEvent>, value:IClientProductList ) => void;
}


/* component */
function ProductGridLoader ( props:TProductGrid ) {

    return (
        <>
            {
                Array.from({ length:8 }).map(() => (
                        <div className="animate-pulse">
                            <div className="bd-trending__item text-center mb-30 position-relative">
                                <div className="bd-trending__product-thumb border-5">
                                    <div className="w-full h-48 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                    <div className="w-full h-4 my-2 rounded-md bg-gray-100" />
                                </div>
                            </div>
                        </div>
                ))
            }
        </>
    )

}

function ProductGrid ( props:TProductGrid ) {

    if ( !props.isLoading && isEmpty(props.payload) ) {
        
        return (
            <NoData
                icon={<HiOutlineNewspaper className="h-40 stroke-1 text-9xl" />}
                className="flex flex-col items-center justify-center py-60"
                textClassName="text-center text-2xl"
                siteSettingPayload={props.siteSettingPayload}
            />
        )

    }

    return (
        <>
            <div
                className={`grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 ${props.className}`}
            >
                {
                    props.isLoading ?
                        (
                            <ProductGridLoader {...props} />
                        ) : 
                        (
                            props.payload?.map((item, index) => <ProductCard
                                                                    payload={item}
                                                                    stockStatusDataSource={props.stockStatusDataSource}
                                                                    variant="gridModern"
                                                                    className="border border-gray-300"
                                                                    imageClassName="!rounded-b-none"
                                                                    disableActionButton
                                                                    // onClick={(event) => props.onClick(event, item)}
                                                                />
                        )
                )}
            </div>
            
            
            
        </>
    );
}

export default ProductGrid;
