import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Lodash, { isEmpty } from "lodash";
// components
import SliderGroupLayouts from "components/SliderGroupLayouts";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientSilderItemServiceAction from "cms/actions/components/Slider/ClientSilderItemServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
// 
import { getLocalDateTime } from "core/utilities/DateTimeHelper";
import IClientSliderItemList from "cms/interfaces/IClientSliderItemList";
//
import { EContentStatus } from "cms/enums/EContentStatus";
import { Dispatch } from "@reduxjs/toolkit";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import TValidations from "cms/types/TValidations";
import { BannerCardLoader } from "./BannerCard";


function mapStateToProps ( state: any ) {
    console.log("Slider.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const webApplicationLanguage: IClientLanguage               = state.CoreStore.language?.payload;
    const webApplicationLanguageCode: string                    = webApplicationLanguage.code;
    const webApplicationLanguageISOCode: string                 = state.CoreStore.languageCode?.payload;

    const sliderItemListPayload:IClientSliderItemList[]|null    = state.SliderStore.ClientSilderItemStore.list?.payload;
    const sliderItemListError:TValidations[]|null               = state.SliderStore.ClientSilderItemStore.list?.error;
    const sliderItemListIsLoading:boolean                       = state.SliderStore.ClientSilderItemStore.list?.isLoading;

    return {

        siteSettingDetailPayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        sliderItemListPayload,
        sliderItemListError,
        sliderItemListIsLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Slider.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const clientSilderItemServiceAction = new ClientSilderItemServiceAction(dispatch);

    return {
        // list
        _listSliderItems: ( layoutName: string, webApplicationLanguageCode: string ) => {
            let queryOptions: IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `mapLayoutName eq '${layoutName}'`, "and", `(publishDate le ${getLocalDateTime()} or publishDate eq null)`, "and", `(expireDate ge ${getLocalDateTime(true)} or expireDate eq null)`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientSilderItemServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        _listAllSliderItems: ( webApplicationLanguageCode: string ) => {
            let queryOptions: IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `(publishDate le ${getLocalDateTime()} or publishDate eq null)`, "and", `(expireDate ge ${getLocalDateTime(true)} or expireDate eq null)`],
                orderBy: ["mapLayoutName asc", "order asc"],
                top: 1000
            };

            clientSilderItemServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // other
        _hideLoader: () => {
            coreAction.hideLoader();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TSlider<T> = {

    layoutName?:string;

} & T;


/* component */
function SliderLoader () {

    return (
        <div className="mb-12 md:mb-14 xl:mb-16 px-2.5 grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5 max-w-[1920px] mx-auto animate-pulse">
            <BannerCardLoader showLoader={true} className="col-span-full sm:col-span-5" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-full sm:col-span-5" />
        </div>
    )

}

function Slider ( props: TSlider<PropsFromRedux> ) {
    console.log("Slider.rendered: ", props);

    useEffect(
        () => {

            if ( props.layoutName && !Lodash.isEmpty(props.layoutName) ) {
                props._listSliderItems(props.layoutName, props.webApplicationLanguageCode);
            }
            else {

                props._listAllSliderItems( props.webApplicationLanguageCode );

            }
            
        }, [ props.layoutName ]
    )


    if ( props.sliderItemListIsLoading && isEmpty(props.sliderItemListError) ) {
        return <SliderLoader />
    }
    // else if (props.sliderItemListIsLoading === false && isEmpty(props.sliderItemListPayload) && isEmpty(props.sliderItemListError)) {
    //     return (
    //         <div className="mb-12 md:mb-14 xl:mb-16 max-w-[1920px] mx-auto">
    //             <NoData
    //                 className="flex flex-col items-center justify-center h-80 bg-gray-100"
    //                 textClassName="text-center text-xl"
    //                 iconClassName="stroke-1 text-8xl"
    //                 siteSettingPayload={props.siteSettingDetailPayload}
    //             />
    //         </div>
    //     )
    // }
    else if ( !isEmpty(props.sliderItemListPayload) && props.layoutName && !isEmpty(props.layoutName) ) {

        const SliderGroupLayout = SliderGroupLayouts[props.layoutName];

        if ( !isEmpty(SliderGroupLayout) ) {
            return (
                <SliderGroupLayout  payload={props.sliderItemListPayload} />
            );
        }

    }
    else if ( !isEmpty(props.sliderItemListPayload) && props.sliderItemListPayload ) {

        const firstItemMapLayoutName = props.sliderItemListPayload?.[0]?.mapLayoutName;
        if ( firstItemMapLayoutName ) {
            
            const SliderGroupLayout = SliderGroupLayouts[firstItemMapLayoutName];
            const sliderItems = props.sliderItemListPayload.filter((item) => item.mapLayoutName === firstItemMapLayoutName);

            if ( !isEmpty(SliderGroupLayout) ) {
                return (
                    <SliderGroupLayout  payload={sliderItems} />
                );
            }

        }

    }

    return null;

}

Slider.defaultProps = {

}

const Component = React.memo(Slider, ( prevProps: TSlider<PropsFromRedux>, nextProps: TSlider<PropsFromRedux> ) => {
    console.log("Slider.memo", { prevProps, nextProps });

    return !(
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode
        || prevProps.sliderItemListPayload !== nextProps.sliderItemListPayload
    )
});

export default connector(Component);
