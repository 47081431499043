import _SiteSettingServiceAction from "cms/actions/services/ClientSiteSettingServiceAction";


const screenCode = "COM";
class SiteSettingServiceAction extends _SiteSettingServiceAction {

    constructor( dispatch:any ) {
        super( dispatch, screenCode);

        this.serviceCode = "SS";
    }

}

export default SiteSettingServiceAction;
