import React from "react";
// types
import { TFooterComponent } from '../type';
import RMDRLink from "components/RMDRLink";
import RMDRImage from "components/RMDRImage";
import ERedirectType from "cms/enums/ERedirectType";
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import SocialMedia01 from "components/SocialMedia/components/SocialMedia01";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


function ContactLoader ( props:TFooterComponent ) {

    return (
        <ul className={`animate-pulse ${props.className}`} style={props.style}>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
            <li className="flex items-baseline h-8 w-full">
                <span className="mr-3 relative rounded-full bg-gray-100 h-8 w-8"></span>
                <div className="ransition-colors duration-200 hover:text-black relative text-sm lg:text-xl rounded-md bg-gray-100 h-8 w-36"></div>
            </li>
        </ul>
    )

}

const Contact = ( props:TFooterComponent ) => {
    console.log("Contact.rendered", props);

    if ( props.parentPayload?.id ) {

        const childItem = props.payload?.find((cItem) => cItem.parentId === props.parentPayload?.id);
        
        const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItem?.languageVersions,
            filters: [
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        });
        const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItem?.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
            ],
            type: "find"
        });
        //
        const phone = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItem?.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "phone" }
            ],
            type: "find"
        });
        const email = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItem?.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }
            ],
            type: "find"
        });
        const callUsString = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: childItem?.languageVersions,
            filters: [
                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "callUsString" }
            ],
            type: "find"
        });


        if ( props?.payload ) {
            return (
                <ul className={props.className} style={props.style}>
                    <li className="flex items-baseline">
                        <RMDRLink
                            title={languageVersion?.name}
                            text={languageVersion?.name}
                            hasNavigationDestination={childItem?.hasDestinationNavigation}
                            navigationURL={languageVersion?.contentRouteNavigationURL}
                            redirectType={childItem?.redirectType}
                            redirectUrl={redirectUrl?.value}
                            className="transition-colors duration-200 hover:text-black"
                        />
                    </li>
                    <li className="flex items-baseline transition-colors duration-200 hover:text-black cursor-pointer">
                        {email?.value}
                    </li>
                    <li className="flex items-baseline transition-colors duration-200 hover:text-black cursor-pointer">
                        {callUsString?.value}{": "}{phone?.value}
                    </li>
                </ul>
            );
        }

    }

    return <ContactLoader {...props} />
     
}

export default Contact;
