import React, { useEffect, useState } from "react";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import IClientCatalogCategoryList from "ecommerce/interfaces/IClientCatalogCategoryList";
import { Dispatch } from "@reduxjs/toolkit";
import RMDRCheckBox from "components/RMDRCheckbox";
import { EContentStatus } from "cms/enums/EContentStatus";
import { connect, ConnectedProps } from "react-redux";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import ClientCatalogCategoriesServiceAction from "ecommerce/actions/components/filters/CategoryFilter/ClientCatalogCategoriesServiceAction";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import CatalogCategoryComposer from "ecommerce/utilities/CatalogCategoryComposer";
import TCatalogCategories from "ecommerce/types/TCatalogCategories";
import cn from 'classnames';


function mapStateToProps ( state: any ) {
    console.log("CategoryFilter.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage                    = state.CoreStore.language.payload;
    const webApplicationLanguageCode:string                         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string                      = state.CoreStore.languageCode.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail|null    = state.SiteSettingStore.details.payload;

    const clientCatalogCategoriesListPayload:IClientCatalogCategoryList[]   = state.CategoryFilterStore.ClientCatalogCategoriesStore.list.payload;
    const clientCatalogCategoriesListError:TValidations[]|null              = state.CategoryFilterStore.ClientCatalogCategoriesStore.list.error;
    const clientCatalogCategoriesListIsLoading:boolean                      = state.CategoryFilterStore.ClientCatalogCategoriesStore.list.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
        
        siteSettingDetailPayload,
        clientCatalogCategoriesListPayload,

        errors: clientCatalogCategoriesListError,
        isLoading: clientCatalogCategoriesListIsLoading
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("CategoryFilter.mapDispatchToProps");

    const clientCatalogCategoriesServiceAction = new ClientCatalogCategoriesServiceAction(dispatch);

    return {

        _getCatalogCategories: ( webApplicationLanguageCode:string ) => {

            clientCatalogCategoriesServiceAction.list({
                servicePayload: {
                    queryOptions: {
                        filter: [`status eq ${EContentStatus.ACTIVE}`],
                        top: 1000,
                        orderBy: ["order asc"]
                    },
                    language: webApplicationLanguageCode 
                }
            })

        },

        _clean: () => {

            clientCatalogCategoriesServiceAction.listClean();

        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TCategoryFilter<T> = {

} & T;


/* component */
function CategoryFilterLoader () {

    return (
        <></>
    )

}

function CategoryFilter ( props:TCategoryFilter<PropsFromRedux> ) {
    console.log("CategoryFilter.rendered:", props);

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ selectedItems, setSelectedItems ] = useState<number[]>([]);
    const [ didMount, setDidMount ] = useState<boolean>(false);

    const category = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "category" }
        ],
        type: "find"
    });

    useEffect(
        () => {
            
            props._getCatalogCategories(props.webApplicationLanguageCode);

            return function cleanup () {
                props._clean();
            }

        }, []
    )

    useEffect(
        () => {

            if ( !isEmpty(props.clientCatalogCategoriesListPayload) ) {
                const categories = searchParams.get("c")?.split(",")?.map((item) => parseInt(item));
                if ( categories?.length !== selectedItems.length ) {
                    setSelectedItems(categories||[]);
                }
                setDidMount(true);
            }

        }, [ props.clientCatalogCategoriesListPayload, searchParams ]
    )

    useEffect(
        () => {

            if ( !isEmpty(selectedItems) ) {
                const categoryParams:string = selectedItems.join(",")
                searchParams.set("c", categoryParams );
                searchParams.set("cp", "1" );
                setSearchParams(searchParams, { replace:true });
            }
            else {
                if ( searchParams.has('c') && didMount ) {
                    searchParams.delete('c');
                    setSearchParams(searchParams, { replace:true });
                }
            }

        }, [ selectedItems ]
    )

    function onChange( event:React.ChangeEvent<HTMLInputElement>, value:IClientCatalogCategoryList ) {

        if ( event.target.checked ) {
            setSelectedItems((prevState) => Array.from(new Set([...prevState, value.hierarchyId])));
        } 
        else {
            setSelectedItems((prevState) => prevState.filter((item) => item !== value.hierarchyId));
        }
        
    }

    function createFilterItems ( values:IClientCatalogCategoryList[]|null, parentItems:IClientCatalogCategoryList[]|undefined|null = null, depth:number=0) {

        let _value:any[] = [];
    
        if ( values && parentItems && !isEmpty(values) && !isEmpty(parentItems) ) {
    
            for ( let parentItem of parentItems ) {
                let _parentItem:TCatalogCategories = {...parentItem};
        
                let childItems = values.filter((item:any) => item.parentId === parentItem.id);
                if ( childItems.length > 0 ) {
                        let response = createFilterItems( values, childItems, depth+1 );
                        if ( response.length > 0 ) {
                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                                languageVersions: _parentItem.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: "find"
                            });
                            
                            _value.push([ 
                                <RMDRCheckBox
                                    key={_parentItem.id}
                                    label={languageVersion?.name||""}
                                    name={languageVersion?.id}
                                    checked={selectedItems.includes(_parentItem.hierarchyId)}
                                    value={languageVersion?.name||""}
                                    onChange={(event) => { onChange(event, _parentItem) }}
                                    className="form-checkbox w-5 h-5 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-floorsAndExtras-600 checked:hover:bg-floorsAndExtras-700 checked:focus:bg-floorsAndExtras-600"
                                    style={{"marginLeft": `${5*depth*4}px`}}
                                />,
                                ...response
                            ]);
                        }
                }
                else {
                    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: _parentItem.languageVersions,
                        filters: [
                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                        ],
                        type: "find"
                    });

                    _value.push(
                        <RMDRCheckBox
                            key={_parentItem.id}
                            label={languageVersion?.name||""}
                            name={languageVersion?.id}
                            checked={selectedItems.includes(_parentItem.hierarchyId)}
                            value={languageVersion?.name||""}
                            onChange={(event) => { onChange(event, _parentItem) }}
                            className="form-checkbox w-5 h-5 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-floorsAndExtras-600 checked:hover:bg-floorsAndExtras-700 checked:focus:bg-floorsAndExtras-600"
                            style={{"marginLeft": `${5*depth*4}px`}}
                        />
                    );
                }
            }
    
        } else {
    
            const _parentItems = values?.filter((x) => x.level === "0.1");
            return createFilterItems( values, _parentItems )
    
        }
    
        return _value;
    
    }

    if ( props.isLoading ) {
        
        return <CategoryFilterLoader />

    }

    return (
        <div className="block border-b border-gray-300 pb-7 mb-7">
            <h3 className="text-heading text-sm md:text-base font-semibold mb-7">
                {category?.value}
            </h3>
            <div className="rmdr-scroll-1 mt-2 flex flex-col space-y-4 max-h-80 overflow-hidden overflow-y-scroll">
                {
                    !isEmpty(props.clientCatalogCategoriesListPayload) ? createFilterItems(props.clientCatalogCategoriesListPayload) : null
                }
                {/* {
                    catalogCategories?.map((item, index) => {
                        const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
                            languageVersions: item.languageVersions,
                            filters: [
                                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                            ],
                            type: "find"
                        });

                        return (
                            <RMDRCheckBox
                                key={item.id}
                                label={languageVersion?.name||""}
                                name={languageVersion?.id}
                                checked={selectedItems.includes(item.hierarchyId)}
                                value={languageVersion?.name||""}
                                onChange={(event) => { onChange(event, item) }}
                            />
                        )}
                    )
                } */}
            </div>
        </div>
    );
}

export default connector(CategoryFilter);
