import SearchIcon from 'components/Icons/search-icon';
import React from 'react';
import cn from 'classnames';
import { IoCloseOutline } from 'react-icons/io5';

type SearchProps = {
    className?: string;
    onSubmit: (e: React.SyntheticEvent) => void;
    onClear: (e: React.SyntheticEvent) => void;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    name: string;
    value: string;
    [key: string]: any;
};


function _SearchBox(props: SearchProps, ref: any) {

    return (
        <form
            className={
                cn(
                    'relative pr-12 md:pr-14 bg-white overflow-hidden rounded-md w-full',
                    props.className
                )
            }
            noValidate
            role="search"
            onSubmit={props.onSubmit}
        >
            <label htmlFor="search" className="flex items-center py-0.5">
                <span className="flex items-center justify-center flex-shrink-0 w-12 h-full cursor-pointer md:w-14 focus:outline-none">
                    <SearchIcon color="text-heading" className="w-4 h-4" />
                </span>
                <input
                    id="search"
                    ref={ref}
                    name={props.name}
                    type='text'
                    className="w-full h-12 text-sm placeholder-gray-400 outline-none text-heading lg:h-14 lg:text-base"
                    placeholder={'Search Anything...'}
                    aria-label="search"
                    autoComplete="off"
                    
                    value={props.value}
                    onChange={props.onChange}
                />
            </label>
            {
                props.onClear && (
                    <button
                        type="button"
                        className="absolute top-0 flex items-center justify-center w-12 h-full text-2xl text-gray-400 transition duration-200 ease-in-out outline-none md:text-3xl right-0 md:w-14 hover:text-heading focus:outline-none"
                        onClick={props.onClear}
                    >
                        <IoCloseOutline className="w-6 h-6" />
                    </button>
                )
            }
            
        </form>
    );

}

const SearchBox = React.forwardRef(_SearchBox);

export default SearchBox;
