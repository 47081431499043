import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// 
import { IoCall } from 'react-icons/io5'


/* types */
type TPhoneNumberBox = {

    payload?: ILanguageVersionList[];

}

function PhoneNumberBox ( props:TPhoneNumberBox ) {
    console.log("PhoneNumberBox: ", props);

    const phoneNUmber = props?.payload?.find((item) => item.name === "phone");
    
    if ( phoneNUmber ) {
        return (
            <div className="text-base">
                <IoCall className='text-sm lg:text-xl float-left mr-2' />{phoneNUmber.value}
            </div>
        );
    }
    
    return (
        <div className="text-base animate-pulse">
            <div className="rounded-full bg-gray-100 h-8 w-8 text-sm lg:text-xl float-left"></div>
            <div className="rounded-md bg-gray-100 h-8 w-36 ml-10"></div>
        </div>
    );

}

export default PhoneNumberBox;
