import React from "react";
// interfaces
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
//
import { HiOutlineNewspaper } from "react-icons/hi2";


type TNoData = {

    icon?: React.ReactNode;
    iconClassName?:string;

    className?: string;
    textClassName?: string;

    siteSettingPayload?: IClientSiteSettingDetail;
    
}

function NoData ( props:TNoData ) {

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { name: "noDataString" }
        ],
        type: "find"
    });

    return (
        <div className={props.className}>
            { 
                props.icon ? 
                    ( props.icon) : 
                    ( <HiOutlineNewspaper className={props.iconClassName} />)
            }
            <p className={props.textClassName}>{ languageVersion?.value }</p>
        </div>
    );

}

export default NoData;
export type {
    TNoData
};